import Layout from '@views/Layout.vue'

export default {
    path: '/alarm-management',
    component: Layout,
    children: [{ //证据中心
        name: 'Alarm-evidence-center',
        path: '/alarm-management/evidence-center/evidence-center',
        component: () =>
            import ('@views/alarm-management/evidence-center/evidence-center'),
            meta: {
                title: '证据中心',
            }
        },{ //证据中心-详情
            name: 'Alarm-evidence-dispose-details',
            path: '/alarm-management/evidence-center/evidence-dispose-details',
            component: () =>
                import ('@views/alarm-management/evidence-center/evidence-dispose-details'),
                meta: {
                    title: '证据详情',
                }
            },
            { //报警中心
                name: 'Alarm-alarm-center',
                path: '/alarm-management/alarm-center/alarm-center',
                component: () =>
                    import ('@views/alarm-management/alarm-center/alarm-center'),
                    meta: {
                        title: '报警中心',
                    }
            },
            { //报警中心 - 车辆详情
                name: 'Alarm-alarm-dispose-details',
                path: '/alarm-management/alarm-center/alarm-dispose-details',
                component: () =>
                    import ('@views/alarm-management/alarm-center/alarm-dispose-details'),
                    meta: {
                        title: '车辆报警详情',
                    }
            },
            { //报警中心 - 人员详情
                name: 'Alarm-alarm-personnel-details',
                path: '/alarm-management/alarm-center/alarm-personnel-details',
                component: () =>
                    import ('@views/alarm-management/alarm-center/alarm-personnel-details'),
                    meta: {
                        title: '人员报警详情',
                    }
            }]
   }