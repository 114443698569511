import Layout from '@views/Layout.vue'

export default {
    path: '/vehicle-management',
    component: Layout,
    children: [{ //车队管理
            path: '/vehicle-management/fleet-management',
            name: 'Fleet-management',
            component: () =>
                import ('@views/vehicle-management/fleet-management/fleet-management'),
                meta: {
                    keepAlive: true,
                    isBack: false
                }
        },
        { //分配车队
            path: '/vehicle-management/assign-fleet',
            name: 'Assign-fleet',
            component: () =>
                import ('@views/vehicle-management/fleet-management/assign-fleet')
        },
        { //设备管理
            path: '/vehicle-management/equipment-management',
            name: 'Equipment-management',
            component: () =>
                import ('@views/vehicle-management/equipment-management/equipment-management'),
                meta: {
                    keepAlive: true,
                    isBack: false
                }
        },
        { //新增设备
            path: '/vehicle-management/add-device',
            name: 'Add-device',
            component: () =>
                import ('@views/vehicle-management/equipment-management/add-device')
        },
        { //sim卡管理
            path: '/vehicle-management/sim-management',
            name: 'Sim-management',
            component: () =>
                import ('@views/vehicle-management/sim-management/sim-management'),
                meta: {
                    keepAlive: true,
                    isBack: false
                }
        },
        { //sim新增
            path: '/vehicle-management/addSIM',
            name: 'AddSIM',
            component: () =>
                import ('@views/vehicle-management/sim-management/addSIM')
        },
        { //车辆信息
            path: '/vehicle-management/car-management',
            name: 'Car-management',
            component: () =>
                import ('@views/vehicle-management/car-management/car-management'),
                meta: {
                    // keepAlive: true,
                    // isBack: false
                }
        },
        { //新增车辆信息
            path: '/vehicle-management/add-car',
            name: 'Add-car',
            component: () =>
                import ('@views/vehicle-management/car-management/add-car')
        },
        { //修改车辆信息
            path: '/vehicle-management/edit-car',
            name: 'Edit-car',
            component: () =>
                import ('@views/vehicle-management/car-management/edit-car')
        }
    ]
}