import Layout from '@views/Layout.vue'

export default {
    path: '/safety-center',
    component: Layout,
    children: [{
        name: 'SafetyMonitoring',
        path: '/safety-center/safety-monitoring',
        component: () =>
            import ('@views/safety-center/safety-monitoring/safety-monitoring'),
        meta: {
            keepAlive: true,
            isBack: false
        }
    }, {
        path: '/safety-center/historical-playback',
        name: 'HistoricalPlayback',
        component: () =>
            import ('@views/safety-center/historical-playback/historical-playback'),
        meta: {
            keepAlive: true,
        }
    }, {
        path: '/safety-center/alarm-management',
        name: 'AlarmManagement',
        component: () =>
            import ('@views/safety-center/alarm-management/alarm-center'),
        meta: {
            keepAlive: true,
            isBack: false
        }
    }, {
        path: '/safety-center/alarm-management/alarmDisposeDetails',
        name: 'AlarmDisposeDetails',
        component: () =>
            import ('@views/safety-center/alarm-management/alarmDisposeDetails'),
    }, {
        path: '/safety-center/alarm-management/annexs',
        name: 'Annexs',
        component: () =>
            import ('@views/safety-center/alarm-management/annexs'),
        meta: {
            keepAlive: true,
        }
    }, {
        path: '/safety-center/evidence-center',
        name: 'EvidenceCenter',
        component: () =>
            import ('@views/safety-center/alarm-management/evidence-center'),
        meta: {
            keepAlive: true,
            isBack: false
        }
    }, {
        path: '/safety-center/alarm-management/evidenceDisposeDetails',
        name: 'EvidenceDisposeDetails',
        component: () =>
            import ('@views/safety-center/alarm-management/evidenceDisposeDetails'),
    }, {
        path: '/safety-center/safety-monitoring/components/real-time-play-video',
        name: 'RealTimePlayVideo',
        component: () =>
            import ('@views/safety-center/safety-monitoring/components/real-time-play-video'),
    }]
}
