import Layout from '@views/Layout.vue'

export default {
    path: '/home',
    component: Layout,
    children: [{
        path: '/home',
        name: 'Home',
        component: () =>
            import ('@views/home/index'),
        meta: {
            title: '首页',
            noCache: true,
            affix: true
        },
    }]
}
