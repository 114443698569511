import Axios from 'axios'

export default {
    /**
     * 安全监控 -- 设备重启
     */
    async reStart(params) {
        return await Axios.post(`/IOV/downMessageIOVService/restart`, params)
    },
    /**
     * 油耗管理 -- 实时油量列表
     */
    async listRealTimeOil(params) {
        return await Axios.post(`/IOV/oilOverview/listRealTimeOil`, params)
    },
    /**
     * 油耗管理 -- 油量查询车辆下拉
     */
     async oilQuery_getHasFuelPlateNumberList(params) {
        return await Axios.post(`/car/carInfo/getHasFuelPlateNumberList`, params)
    },
    /**
     * 油耗管理 -- 油量查询
     */
     async oilQuery_queryOilData(params) {
        return await Axios.post(`/iov/oilQuery/queryOilData`, params)
    },    
    /**
     * 油耗管理 -- 油量查询
     */
     async oilQuery_getRealTimeOilData(params) {
        return await Axios.post(`/iov/oilQuery/getRealTimeOilData`, params)
    },  

    /**
     * 油耗管理 -- 实时油量详情
     */
    async getRealTimeOilDetail(params) {
        return await Axios.post(`/IOV/oilOverview/getRealTimeOilDetail`, params)
    },
    /**
     * 油耗管理
     * eventType: 加油情况 - 油量骤减
     */
    async listOilEventInfo(params) {
        return await Axios.post(`/IOV/oilManage/listOilEventInfo`, params)
    },
    /**
     * 油耗管理
     * eventType: 加油 - 油量骤减
     */
    async getOilEventDetailInfo(params) {
        return await Axios.post(`/IOV/oilManage/getOilEventDetailInfo`, params)
    },
    /**
     * 油耗管理
     * 获取车辆品牌型号列表
     */
    async getListCarBrand(params) {
        return await Axios.post(`/IOV/carInfo/listCarBrand`, params)
    },
    /**
     * 安全监控 -- 停车点
     */
    async GetStopPointList(params) {
        return await Axios.post(`/steel/web/getStopPointList`, params)
    },
    /**
     * 安全监控 -- 报警列表
     */
    async LsitAlarmDeal(params) {
        return await Axios.post(`/activeSafety/alarmManage/lsitAlarmDeal`, params)
    },
    /**
     * 安全监控 -- TTs
     */
    async DownMsg(params) {
        return await Axios.post(`/IOV/downMessageIOVService/downMsg`, params)
    },
    /**
     * 安全监控 -- 轨迹
     */
    async GetDeviceHistoryUsage(params) {
        return await Axios.post(`/steel/web/getDeviceHistoryUsage`, params)
    },
    /**
     * 安全监控 -- 下线
     */
    async OffLine(params) {
        return await Axios.post(`/IOV/downMessageIOVService/offLine`, params)
    },
    /**
     * 安全监控 -- 上线
     */
    async OnLine(params) {
        return await Axios.post(`/IOV/downMessageIOVService/onLine`, params)
    },
    /**
     * 安全监控 -- 查询今日ai报警总数
     */
    async GetTodayAiAlarmCount(params) {
        return await Axios.post(`/IOV/alarmManage/getTodayAiAlarmCount`, params)
    },
    /**
     * 安全监控 --查询今日ai报警列表
     */
    async ListTodayAiAlarm(params) {
        return await Axios.post(`/IOV/alarmManage/listTodayAiAlarm`, params)
    },
    /**
     * 安全监控 -- 实时视频监控 -- 获取车辆的实时位置
     */
    async GetRealTimeCarLocation(params) {
        return await Axios.post(`/IOV/realtimeLocationIOVService/getRealTimeCarLocation`, params)
    },
    /**
     * 安全监控 -- 实时位置监控 -- 公司车队车辆信息
     */
    async getRealTimeCarInfoList(params) {
        return await Axios.post(`/IOV/realtimeLocationIOVService/getRealTimeCarInfoList`, params)
    },
    /**
     * 安全监控 -- 实时位置监控 -- 获取车辆状态表
     */
    async getRealTimeCarStatus(params) {
        return await Axios.post(`/IOV/realtimeLocationIOVService/getRealTimeCarStatus`, params)
    },
    /**
     * 安全监控 -- 实时位置监控 -- 获取报警列表
     */
    async getRealTimeAlarmList(params) {
        return await Axios.post(`/IOV/realtimeLocationIOVService/getRealTimeAlarmList`, params)
    },
    /**
     * 安全监控 -- 实时位置监控 -- 区域查车
     */
    async getCarWithRegion(params) {
        return await Axios.post(`/IOV/realtimeLocationIOVService/getCarWithRegion`, params)
    },
    /**
     * 安全监控 -- 实时位置监控 -- 定时定位区域查询
     */
    async searchByLocationAndTime(params) {
        return await Axios.post(`/IOV/regionInfoIOVService/listRegionInfoByTenantIdWithTime`, params)
    },
    /**
     * 安全监控 -- 实时位置监控 -- 定时定位新增区域
     * 新增类型0-预设区域 1-定时定位查车
     * 区域类型0-矩形 1-圆形 2-多边形
     */
    async insertRegionInfo(params) {
        return await Axios.post(`/IOV/regionInfoIOVService/insertRegionInfo`, params)
    },
    /**
     * 安全监控 -- 实时位置监控 -- 定时定位查车查询结果
     */
    async searchByRegionAndTime(params) {
        return await Axios.post(`/IOV/regionInfoIOVService/listCarByTimeAndRegion`, params)
    },
    /**
     * 安全监控 -- 实时位置监控 -- 定时定位查车 -- 发送消息
     */
    async sendMessage(params) {
        return await Axios.post(`/IOV/deviceVoice/sendMessage`, params)
    },
    /**
     * 安全监控 -- 实时位置监控 -- 删除区域记录
     */
    async DeleteRegionInfo(params) {
        return await Axios.post(`/IOV/regionInfoIOVService/batchDeleteRegionInfo`, params)
    },
    /**
     * 安全监控 -- 实时位置监控 -- 预设区域查车
     */
    async searchCarByDefaultRegion(params) {
        return await Axios.post(`/IOV/realtimeLocationIOVService/listCarByDefaultRegion`, params)
    },
    /**
     * 安全监控 -- 实时位置监控 -- 定时定位查车查询结果导出
     */
    async searchByLocationAndTimeResultExport(params) {
        return await Axios.post(`/IOV/excelExport/listCarByTimeAndRegionExport`, params)
    },
    /**
     * 安全监控 -- 实时位置监控 -- 查询预设区域
     */
    async searchDefaultRegion(params) {
        return await Axios.post(`/IOV/regionInfoIOVService/listDefaultRegionInfoByTenantId`, params)
    },
    /**
     * 登录
     */
    async Login(params) {
        return await Axios.post(`/IOV/login`, params)
    },
    /**
     * 登出
     */
    async Logout(params) {
        return await Axios.post(`/IOV/logout`, params)
    },
    /**
     * 提交忘记密码验证码
     */
    async PasswordOperate(params) {
        return await Axios.post(`/userInfo/passwordOperate`, params)
    },
    /**
     * 忘记密码验证码
     */
    async SendVerifyCode(params) {
        return await Axios.post(`/userInfo/sendVerifyCode`, params)
    },
    /**
     * 验证用户是否已注册为平台用户
     */
    async UserIdVerify(params) {
        return await Axios.post(`/userInfo/userIdVerify`, params)
    },
    /**
     * 菜单
     */
    async Menu(params) {
        return await Axios.post(`/steel/web/platform/menu`, params)
    },
    /**
     * 个人中心展示信息接口
     */
    async Self(params) {
        return await Axios.post(`/IOV/user/self`, params)
    },
    // 首页
    /**
     * 首页 -- 获取在线时长和车辆利用率
     */
    async OnLineTimeAndUtilization(params) {
        return await Axios.post(`/IOV/index/getOnLineTimeAndUtilization`, params)
    },
    /**
     * 首页 -- 获取实时报警
     */
    async RealTimeAlarmData(params) {
        return await Axios.post(`/IOV/index/getRealTimeAlarmData`, params)
    },
    /**
     * 首页 -- 报警分布
     */
    async DataAlarmDistributionData(params) {
        return await Axios.post(`/IOV/indexIOVService/getAlarmDistributionData`, params)
    },
    /**
     * 首页 -- 获取今日新增数据
     */
    async TodayIncreaseData(params) {
        return await Axios.post(`/IOV/index/getTodayIncreaseData`, params)
    },
    /**
     * 首页 -- 获取不同状态的车辆信息
     */
    async CarStatusCount(params) {
        return await Axios.post(`/IOV/indexIOVService/getCarStatusCount`, params)
    },
    /**
     * 首页 -- 获取今日新增数据
     */
    async ExpireReminderData(params) {
        return await Axios.post(`/IOV/index/getExpireReminderData`, params)
    },
    /**
     * 安全监控 -- 安全看板 -- 获取报警信息分布数据
     */
    async getAlarmDistributionData(params) {
        return await Axios.post(`/IOV/safetyBoard/getAlarmDistributionData`, params)
    },
    /**
     * 安全监控 -- 安全看板 -- 获取折线图数据
     */
    async getAlarmLineChartData(params) {
        return await Axios.post(`/IOV/safetyBoard/getAlarmLineChartData`, params)
    },
    /**
     * 安全监控 -- 安全看板 -- 车辆报警排名
     */
    async getAlarmRanking(params) {
        return await Axios.post(`/IOV/safetyBoardIOVService/getAlarmRanking`, params)
    },
    /**
     * 安全监控 -- 安全看板 -- 司机得分排名
     */
    async getDriverScoreRanking(params) {
        return await Axios.post(`/IOV/safetyBoardIOVService/getDriverScoreRanking`, params)
    },
    /**
     * 安全监控 -- 安全看板 -- 查询顶部统计信息
     */
    async getTopStaticData(params) {
        return await Axios.post(`/IOV/safetyBoard/getTopStaticData`, params)
    },
    /**
     * 报警管理 -- 报警分析 -- 获得企业报警信息报警分布-饼状图数据
     */
    async GetAlarmDistributionData(params) {
        return await Axios.post(`/IOV/alarmManage/getAlarmDistributionData`, params)
    },
    // 指挥总览
    /**
     * 订单统计
     */
    async GetOrderNums(params) {
        return await Axios.post(`/IOV/dataCloudIOVService/getOrderNums`, params)
    },
    /**
     * 报警趋势
     */
    async GetAlarmTrendPerDay(params) {
        return await Axios.post(`/IOV/dataCloudIOVService/getAlarmTrendPerDay`, params)
    },
    /**
     * 实时报警图片
     */
    async GetRealTimeAlarmPicUrls(params) {
        return await Axios.post(`/IOV/dataCloudIOVService/getRealTimeAlarmPicUrls`, params)
    },
    /**
     * 实时报警
     */
    async GetRealTimeAlarmData(params) {
        return await Axios.post(`/IOV/dataCloudIOVService/getRealTimeAlarmData`, params)
    },
    /**
     * 运行的总里程
     */
    async GetStatisticalData(params) {
        return await Axios.post(`/IOV/dataCloudIOVService/getStatisticalData`, params)
    },
    /**
     * 到期提醒
     */
    async GetExpireandNormal(params) {
        return await Axios.post(`/IOV/dataCloudIOVService/getExpireandNormal`, params)
    },
    /**
     * 订单图片
     */
    async GetPictureUrls(params) {
        return await Axios.post(`/IOV/dataCloudIOVService/getPictureUrls`, params)
    },
    /**
     * 内部驾驶员统计列表
     */
    async ListDriverAccountCheckingInfo(params) {
        return await Axios.post(`/steel/accountCheckingInfoService/listDriverAccountCheckingInfoStatistics`, params)
    },
    /**
     * 结算客户对账订单明细excel导出
     *
     */
    async Excel(params) {
        return await Axios.post(`/steel/web/excel`, params)
    },
    /**
     * 会计对账
     * 对账
     */
    async InsertAccountCheckingInfo(params) {
        return await Axios.post(`/steel/accountCheckingInfoService/insertAccountCheckingInfo`, params)
    },
    /**
     * 会计对账
     * 订单明细列表
     */
    async ListOrderAtAccountChecking(params) {
        return await Axios.post(`/steel/web/listOrderAtAccountChecking`, params)
    },
    /**
     * 会计对账
     * 查询客户对账列表
     */
    async CheckingInfoStatistics(params) {
        return await Axios.post(`/steel/accountCheckingInfoService/listPartnerAccountCheckingInfoStatistics`, params)
    },


    /**
     * 智连-会计对账-对账订单列表
     * @param {*} params 
     */
    async accounting_getAccountCheckingInfoByObjectId(params) {
        return await Axios.post(`/steel/SteelAccountCheckingInfoService/getAccountCheckingInfoByObjectId`, params)
    },

    /**
     * 智连-会计对账-对账订单列表
     * @param {*} params 
     */
    async accounting_getOrderListByAccountCheckingId(params) {
        return await Axios.post(`/steel/SteelAccountCheckingInfoService/getOrderListByAccountCheckingId`, params)
    },
    /**
     * 智连-会计对账-列表移入移出
     * @param {*} params 
     */
    async accounting_addOrRemoveOrderUnderAccount(params) {
        return await Axios.post(`/steel/SteelAccountCheckingInfoService/addOrRemoveOrderUnderAccount`, params)
    },
    /**
     * 智连-会计对账-获取对账信息
     * @param {*} params 
     */
    async accounting_accountCheckingBackInfo(params) {
        return await Axios.post(`/steel/SteelAccountCheckingInfoService/accountCheckingBackInfo`, params)
    },
    
    /**
     * 智连-会计对账
     * @param {*} params 
     */
    async accounting_accountChecking(params) {
        return await Axios.post(`/steel/SteelAccountCheckingInfoService/accountChecking`, params)
    },
    

    /**
     * 订单列表查询
     * 多模块共用：订单查询模块、托运录单模块、外发调度模块、交单审核模块、订单核算模块
     */
    async OrderList(params) {
        return await Axios.post(`/steel/web/order/list`, params)
    },
    /**
     * 订单列表详情
     * 多模块共用：订单查询模块、托运录单模块、外发调度模块、交单审核模块、订单核算模块
     */
    async OrderGet(params) {
        return await Axios.post(`/steel/web/order/get`, params)
    },
    /**
     * 订单列表更新
     * 多模块共用：订单查询模块、托运录单模块、外发调度模块、交单审核模块、订单核算模块
     */
    async OrderUpdate(params) {
        return await Axios.post(`/steel/order/updateOrder`, params)
    },
    /**
     * 托运录单
     * 废单
     */
    async deleteOrder(params) {
        return await Axios.post(`/steel/order/deleteOrder`, params)
    },
    /**
     * 托运录单
     * 录单
     */
    async recordOrder(params) {
        return await Axios.post(`/steel/recordOrderInfoService/recordOrder`, params)
    },
    /**
     * 托运录单
     * 往来单位列表--自动取值 简称和全称
     */
    async shortList(params) {
        return await Axios.post(`/steel/web/partner/shortlist`, params)
    },

    /**
     * 上传图片
     */
    async UploadImg(params) {
        return await Axios.post(`/iot/files/upload`, params)
    },

    /**
     * 交单
     */
    async commitOrder(params) {
        return await Axios.post(`/steel/order/commitOrder`, params)
    },
    /**
     * 上传交单图片
     */
    async saveImage(params) {
        return await Axios.post(`/steel/image/saveImage`, params)
    },


    /**
     * 运维管理
     * 内部驾驶员维护
     * 内部驾驶员维护列表
     */
    async driverList(params) {
        return await Axios.post('/steel/web/driver/list', params)
    },
    /**
     * 运维管理
     * 内部驾驶员维护
     * 内部驾驶员维护列表 -- 简单版本
     */
    async driverFastList(params) {
        return await Axios.post('/steel/web/driver/nameList', params)
    },

    /**
     * 运维管理
     * 内部驾驶员新增
     */
    async driverSave(params) {
        return await Axios.post('/steel/web/driver/save', params)
    },

    /**
     * 运维管理
     * 内部驾驶员删除
     */
    async driverDelete(params) {
        return await Axios.post('/steel/web/driver/delete', params)
    },

    /**
     * 运维管理
     * 内部驾驶员详情
     */
    async driverGet(params) {
        return await Axios.post('/steel/web/driver/get', params)
    },

    /**
     * 运维管理
     * 内部驾驶员修改
     */
    async driverUpdate(params) {
        return await Axios.post('/steel/web/driver/update', params)
    },
    /**
     * 运维管理
     * 内部驾驶员获取挂靠单位
     */
    async listCarUnit(params) {
        return await Axios.post('/IOV/carInfoService/listCarUnit', params)
    },
    /**
     * 运维管理
     * 往来单位列表
     */
    async partnerList(params) {
        return await Axios.post(`/steel/web/partner/list`, params)
    },
    /**
     * 运维管理
     * 往来单位维护
     * 往来单位维护列表/steel/web/partner/delete
     */
    async operationsList(params) {
        return await Axios.post('/steel/web/partner/list', params)
    },
    /**
     * 运维管理
     * 往来单位维护
     * 新增往来单位维护
     */
    async operationsSave(params) {
        return await Axios.post('/steel/web/partner/save', params)
    },
    /**
     * 运维管理
     * 往来单位维护
     * 往来单位维护列表导出
     */
    async operationsExcel(params) {
        return await Axios.post('/steel/web/excel', params)
    },
    /**
     * 运维管理
     * 往来单位维护
     * 往来单位维护删除
     */
    async operationsDelete(params) {
        return await Axios.post('/steel/web/partner/delete', params)
    },
    /**
     * 运维管理
     * 往来单位维护
     * 往来单位维护详情
     */
    async operationsGet(params) {
        return await Axios.post('/steel/web/partner/get', params)
    },
    /**
     * 运维管理
     * 往来单位维护
     * 往来单位维护修改
     */
    async operationsUpdate(params) {
        return await Axios.post('/steel/web/partner/update', params)
    },
    /**
     * 运维管理
     * 车辆信息列表
     */
    async carInfoList(params) {
        return await Axios.post('/steel/carSatelliteInfoServic/listCarSatelliteInfoByCondition', params)
    },


    /**
     * 业务报表
     * 客户报表
     * 客户报表
     */
    async customerPartner(params) {
        return await Axios.post('/steel/web/report/partner', params)
    },
    /**
     * 业务报表
     * 驾驶员报表
     * 驾驶员报表
     */
    async customerDriver(params) {
        return await Axios.post('/steel/web/report/driver', params)
    },
    /**
     * 业务报表
     * 驾驶员报表
     * 驾驶员报表
     */
    async carDriver(params) {
        return await Axios.post('/steel/web/report/car', params)
    },
    /**
     * 业务报表
     * 业务类型报表
     * 业务类型报表
     */
    async customerBusinessType(params) {
        return await Axios.post('/steel/web/report/businessType', params)
    },
    /**
     * 业务报表
     * 地点报表
     * 地点报表
     */
    async customerDestination(params) {
        return await Axios.post('/steel/web/report/destination', params)
    },
    /**
     * 业务报表
     * 报表明细
     * 报表明细
     */
    async reportDetails(params) {
        return await Axios.post('/steel/web/report/detail', params)
    },

    /**
     * 业务报表
     * 明细
     * 明细导出
     */
    async excelAll(params) {
        return await Axios.post('/steel/web/excel', params)
    },

    /**
     * 状态报表
     */
    async statusDriver(params) {
        return await Axios.post('/steel/web/report/status', params)
    },
    /**
     * 状态报表
     *  状态报表导出
     */
    async statusDriverExcel(params) {
        return await Axios.post('/steel/web/excel', params)
    },

    /**
     * 获得excel导出列相关信息
     */
    async GetExcelExportKeyAndColumn(params) {
        return await Axios.post(`/IOV/excelExport/getExcelExportKeyAndColumn`, params)
    },

    /**
     * 导出excel提交模板
     */
    async ExcelImportTemplate(params) {
        return await Axios.post(`/IOV/excelImport/excelImportTemplate`, params)
    },
    /**
     * 导入excel提交模板/steel/web/excel
     */
    async ExcelImport(params) {
        return await Axios.post(`/IOV/excelImport/excelImport`, params)
    },
    /**
     *  业务类型报表导出
     */
    async reportExcel(params) {
        return await Axios.post(`/steel/web/excel`, params)
    },
    /**
     *  订单查询
     * 订单打印
     */
    async printOrder(params) {
        return await Axios.post(`/steel/web/order/print`, params)
    },
    /**
     * 出纳结算
     * 往来单位结算
     * 往来单位结算列表
     */
    async listAccountCheckingInfoByCondition(params) {
        return await Axios.post('/steel/accountCheckingInfoService/listAccountCheckingInfoByCondition', params)
    },
    /**
     * 出纳结算
     * 往来单位结算
     * 往来单位结算明细
     */
    async listDetailByCheckingId(params) {
        return await Axios.post('/steel/steelSettlementDetailInfoService/listDetailByCheckingId', params)
    },
    /**
     * 出纳结算
     * 往来单位结算
     * 根据对账单编号查询对账信息
     */
    async getAccountCheckingInfoByAccountCheckingId(params) {
        return await Axios.post('/steel/accountCheckingInfoService/getAccountCheckingInfoByAccountCheckingId', params)
    },
    /**
     * 企业管理维护
     * 企业管理列表
     */
    async settlementList(params) {
        return await Axios.post('/steel/web/account/list', params)
    },
    /**
     * 企业管理维护
     * 获取公司信息
     */
    async getCompanyInfo(params) {
        return await Axios.post('/iov/company/getCompanyInfoByTenantId', params)
    },
    /**
     * 企业管理维护
     * 企业管理新增
     */
    async addCompanyInfo(params) {
        return await Axios.post('/steel/web/account/save', params)
    },
    /**
     * 企业管理维护
     * 企业管理编辑
     */
    async updateCompanyInfo(params) {
        return await Axios.post('/steel/web/account/update', params)
    },
    /**
     * 企业管理维护
     * 企业管理详情
     */
    async detailsCompanyInfo(params) {
        return await Axios.post('/steel/web/account/get', params)
    },
    /**
     * 企业管理维护
     * 企业管理删除
     */
    async deleteCompanyInfo(params) {
        return await Axios.post('/steel/web/account/delete', params)
    },
    /**
     * 企业管理维护
     * 该公司是否有指定类型的账户
     * 企业账户、普通账户
     */
    async AccountType(params) {
        return await Axios.post('/steel/web/account/hasBusinessAccount', params)
    },
    /**
     * 出纳结算
     * 往来单位结算
     * 新增结算
     */
    async insertDetailInfo(params) {
        return await Axios.post('/steel/steelSettlementDetailInfoService/insertDetailInfo', params)
    },
    /**
     * 出纳结算
     * 往来单位结算
     * 删除结算明细
     */
    async deleteDetailByDetailId(params) {
        return await Axios.post('/steel/steelSettlementDetailInfoService/deleteDetailByDetailId', params)
    },
    /**
     * 出纳结算
     * 往来单位结算
     * 修改结算明细
     */
    async updateDetailByDetailId(params) {
        return await Axios.post('/steel/steelSettlementDetailInfoService/updateDetailByDetailId', params)
    },
    /**
     * 出纳结算
     * 往来单位结算
     * 对账单订单列表
     */
    async listOrderByOrderList(params) {
        return await Axios.post('/steel/accountCheckingInfoService/listOrderByOrderList', params)
    },
    /**
     * 出纳结算
     * 往来单位结算
     * 根据结算编号查询结算信息
     */
    async getSettlementDetailInfoById(params) {
        return await Axios.post('/steel/steelSettlementDetailInfoService/getSettlementDetailInfoById', params)
    },
    /**
     * 出纳结算
     * 外请车主结算
     * 外请车主结算列表
     */
    async listOrderByDriverSettlement(params) {
        return await Axios.post('/steel/accountCheckingInfoService/listOrderByDriverSettlement', params)
    },

    /**
     * 出纳结算
     * 外请车主结算
     * 导出外请车主结算列表
     */
    async listOrderExcel(params) {
        return await Axios.post('/steel/web/excel', params)
    },

    /**
     * 车辆管理
     * 车队管理
     * 车队管理列表
     */
    async listCarTeamInfoByQueryCondition(params) {
        return await Axios.post('/IOV/carTeamInfo/listCarTeamInfoByQueryCondition', params)
    },
    /**
     * 车辆管理
     * 车队管理
     * 车队管理-删除车队item
     */
    async DeleteMotorcadeItem(params) {
        return await Axios.post(`/IOV/carTeamInfo/batchDeleteCarTeamInfo`, params)
    },
    /**
     * 车辆管理
     * 车队管理
     * 车队管理-新增车队-获取公司id
     */
    async companyList(params) {
        return await Axios.post(`/IOV/company/list`, params)
    },
    /**
     * 车辆管理
     * 车队管理
     * 车队管理-新增车队
     */
    async AddMotorcade(params) {
        return await Axios.post(`/IOV/carTeamInfo/insertCarTeamInfo`, params)
    },
    /**
     * 车辆管理
     * 车队管理
     * 车队管理-查询车队所属公司列表
     */
    async listCompanyInfoForAddCarTeam(params) {
        return await Axios.post(`/IOV/carTeamInfoService/listCompanyInfoForAddCarTeam`, params)
    },
    /**
     * 车辆管理
     * 车队管理
     * 车队管理-编辑车队
     */
    async getCarTeamInfoByGroupId(params) {
        return await Axios.post(`/IOV/carTeamInfo/getCarTeamInfoByGroupId`, params)
    },
    /**
     * 车辆管理
     * 车队管理
     * 更新车队信息
     */
    async UpdateMotorcadeInfo(params) {
        return await Axios.post(`/IOV/carTeamInfo/updateCarTeamInfoByGroupId`, params)
    },
    /**
     * * 车辆管理
     * 车队管理
     * 车辆分配--获取未分配车辆信息
     */
    async UnallottedCarInfo(params) {
        return await Axios.post(`/IOV/VehicleAllocation/listCarInfoNotAllocated`, params)
    },
    /**
     * 车辆管理
     * 车队管理
     * 车辆分配--获取分配车辆信息
     */
    async allottedCarInfo(params) {
        return await Axios.post(`/IOV/VehicleAllocation/listCarInfoByGroupIdAndCompany`, params)
    },
    /**
     * 车队管理-查询车队信息列表
     */
    async SearchMotorcadeList(params) {
        return await Axios.post(`/IOV/carTeamInfo/listCarTeamInfoByQueryCondition`, params)
    },
    /**
     * 更新车辆车队--支持批量更新
     */
    async UpdateAllottedCarTable(params) {
        return await Axios.post(`/IOV/VehicleAllocation/batchUpdateGroupIdByPlateNumber`, params)
    },
    /**
     * 车辆分配--获取公司列表及公司下车队信息列表
     */
    async SearchMotorcade(params) {
        return await Axios.post(`/IOV/VehicleAllocation/listCompanyAndCarTeam`, params)
    },
    /**
     * 查询企业及子企业信息列表
     */
    async SearchCompanyList(params) {
        return await Axios.post(`/IOV/company/listCompanyInfoByTenantId`, params)
    },
    /*设备管理-设备管理*/
    /**
     * 查询设备列表
     */
    async ListDeviceInfo(params) {
        return await Axios.post(`/IOV/deviceIOVService/listDeviceInfo`, params)
    },
    /**
     * 查询未使用的SIM卡号
     */
    async GetNotEnableSimNumber(params) {
        return await Axios.post(`/IOV/simInfo/getNotEnableSimNumber`, params)
    },
    /**
     * 导出设备列表的Excel
     */
    async DeviceInfoExport(params) {
        return await Axios.post(`/IOV/excelExportIOVService/deviceInfoExport`, params)
    },
    /**
     * 修改设备列表
     */
    async UpdateDeviceInfo(params) {
        return await Axios.post(`/IOV/deviceIOVService/updateDeviceInfo`, params)
    },
    /**
     * 删除设备列表
     */
    async DeleteDeviceInfo(params) {
        return await Axios.post(`/IOV/deviceIOVService/deleteDeviceInfo`, params)
    },
    /**
     * 新增设备列表
     */
    async InsertDeviceInfo(params) {
        return await Axios.post(`/IOV/deviceIOVService/insertDeviceInfo`, params)
    },
    /**
     * 查询设备详情
     */
    async GetDeviceInfo(params) {
        return await Axios.post(`/IOV/deviceIOVService/getDeviceInfo`, params)
    },
    /**
     * SIM卡查询
     */
    async SimInfoByQueryCondition(params) {
        return await Axios.post(`/IOV/simInfo/listSimInfoByQueryCondition`, params)
    },
    /**
     * SIM卡删除
     */
    async DeleteSimInfo(params) {
        return await Axios.post(`/IOV/simInfo/deleteSimInfo`, params)
    },
    /**
     * 新增SIM卡
     */
    async InsertSimInfo(params) {
        return await Axios.post(`/IOV/simInfo/insertSimInfo`, params)
    },
    /**
     * SIM卡详情
     */
    async GetSimInfoBySimNumber(params) {
        return await Axios.post(`/IOV/simInfo/getSimInfoBySimNumber`, params)
    },
    /**
     * 编辑SIM卡
     */
    async UpdateSimInfo(params) {
        return await Axios.post(`/IOV/simInfo/updateSimInfo`, params)
    },

    /**
     * 获取车辆信息列表
     */
    async listCarSatelliteInfoByCondition(params) {
        return await Axios.post(`/steel/carSatelliteInfoServic/listCarSatelliteInfoByCondition`, params)
    },
    /**
     * 删除获取车辆信息列表/steel/carSatelliteInfoService/listUninstallDeviceId
     */
    async deleteCarSatelliteInfoByPlateNumber(params) {
        return await Axios.post(`/steel/carSatelliteInfoServic/deleteCarSatelliteInfoByPlateNumber`, params)
    },
    /**
     * 获取未安装的设备号
     */
    async listUninstallDeviceId(params) {
        return await Axios.post(`/steel/carSatelliteInfoService/listUninstallDeviceId`, params)
    },
    /**
     * 新增车辆
     */
    async insertCarSatelliteInfo(params) {
        return await Axios.post(`/steel/carSatelliteInfoServic/insertCarSatelliteInfo`, params)
    },
    /**
     * 获取车辆详情
     */
    async getCarSatelliteInfoByPlateNumber(params) {
        return await Axios.post(`/steel/carSatelliteInfoServic/getCarSatelliteInfoByPlateNumber`, params)
    },

    /**
     * 修改车辆
     */
    async updateCarSatelliteInfoByPlateNumber(params) {
        return await Axios.post(`/steel/carSatelliteInfoServic/updateCarSatelliteInfoByPlateNumber`, params)
    },

    //平台管理
    /** 角色管理 */
    /**
     * 平台管理 -- 角色管理 -- 角色查询接口
     */
    async getList(params) {
        return await Axios.post(`/IOV/role/list`, params)
    },
    /**
     * 平台管理 -- 角色管理 -- 添加角色查询权限列表
     */
    async permissionTree(params) {
        return await Axios.post(`/IOV/role/permissionTree`, params)
    },
    /**
     * 平台管理 -- 角色管理 -- 添加角色接口
     */
    async getAddRole(params) {
        return await Axios.post(`/IOV/role/add`, params)
    },
    /**
     * 平台管理 -- 角色管理 -- 查看角色详情
     */
    async getDetail(params) {
        return await Axios.post(`/IOV/role/get`, params)
    },
    /**
     * 平台管理 -- 角色管理 -- 编辑角色详情
     */
    async getEdit(params) {
        return await Axios.post(`/IOV/role/update`, params)
    },
    /**
     * 平台管理 -- 角色管理 -- 角色删除接口
     */
    async getDelete(params) {
        return await Axios.post(`/IOV/role/delete`, params)
    },
    /**
     * 平台管理 -- 角色管理 -- 角色管理列表导出
     */
    async listRoleInfoExport(params) {
        return await Axios.post(`/IOV/excelExport/listRoleInfoExport`, params)
    },

    /** 用户管理 */
    /**
     * 平台管理 -- 用户管理 -- 列表展示用户信息
     */
    async getUserList(params) {
        return await Axios.post(`/IOV/user/list`, params)
    },
    /**
     * 平台管理 -- 用户管理 -- 查询用户详情
     */
    async getUserGet(params) {
        return await Axios.post(`/IOV/user/get`, params)
    },
    /**
     * 平台管理 -- 用户管理 -- 删除用户
     */
    async getUserDelete(params) {
        return await Axios.post(`/IOV/user/delete`, params)
    },
    /**
     * 平台管理 -- 用户管理 -- 新增用户 -- 新增用户页面加载企业下拉框
     */
    async getListCompany(params) {
        return await Axios.post(`/IOV/user/listCompany`, params)
    },
    /**
     * 平台管理 -- 用户管理 -- 新增用户 -- 根据选择的公司确定接下来的操作
     */
    async getListHandle(params) {
        return await Axios.post(`/IOV/user/handle`, params)
    },
    /**
     * 平台管理 -- 用户管理 -- 新增用户 -- 新增用户操作
     */
    async getListAdd(params) {
        return await Axios.post(`/IOV/user/add`, params)
    },
    /**
     * 平台管理 -- 用户管理 -- 修改用户信息
     */
    async updateUser(params) {
        return await Axios.post(`/IOV/user/update`, params)
    },
    /**
     * 平台管理 -- 用户管理 -- 用户列表导出
     */
    async listUserInfoExport(params) {
        return await Axios.post(`/IOV/excelExportIOVService/listUserInfoExport`, params)
    },
    /**
     *  获取视频url
     */
    async getRtmpUrls(params) {
        return await Axios.post(`/IOV/videoIOVService/getRtmpUrls`, params)
    },

    /** 报警处置 */
    /**
     * 报警管理 -- 报警处置 -- 分页查询证据中心信息
     */
    async lsitAlarmDeal(params) {
        return await Axios.post(`/activeSafety/alarmManage/lsitAlarmDeal`, params)
    },
    /** 报警处置 */
    /**
     * 报警管理 -- 处理人
     */
    async getDealUserInfo(params) {
        return await Axios.post(`/activeSafety/alarmManage/getDealUserInfo`, params)
    },
    /** 报警处置 */
    /**
     * 报警管理 -- 附件预览
     */
    async findFileByPrefix(params) {
        return await Axios.post(`/IOV/alarmManage/findFileByPrefix`, params)
    },
    /**
     * 报警管理 -- 报警处置 -- 分页查询证据中心信息
     */
    async listAlarmDealExport(params) {
        return await Axios.post(`/IOV/excelExport/listAlarmDealExport`, params)
    },
    /**
     * 报警管理 -- 报警处置 -- 报警中心证据导出
     */
    async evidenceExport(params) {
        return await Axios.post(`/IOV/alarmManage/evidenceExport`, params)
    },
    /**
     * 报警管理 -- 报警处置 -- 获得报警处理详情
     */
    async AlarmDealDetail(params) {
        return await Axios.post(`/IOV/alarmManage/getAlarmDealDetail`, params)
    },
    /**
     * 报警管理 -- 报警处置 -- 报警处理
     */
    async updateAlarmDealInfo(params) {
        return await Axios.post(`/IOV/alarmManage/updateAlarmDealInfo`, params)
    },
    /**
     * 安全中心 -- 报警管理 -- 证据中心 -- 分页查询证据中心信息
     */
    async listAlarmEvidence(params) {
        return await Axios.post(`/IOV/alarmManage/listAlarmEvidence`, params)
    },
    /**
     * 安全中心 -- 报警管理 -- 证据中心 -- 获得证据中心证据详情
     */
    async getAlarmEvidence(params) {
        return await Axios.post(`/IOV/alarmManage/getAlarmEvidence`, params)
    },
    /**
     * 安全中心 -- 报警管理 -- 证据中心 -- 证据中心excel导出
     */
    async alarmEvidenceExport(params) {
        return await Axios.post(`/IOV/excelExport/alarmEvidenceExport`, params)
    },

    /**
     * 历史视频
     * 车辆查询
     */
    async CarInfoByQueryCondition(params) {
        return await Axios.post(`/IOV/carInfo/listCarInfoByQueryCondition`, params)
    },
    /**
     * 历史回放 -- 视频回放 -- 登录获取jsession
     */
    async getJsession(params) {
        return await Axios.post(`/external/gsky/jsession`, params)
    },
    /**
     * 历史回放 -- 视频回放 -- 获取视频列表
     */
    async getHistoryVideo(params) {
        return await Axios.post(`/iov/historyVideoService/list`, params)
    },
    /**
     * 历史回放 -- 视频回放 -- 历史视频播放
     */
    async playHistoryVideo(params) {
        return await Axios.post(`/iov/historyVideoService/play`, params)
    },
    /**
     * 历史回放 -- 视频回放 -- 获取第三方平台的视频列表
     */
    async getVideoList(params) {
        return await Axios.get(`/StandardApiAction_getVideoFileInfo.action`, params)
    },
    /**
     * 历史回放 -- 视频回放 -- 根据条件查询车牌号和设备号
     */
    async PlateNumberAndDeviceNameByCondition(params) {
        return await Axios.post(`/IOV/realtimeLocationIOVService/getPlateNumberAndDeviceNameByCondition`, params)
    },
    /**
     * 获取绑定设备的车辆
     */
    async listCarWhileDeviceNotNull(params) {
        return await Axios.post(`/IOV/carInfo/listCarWhileDeviceNotNull`, params)
    },

    /**
     * 信息费报表
     */
    async listMessageOrderReport(params) {
        return await Axios.post(`/steel/accountCheckingInfoService/listMessageOrderReport`, params)
    },
    /**
     * 信息费报表导出
     */
    async listMessageExcel(params) {
        return await Axios.post(`/steel/web/excel`, params)
    },
    /**
     * 出纳收付报表
     */
    async listSteelSettlementReport(params) {
        return await Axios.post(`/steel/SteelReportService/listSteelSettlementReport`, params)
    },
    /**
     * 出纳收付报表明细
     */
    async listSteelSettlementDetailReport(params) {
        return await Axios.post(`/steel/SteelReportService/listSteelSettlementDetailReport`, params)
    },
    /**
     * 出纳收付报表导出
     */
    async listSteelExcel(params) {
        return await Axios.post(`/steel/web/excel`, params)
    },

    /**
     * 油量概况列表
     */
    async ListOilOverview(params) {
        return await Axios.post(`/IOV/oilOverview/listOilOverview`, params)
    },

    /**
     * 油量概况列表导出
     */
    async ListOilOverviewExcel(params) {
        return await Axios.post(`/steel/web/excel`, params)
    },
    /**
     * 油量概况列表详情
     */
    async GetOilOverviewDetail(params) {
        return await Axios.post(`/IOV/oilOverview/getOilOverviewDetail`, params)
    },

    /**
     * 本地回放-停车点列表
     */
    async localListParkingSpot(params) {
        return await Axios.post(`/IOV/localVideoReplyService/listParkingSpot`, params)
    },
    /**
     * 本地回放--报警列表
     */
    async localListAlarm(params) {
        return await Axios.post(`/IOV/localVideoReplyService/listAlarm`, params)
    },
    /**
     * 本地回放--通过文件名获得deviceId
     */
    async getDeviceIdByFilename(params) {
        return await Axios.post(`/IOV/localVideoReplyService/getDeviceIdByFilename`, params)
    },

    /**
     * 数据备份--excel备份数据
     */
    async excelDataBackup(params) {
        return await Axios.post(`/IOV/dataBackupService/excelDataBackup`, params)
    },
    /**
     * 数据备份--图片备份数据
     */
    async picDataBackup(params) {
        return await Axios.post(`/IOV/dataBackupService/picDataBackup`, params)
    },
    /**
     * 数据备份--excel准备数据状态查询
     */
    async getExcelDataBackupStatus(params) {
        return await Axios.post(`/IOV/dataBackupService/getExcelDataBackupStatus`, params)
    },
    /**
     * 数据备份--图片准备数据状态查询
     */
    async getPicDataBackupStatus(params) {
        return await Axios.post(`/IOV/dataBackupService/getPicDataBackupStatus`, params)
    },

    /**
     * -----------------托运地址--------------------
     */



    // 托运地址
    async consignOrder_getAddressSummaryList(params) {
        return await Axios.post(`/smartLogistics/AddressManage/getAddressSummaryList`, params)
    },
    // 托运地址-公共地址明细
    async consignOrder_getAddressDetailList(params) {
        return await Axios.post(`/smartLogistics/AddressManage/getAddressDetailList`, params)
    },
    // 托运地址-公共地址明细-新增
    async consignOrder_insertSteelAddressInfo(params) {
        return await Axios.post(`/steel/applet/insertSteelAddressInfo`, params)
    },
    // 托运地址-公共地址明细-修改
    async consignOrder_updateSteelAddressInfo(params) {
        return await Axios.post(`/steel/applet/updateSteelAddressInfo`, params)
    },
    // 托运地址-公共地址明细-删除
    async consignOrder_deleteAddressInfo(params) {
        return await Axios.post(`/steel/applet/deleteAddressInfo`, params)
    },
    // 地址簿
    async consignOrder_getAllAddressListForPC(params) {
        return await Axios.post(`/smartLogistics/AddressManage/getAllAddressListForPC`, params)
    },

    // 地址簿
    async app_apkUpgradeService(params) {
        return await Axios.post(`/steel/app/apkUpgradeService`, params)
    },


    /**
     * 实时监管 -- 查看视频是否正在播放中
     */
    async commonService_getVideoPlayingFlag(params) {
        return await Axios.post(`/platformFunction/commonService/getVideoPlayingFlag`, params)
    },
    /**
     * 实时监管 -- 设备播放标识存放
     */
    async commonService_setVideoPlayingFlag(params) {
        return await Axios.post(`/platformFunction/commonService/setVideoPlayingFlag`, params)
    },



    /**
     * 外请车对账列表（最外面）
     */
    async commonService_WQAccountCheckingList(params) {
        return await Axios.post(`/steel/WQOrderInfo/WQAccountCheckingList`, params)
    },
    /**
     * 并入已有对账单下拉
     */
    async accounting_getMergeWQAccountChecking(params) {
        return await Axios.post(`/steel/SteelAccountCheckingInfoService/getMergeWQAccountChecking`, params)
    },
    /**
     * 外请车订单加入对账单
     */
    async accounting_WQAddToAccountChecking(params) {
        return await Axios.post(`/steel/SteelAccountCheckingInfoService/WQAddToAccountChecking`, params)
    },
    /**
     * 外请车对账列表（里面）
     */
    async accounting_getWQCheckingList(params) {
        return await Axios.post(`/steel/SteelAccountCheckingInfoService/getWQCheckingList`, params)
    },
    /**
     * 获取外请车对账信息
     */
    async accounting_WQAccountCheckingBackInfo(params) {
        return await Axios.post(`/steel/SteelAccountCheckingInfoService/WQAccountCheckingBackInfo`, params)
    },
    /**
     * 外请车对账-对账
     */
    async accounting_WQAccountChecking(params) {
        return await Axios.post(`/steel/SteelAccountCheckingInfoService/WQAccountChecking`, params)
    },
    /**
     * 外请车结算
     */
    async cashierSettlement_getWQAccountChecking(params) {
        return await Axios.post(`/steel/SteelAccountCheckingInfoService/getWQAccountChecking`, params)
    },
    /**
     * 外请车明细列表移入/出
     */
    async accounting_WQAccountAddOrRemoveOrder(params) {
        return await Axios.post(`/steel/SteelAccountCheckingInfoService/WQAccountAddOrRemoveOrder`, params)
    },
    
    /**
     * 客户对账单所有列表
     */
    async accounting_getPartAccountCheckingList(params) {
        return await Axios.post(`/steel/SteelAccountCheckingInfoService/getPartAccountCheckingList`, params)
    },
    
    /**
     * 车队详情
     */
    async vehicleManagement_carTeamList(params) {
        return await Axios.post(`/car/carInfo/carTeamList`, params)
    },

    /**
     * 获取导出orderListId
     */
    async accounting_queryOrderListByAccountCheckingId(params) {
        return await Axios.post(`/steel/SteelAccountCheckingInfoService/queryOrderListByAccountCheckingId`, params)
    },

    // 删除空的对账单
    async accounting_deleteAccountChecking(params) {
        return await Axios.post(`/steel/accountCheckingInfoService/deleteAccountChecking`, params)
    },
    
    //导出
    async getExcel(params) {
        return await Axios.post(`/steel/web/excel`, params)
    },

    //个人地址簿新增
    async address_addAddressInfoFromPC(params) {
        return await Axios.post(`/steel/address/addAddressInfoFromPC`, params)
    },
}