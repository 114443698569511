// 枚举文件
export default {
    CompanyList: {
        list: ['板材', '型材', '管材', '螺纹', '线材', '预制叠合板','预制墙板','预制件','其他']
    },
    OrderType: {
        list: [{ label: '内转', value: '内转' }, { label: '直拨', value: '直拨' }, { label: '外发', value: '外发' }]
    },
    tankShape: {
        list: [{ label: '长方形', value: '长方形' }, { label: '圆形', value: '圆形' }, { label: 'D型', value: 'D型' }, { label: '椭圆形', value: '椭圆形' }]
    },
    oilMonitoring: {
        list: [{ label: '启用', value: '启用' }, { label: '未启用', value: '未启用' }]
    },
    OrderStatus: {
        list: [{ label: '待录单', value: '待录单' }, { label: '已废单', value: '已废单' }, { label: '已录单', value: '已录单' }, {
            label: '已调度',
            value: '已调度'
        }, { label: '运输中', value: '运输中' }, { label: '已交单', value: '已交单' }, { label: '已审核', value: '已审核' }, { label: '已核算', value: '已核算' }, { label: '对账前修改', value: '对账前修改' }, {
            label: '已对账',
            value: '已对账'
        }, { label: '已结算', value: '已结算' },{ label: '对账中', value: '对账中' }]
    },
    OrderStatus_z: {
        list: [{ label: '对账前修改', value: '对账前修改' }, { label: '已废单', value: '已废单' }]
    },
    OrderStatus_n: ['待录单', '已交单', '已审核', '已核算', '对账前修改','对账中', '已对账', '已结算'],
    OrderStatus_w: ['待录单', '已录单', '已调度', '运输中', '已交单', '已审核', '已核算', '对账前修改','对账中', '已对账', '已结算'],
    EquipmentStatus: ['设备运行', '设备临时运行', '设备待机', '网络离线', '异常断电', '设备故障', '初始状态', '无设备'],
    SetDateList: ['自定义时间', '今天', '昨天', '最近两天', '最近七天'],
    // ---------------- AlarmNames枚举  开始 -------------------
    AlarmNames: {
        list: [{
            value: 1,
            label: '全部',
            children: [{
                value: 2,
                label: 'ADAS报警',
                children: [
                    { value: '前向碰撞报警', label: '前向碰撞报警' },
                    { value: '车道偏离报警', label: '车道偏离报警' },
                    // { value: '车距过近报警', label: '车距过近报警' },
                    // { value: '行人碰撞报警', label: '行人碰撞报警' },
                    // { value: '频繁变道报警', label: '频繁变道报警' },
                    // { value: '道路标识超限报警', label: '道路标识超限报警' },
                    // { value: '障碍物报警', label: '障碍物报警' },
                ]
            }, {
                value: 7,
                label: 'DSM报警',
                children: [
                    { value: '疲劳驾驶报警', label: '疲劳驾驶报警' },
                    { value: '接打电话报警', label: '接打电话报警' },
                    { value: '抽烟报警', label: '抽烟报警' },
                    { value: '分神驾驶报警', label: '分神驾驶报警' },
                    { value: '驾驶员异常报警', label: '驾驶员异常报警' },
                    // { value: '驾驶员IC卡异常报警', label: '驾驶员IC卡异常报警' },
                ]
            }, {
                value: 9,
                label: '右侧盲区报警',
                children: [
                    { value: '右侧盲区报警', label: '右侧盲区报警' },
                ]
            }, {
                value: 12,
                label: '普通报警',
                children: [
                    { value: '超速', label: '超速' },
                    { value: '超时停车', label: '超时停车' },
                    { value: '路线偏移', label: '路线偏移' },
                    { value: '急加速', label: '急加速' },
                    { value: '急减速', label: '急减速' },
                ]
            }, {
                value: 15,
                label: '电子规则报警',
                children: [
                    { value: '电子围栏报警', label: '电子围栏报警' },
                    { value: '区域限速报警', label: '区域限速报警' },
                    { value: '电子围栏报警', label: '电子围栏报警' }
                ]
            }]
        }],
        // 查询条件列表
        allList: function () {
            return [{
                label: '全部',
                value: null
            }].concat(this.list)
        },
        // 根据枚举值获取名称
        getName: function (val) {
            switch (val) {
                default: return val
            }
        }
    },
    // ---------------- AlarmNames枚举  结束 -------------------
    // ---------------- 司机驾照类型枚举  开始-------------------
    LicenseType: {
        // 枚举列表
        list: [{
            label: 'A1',
            value: 'A1'
        },
        {
            label: 'A2',
            value: 'A2'
        },
        {
            label: 'A3',
            value: 'A3'
        },
        {
            label: 'B1',
            value: 'B1'
        },
        {
            label: 'B2',
            value: 'B2'
        },
        {
            label: 'C1',
            value: 'C1'
        },
        {
            label: 'C2',
            value: 'C2'
        },
        {
            label: 'C3',
            value: 'C3'
        },
        {
            label: 'C4',
            value: 'C4'
        },
        ],
        // 查询条件列表
        allList: function () {
            return [{
                label: '全部',
                value: null
            }].concat(this.list)
        },
        // 根据枚举值获取名称
        getName: function (val) {
            switch (val) {
                // case 0:
                //   return '内部司机'
                // case 1:
                //   return '外请车主'
                default: return val
            }
        }
    },
    // ---------------- 司机驾照类型枚举  结束-------------------

    // ---------------- Status枚举  开始 -------------------
    Status: {
        // 枚举列表
        list: [{
            label: '在线',
            value: 0
        },
        {
            label: '离线',
            value: 1
        },
        {
            label: '未激活',
            value: 2
        }
        ],
        // 查询条件列表
        allList: function () {
            return [{
                label: '全部',
                value: null
            }].concat(this.list)
        },
        // 根据枚举值获取名称
        getName: function (val) {
            switch (val) {
                case 0:
                    return '在线'
                case 1:
                    return '离线'
                case 2:
                    return '未激活'
                default:
                    return val
            }
        }
    },
    // ---------------- Status枚举  结束 -------------------

    // ---------------- userList枚举  开始 -------------------
    userList: {
        // 枚举列表
        list: [{
            label: '正常',
            value: '正常'
        },
        {
            label: '禁用',
            value: '禁用'
        },
            // {
            //   label: '禁用',
            //   value: '禁用'
            // },
            // {
            //   label: '试用',
            //   value: '试用'
            // },
        ],
        // 查询条件列表
        allList: function () {
            return [{
                label: '全部',
                value: null
            }].concat(this.list)
        },
        // 根据枚举值获取名称
        getName: function (val) {
            switch (val) {
                case '正常':
                    return '正常'
                case '禁用':
                    return '禁用'
                // case '禁用':
                //   return '禁用'
                // case '试用':
                //   return '试用'
                default:
                    return val
            }
        }
    },
    // ---------------- userList枚举  结束 -------------------

    // ---------------- userLevel枚举  开始 -------------------
    userLevel: {
        // 枚举列表
        list: [{
            label: 'I级',
            value: 'I级'
        },
        {
            label: 'II级',
            value: 'II级'
        },
        {
            label: 'III级',
            value: 'III级'
        },
        ],
        // 查询条件列表
        allList: function () {
            return [{
                label: '全部',
                value: null
            }].concat(this.list)
        },
        // 根据枚举值获取名称
        getName: function (val) {
            switch (val) {
                case 'I级':
                    return 'I级'
                case 'II级':
                    return 'II级'
                case 'III级':
                    return 'III级'
                default:
                    return val
            }
        }
    },
    // ---------------- userLevel枚举  结束 -------------------

    // ---------------- OrderNewType枚举  开始 -------------------
    OrderNewType: {
        // 枚举列表
        list: [{
            label: '内转',
            value: '内转'
        },
        {
            label: '直拨',
            value: '直拨'
        },
        {
            label: '外发',
            value: '外发'
        }
        ],
        // 查询条件列表
        allList: function () {
            return [{
                label: '全部',
                value: null
            }].concat(this.list)
        },
        // 根据枚举值获取名称
        getName: function (val) {
            switch (val) {
                case '内转':
                    return '内转'
                case '直拨':
                    return '直拨'
                case '外发':
                    return '外发'
                default:
                    return val
            }
        }
    },
    // ---------------- Status枚举  结束 -------------------
    // ---------------- CarType枚举  开始 -------------------
    CarType: {
        // 枚举列表
        list: [{
            label: '自有-智连',
            value: '自有-智连'
        },
        {
            label: '租赁',
            value: '租赁'
        },
        {
            label: '挂靠',
            value: '挂靠'
        },
        {
            label: '外请',
            value: '外请'
        },
        {
            label: '自有-三正',
            value: '自有-三正'
        }
        ],
        // 查询条件列表
        allList: function () {
            return [{
                label: '全部',
                value: null
            }].concat(this.list)
        },
        // 根据枚举值获取名称
        getName: function (val) {
            switch (val) {
                case '自有-智连':
                    return '自有-智连'
                case '租赁':
                    return '租赁'
                case '挂靠':
                    return '挂靠'
                case '外请':
                    return '外请'
                case '自有-三正':
                    return '自有-三正'
                default:
                    return val
            }
        }
    },
    carType: {
        // 枚举列表
        list: [{
            label: '自有-智连',
            value: '0'
        },
        {
            label: '租赁',
            value: '1'
        },
        {
            label: '挂靠',
            value: '2'
        },
        {
            label: '外请',
            value: '3'
        },
        {
            label: '自有-三正',
            value: '4'
        }
        ],
        // 查询条件列表
        allList: function () {
            return [{
                label: '全部',
                value: null
            }].concat(this.list)
        },
        // 根据枚举值获取名称
        getName: function (val) {
            switch (val) {
                case '自有-智连':
                    return '自有-智连'
                case '租赁':
                    return '租赁'
                case '挂靠':
                    return '挂靠'
                case '外请':
                    return '外请'
                case '自有-三正':
                    return '自有-三正'
                default:
                    return val
            }
        }
    },
    // ---------------- CarType枚举  结束 -------------------

    // ---------------- SettlementType枚举  开始 -------------------
    SettlementType: {
        // 枚举列表
        list: [{
            label: '未结清',
            value: '未结清'
        },
        {
            label: '已结清',
            value: '已结清'
        }
        ],
        // 查询条件列表
        allList: function () {
            return [{
                label: '全部',
                value: null
            }].concat(this.list)
        },
        // 根据枚举值获取名称
        getName: function (val) {
            switch (val) {
                case '未结清':
                    return '未结清'
                case '已结清':
                    return '已结清'
                default:
                    return val
            }
        }
    },
    // ---------------- Status枚举  结束 -------------------

    // ---------------- Operator枚举  开始 -------------------
    Operator: {
        // 枚举列表
        list: [{
            label: '视频终端',
            value: '视频终端'
        },
        {
            label: '定位终端',
            value: '定位终端'
        },
        {
            label: '其他',
            value: '其他'
        }
        ],
        // 查询条件列表
        allList: function () {
            return [{
                label: '全部',
                value: null
            }].concat(this.list)
        },
        // 根据枚举值获取名称
        getName: function (val) {
            switch (val) {
                default: return val
            }
        }
    },
    // ---------------- Operator枚举  结束 -------------------
    DeviceType: {
        // 枚举列表
        list: [{
            label: '网络摄像头',
            value: '网络摄像头'
        },
        {
            label: '模拟摄像头',
            value: '模拟摄像头'
        },
        {
            label: '传感器设备',
            value: '传感器设备'
        }
        ],
        // 查询条件列表
        allList: function () {
            return [{
                label: '全部',
                value: null
            }].concat(this.list)
        },
        // 根据枚举值获取名称
        getName: function (val) {
            switch (val) {
                default: return val
            }
        }
    },

    UseType: {
        // 枚举列表
        list: [{
            label: '使用',
            value: '使用'
        },
        {
            label: '停用',
            value: '停用'
        }
        ],
        // 查询条件列表
        allList: function () {
            return [{
                label: '全部',
                value: null
            }].concat(this.list)
        },
        // 根据枚举值获取名称
        getName: function (val) {
            switch (val) {
                default: return val
            }
        }
    },

    AudioEncode: {
        list: [{
            label: 'G.711A',
            value: 'G.711A'
        }, {
            label: 'G.711U',
            value: 'G.711U'
        }, {
            label: 'G.721',
            value: 'G.721'
        }, {
            label: 'AAC',
            value: 'AAC'
        }, {
            label: 'MP3',
            value: 'MP3'
        }, {
            label: 'AMR',
            value: 'AMR'
        }, {
            label: '其他',
            value: '其他'
        },],
        allList: function () {
            return [{
                label: '全部',
                value: null
            }].concat(this.list)
        }, // 根据枚举值获取名称
        getName: function (val) {
            switch (val) {
                default: return val
            }
        }
    },

    ServiceProvider: {
        // 枚举列表
        list: [
            {
            label: '中国移动',
            value: '0'
            },
            {
                label: '中国联通',
                value: '1'
            },
            {
                label: '中国电信',
                value: '2'
            }
        ],
        listDes: [
            {
            label: '中国移动',
            value: '中国移动'
            },
            {
                label: '中国联通',
                value: '中国联通'
            },
            {
                label: '中国电信',
                value: '中国电信'
            }
        ],
        // 查询条件列表
        allList: function () {
            return [{
                label: '全部',
                value: null
            }].concat(this.list)
        },
        // 根据枚举值获取名称
        getName: function (val) {
            switch (val) {
                default: return val
            }
        }
    },

    PlateColor: {
        // 枚举列表
        list: [{
            label: '蓝牌',
            value: '蓝牌'
        }, {
            label: '黄牌',
            value: '黄牌'
        }, {
            label: '黑牌',
            value: '黑牌'
        },
        {
            label: '白牌',
            value: '白牌'
        },

        {
            label: '绿牌',
            value: '绿牌'
        },
        {
            label: '黄绿牌',
            value: '黄绿牌'
        },
        {
            label: '农黄牌',
            value: '农黄牌'
        },
        {
            label: '农绿牌',
            value: '农绿牌'
        }, {
            label: '渐变绿牌',
            value: '渐变绿牌'
        },
        {
            label: '其他',
            value: '其他'
        },
        ],
        // 查询条件列表
        allList: function () {
            return [{
                label: '全部',
                value: null
            }].concat(this.list)
        },
        // 根据枚举值获取名称
        getName: function (val) {
            switch (val) {
                default: return val
            }
        }
    },

    CarAttribution: {
        // 枚举列表
        list: [{
            label: '自有-智连',
            value: '自有-智连'
        },
        {
            label: '租赁',
            value: '租赁'
        },
        {
            label: '挂靠',
            value: '挂靠'
        },
        {
            label: '外请',
            value: '外请'
        },
        {
            label: '自有-三正',
            value: '自有-三正'
        }
        ],
        // 查询条件列表
        allList: function () {
            return [{
                label: '全部',
                value: null
            }].concat(this.list)
        },
        // 根据枚举值获取名称
        getName: function (val) {
            switch (val) {
                default: return val
            }
        }
    },

    AddCarStatus: {
        // 枚举列表
        list: [{
            label: '正常',
            value: '正常'
        },
        {
            label: '维修',
            value: '维修'
        },
        {
            label: '停用',
            value: '停用'
        },
        {
            label: '欠费',
            value: '欠费'
        },
        ],
        // 查询条件列表
        allList: function () {
            return [{
                label: '全部',
                value: null
            }].concat(this.list)
        },
        // 根据枚举值获取名称
        getName: function (val) {
            switch (val) {
                default: return val
            }
        }
    },

    UseNature: {
        // 枚举列表
        list: [{
            label: '家庭自用汽车',
            value: '家庭自用汽车'
        },
        {
            label: '非营运客车',
            value: '非营运客车'
        },
        {
            label: '营运客车',
            value: '营运客车'
        },
        {
            label: '非营运货车',
            value: '非营运货车'
        },
        {
            label: '营运货车',
            value: '营运货车'
        },
        {
            label: '特种车辆',
            value: '特种车辆'
        },
        {
            label: '其他',
            value: '其他'
        },
        ],
        // 查询条件列表
        allList: function () {
            return [{
                label: '全部',
                value: null
            }].concat(this.list)
        },
        // 根据枚举值获取名称
        getName: function (val) {
            switch (val) {
                default: return val
            }
        }
    },

    // ---------------- alarmType 枚举  开始 -------------------
    alarmType: {
        list: [{
            label: '低风险',
            value: '低风险'
        }, {
            label: '中风险',
            value: '中风险'
        }, {
            label: '高风险',
            value: '高风险'
        }],
        allList: function () {
            return [{
                label: '全部',
                value: null
            }].concat(this.list)
        },
        // 根据枚举值获取名称
        getName: function (val) {
            switch (val) {
                case '低风险':
                    return '低风险'
                case '中风险':
                    return '中风险'
                case '高风险':
                    return '高风险'
                default:
                    return val
            }
        }
    },
    // ---------------- alarmType  结束 -------------------
    // ---------------- alarmState 枚举  开始 -------------------
    alarmState: {
        list: [{
            label: '未处理',
            value: '未处理'
        }, {
            label: '人工处理',
            value: '人工处理'
        }, {
            label: '自动处理',
            value: '自动处理'
        }],
        allList: function () {
            return [{
                label: '全部',
                value: null
            }].concat(this.list)
        },
        // 根据枚举值获取名称
        getName: function (val) {
            switch (val) {
                case '未处理':
                    return '未处理'
                case '人工处理':
                    return '人工处理'
                case '自动处理':
                    return '自动处理'
                default:
                    return val
            }
        }
    },
    // ---------------- alarmType  结束 -------------------

    // ---------------- 视频视角枚举开始 -------------------
    VideoPerspective: {
        // 枚举列表
        list: [{
            label: 'CH1 通道',
            value: 1
        },
        {
            label: 'CH2 通道',
            value: 2
        },
        {
            label: 'CH3 通道',
            value: 3
        },
        {
            label: 'CH4 通道',
            value: 4
        },
        {
            label: 'CH5 通道',
            value: 5
        },
        {
            label: 'CH6 通道',
            value: 6
        },
        ],
        listString: [{
            label: 'CH1 通道',
            value: "1"
        },
        {
            label: 'CH2 通道',
            value: "2"
        },
        {
            label: 'CH3 通道',
            value: "3"
        },
        {
            label: 'CH4 通道',
            value: "4"
        },
        {
            label: 'CH5 通道',
            value: "5"
        },
        {
            label: 'CH6 通道',
            value: "6"
        },
        ],
        // 查询条件列表
        allList: function () {
            return [{
                label: '全部',
                value: null
            }].concat(this.list)
        },
        // 根据枚举值获取名称
        getName: function (val) {
            switch (val) {
                case 1:
                    return 'CH1 通道'
                case 2:
                    return 'CH2 通道'
                case 3:
                    return 'CH3 通道'
                case 4:
                    return 'CH4 通道'
                case 5:
                    return 'CH5 通道'
                case 6:
                    return 'CH6 通道'
                default:
                    return val
            }
        }
    },
    // ---------------- 视频视角枚举结束-------------------

    // ---------------- ProtocolType枚举  开始 -------------------
    ProtocolType: {
        // 枚举列表
        list: [{
            label: '部标808',
            value: '部标808'
        },
        {
            label: '部标1078',
            value: '部标1078'
        },
        {
            label: '苏标',
            value: '苏标'
        },
        {
            label: '艾依灵自定义协议',
            value: '艾依灵自定义协议'
        },
        {
            label: '其他',
            value: '其他'
        },
        ],
        // 查询条件列表
        allList: function () {
            return [{
                label: '全部',
                value: null
            }].concat(this.list)
        },
        // 根据枚举值获取名称
        getName: function (val) {
            switch (val) {
                default: return val
            }
        }
    },

    // ---------------- deviceCompanyType  开始 -------------------
    deviceCompanyType: {
        // 枚举列表
        list: [{
            label: '艾依灵科技',
            value: '艾依灵科技'
        },
        {
            label: '中天安驰',
            value: '中天安驰'
        },
        {
            label: 'G7',
            value: 'G7'
        },
        {
            label: '径卫视觉',
            value: '径卫视觉'
        },
        {
            label: '博实科技',
            value: '博实科技'
        },
        {
            label: '通天星',
            value: '通天星'
        },
        {
            label: '瑞为科技',
            value: '瑞为科技'
        },
        {
            label: '福建星冠',
            value: '福建星冠'
        },
        {
            label: '其他',
            value: '其他'
        },

        ],
        // 查询条件列表
        allList: function () {
            return [{
                label: '全部',
                value: null
            }].concat(this.list)
        },
        // 根据枚举值获取名称
        getName: function (val) {
            switch (val) {
                default: return val
            }
        }
    },

    // ---------------- equipmentFunction  开始 -------------------
    equipmentFunction: {
        // 枚举列表
        list: [{
            label: '高级驾驶辅助系统（ADAS）',
            value: '高级驾驶辅助系统（ADAS）'
        },
        {
            label: '驾驶员状态监测（DSM）',
            value: '驾驶员状态监测（DSM）'
        },
        {
            label: '盲点监测（BSD）',
            value: '盲点监测（BSD）'
        },
        {
            label: '360环视',
            value: '360环视'
        },
        {
            label: '油量监控',
            value: '油量监控'
        },
        ],
        // 查询条件列表
        allList: function () {
            return [{
                label: '全部',
                value: null
            }].concat(this.list)
        },
        // 根据枚举值获取名称
        getName: function (val) {
            switch (val) {
                default: return val
            }
        }
    },

    // ---------------- equipmentFunction  开始 -------------------
    cameraType: {
        // 枚举列表
        list: [{
            label: 'CH1:ADAS摄像头',
            value: 'ADAS摄像头',
            physicalPipe: 1
        },
        {
            label: 'CH2:DSM摄像头',
            value: 'DSM摄像头',
            physicalPipe: 2
        },
        {
            label: 'CH3:BSD摄像头',
            value: 'BSD摄像头',
            physicalPipe: 3
        },
        {
            label: 'CH4:车内摄像头',
            value: '车内摄像头',
            physicalPipe: 4
        },
        {
            label: 'CH5:360前置摄像头',
            value: '360前置摄像头',
            physicalPipe: 5
        },
        {
            label: 'CH6:360后置摄像头',
            value: '360后置摄像头',
            physicalPipe: 6
        },
        {
            label: 'CH7:360左置摄像头',
            value: '360左置摄像头',
            physicalPipe: 7
        },
        {
            label: 'CH8:360右置摄像头',
            value: '360右置摄像头',
            physicalPipe: 8
        },

        ],
        // 查询条件列表
        allList: function () {
            return [{
                label: '全部',
                value: null
            }].concat(this.list)
        },
        // 根据枚举值获取名称
        getName: function (val) {
            switch (val) {
                default: return val
            }
        }
    },
    //车辆归属 0-自有、1-租赁、2-挂靠、3-外请
    carOwnership: {
        list: [{
            value: 0,
            label: '自有-智连',
        },{
            value: 1,
            label: '租赁',
        },{
            value: 2,
            label: '挂靠',
        },{
            value: 3,
            label: '外请',
        },{
            value: 4,
            label: '自有-三正',
        }]
    },

    //账单状态
    accountType: {
        list: [{
            value: 0,
            label: '对账中',
        },{
            value: 1,
            label: '对账已完成',
        }]
    }
}