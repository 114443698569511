import Layout from '@views/Layout.vue'

export default {
    path: '/examine',
    component: Layout,
    children: [{
        path: '/examine/to-examine',
        name: 'Examine',
        component: () =>
            import ('@views/to-examine/to-examine'),
        meta: {
            keepAlive: true,
            isBack: false
        }
    }, {
        path: '/examine/examine-details',
        name: 'ExamineDetails',
        component: () =>
            import ('@views/to-examine/examine-details'),
    }]
}