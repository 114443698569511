import Layout from '@views/Layout.vue'

export default {
    path: '/order',
    component: Layout,
    children: [{
        path: '/order/order-accounting',
        name: 'OrderAccounting',
        component: () =>
            import ('@views/order-accounting/order-accounting'),
        meta: {
            keepAlive: true,
            isBack: false
        }
    }, {
        path: '/order/bus-accounting',
        name: 'orderAccounting',
        component: () =>
            import ('@views/order-accounting/bus-accounting'),
    }]
}