<template>
  <div class="header">
    <!-- <template v-if="additional.showTmsAppletQRCode">
      <el-popover placement="bottom" title="" trigger="hover">
        <img :src="require('../../assets/images/ocr-code-0.png')"  style="height: 300px;width: 300px;"/>
        <img slot="reference" :src="require('../../assets/images/ocr-code-0.png')" :alt="require('../../assets/images/ocr-code-0.png')" style="height: 40px;width: 40px">
      </el-popover>
      <span style="padding-left: 10px;padding-right: 10px;">货主小程序</span>
    </template> -->
      <template v-if="additional.showTmsAppQRCode">
        <el-popover placement="bottom" trigger="hover">
          <vue-qr :logoSrc="imageUrl" :text="QRcode" :size="300"></vue-qr>
          <img slot="reference" :src="require('../../assets/images/ocr-code-1.png')" :alt="require('../../assets/images/ocr-code-1.png')" style="height: 40px;width: 40px">
        </el-popover>
        <span style="padding-left: 10px;">司机APP</span>
    </template>
    <span style="padding-left: 20px;">{{user.nickName}}</span>
    <img src="@img/sign-out.png" alt="退出" class="curp exit" @click="logout">
  </div>
</template>
<script>
  import vueQr from 'vue-qr'
  export default {
    components: {
        vueQr
    },
    data () {
      return {
        additional: JSON.parse(sessionStorage.getItem('additional')),
        user: JSON.parse(sessionStorage.getItem('userInfos')),
        imageUrl: require('../../assets/images/ocr-code-1.png'),
        QRcode: ""
      }
    },
    methods: {
      async logout () {
        try {
          await this.$api.Logout({
            userIdentifier: sessionStorage.getItem('userIdentifier'),
            // userType: 1,
            mac: sessionStorage.getItem('mac')
          })
          sessionStorage.clear()
          // this.$Cookies.remove('menus')
          // localStorage.clear()
          // this.$destroy()
          // location.reload()
          // this.$forceUpdate()
          // this.$router.go(0)
          window.location.reload()//解决：清掉视图级别的缓存
          // this.$router.push('/').then()          
          this.$notify.success({
            title: '成功',
            message: '已成功退出！'
          })
          this.$router.replace({
            name: 'Login'
          });
        } catch (e) {}
      },
      getEWM(){
        let params = {
          version: "",
          prefix: "ailiving-logistics-",
          rollbackVersion:"",
          systemType:"Android"
        }
        this.$api.app_apkUpgradeService(params).then(res=>{
          // console.log(res.url)
          this.QRcode = res.url
        })  
      }
    },
    mounted () {

    },
    created () {
      this.getEWM()
    },
    computed: {},
    watch: {},
  }
</script>
<style lang="sass" scoped>
  .header
    width: 100%
    height: $sidebarHeaderHeight
    background: $-background-color
    display: flex
    align-items: center
    justify-content: flex-end
    color: $-color-white

  .exit
    width: 18px
    height: 20px
    margin: 0 24px 0 14px
</style>


