import Layout from '@views/Layout.vue'

export default {
    path: '/search-order',
    component: Layout,
    children: [{
            name: 'searchOrder',
            path: '/search-order/search-order',
            component: () =>
                import ('@views/search-order/search-order'),
            meta: {
                keepAlive: true,
                isBack: false
            }
        },
        {
            name: 'orderDetails',
            path: '/search-order/order-details',
            component: () =>
                import ('@views/search-order/order-details')
        }
    ]
}