import Layout from '@views/Layout.vue'

export default {
    path: '/operation-regulation',
    component: Layout,
    children: [{ //实时监管
        name: 'Real-time-regulation',
        path: '/operation-regulation/real-time-regulation/real-time-regulation',
        component: () =>
            import ('@views/operation-regulation/real-time-regulation/real-time-regulation'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '实时监管',
            }
        },{ //人员列表
            name: 'Personnel-list',
            path: '/operation-regulation/real-time-regulation/personnel-list',
            component: () =>
                import ('@views/operation-regulation/real-time-regulation/personnel-list'),
                meta: {
                    keepAlive: true,
                    isBack: false,
                    title: '人员列表',
                }
        },{ //历史回放
            name: 'Regulation-historical-playback',
            path: '/operation-regulation/historical-playback/historical-playback',
            component: () =>
                import ('@views/operation-regulation/historical-playback/historical-playback'),
                meta: {
                    title: '历史回放',
                }
        },{ //实时视频
            name: 'Real-play-back',
            path: '/operation-regulation/real-time-regulation/play-back',
            component: () =>
                import ('@views/operation-regulation/real-time-regulation/play-back'),
                meta: {
                    title: '实时视频',
                }
         },]
   }