import Vue from 'vue';

const bus = new Vue();
const eventsConfig = {
    /**
     * 生成一个唯一的事件名
     * @param {string} action 
     * @param {string} componentName 
     * @returns {string}
     */
    makeEvent(action,componentName){
        return action+"."+componentName;
    },
    CLOSE_NAV:"close.nav",
    REFRESH_MUNICIPAL_ROAD_MANAGEMENT:"refresh.municipal-road-management",
    REFRESH_PLOT_MANAGEMENT:"refresh.plot-management",
    REFRESH_GREEN_GARBAGE_HOUSE:"refresh.green-garbage-house",
    REFRESH_VEHICLE_OPERATION_MANAGEMENT:"refresh.vehicle-operation-management",
    REFRESH_EDIT_WAGE:"refresh.edit-wage",
    REFRESH_DISTRICT_INOF:"refresh.district-info",
    REFRESH_URBAN_VILLAGES_MANAGEMENT:"REFRESH_URBAN_VILLAGES_MANAGEMENT",
    REFRESH_PUBLIC_TOILETS_MANAGEMENT:"refresh.public-toilets-management",
    REFRESH_DISTRICT_CONTRACT:"refresh.district-contract",
    REFRESH_FLEET_MANAGEMENT:"refresh.fleet-management",
    REFRESH_EQUIPMENT_MANAGEMENT:"refresh.equipment-management",
    REFRESH_PERSONNEL_INFO:"refresh.personnel-info",
    REFRESH_OPERATIONAL_EARLY_WARNING_MANAGEMENT:"refresh.operational-early-warning-management",
    REFRESH_OPERATING_RULES:"refresh.operating-rules",
    REFRESH_SAFETT_TRAINING_RECORD:"refresh.safety-training-record",
    REFRESH_SAFETY_CHECK:"refresh.safety-check",
    REFRESH_EMERGENCY_MANAGEMENT1:"refresh.emergency-management1",
    REFRESH_EMERGENCY_MANAGEMENT2:"refresh.emergency-management2",
    REFRESH_EMERGENCY_MANAGEMENT3:"refresh.emergency-management3",
    REFRESH_EMERGENCY_MANAGEMENT4:"refresh.emergency-management4",
    REFRESH_SAFETY_INCIDENT_RECORD:"refresh.safety-incident-record",
    REFRESH_VEHICLE_MANAGEMENT:"refresh.vehicle-management",
    REFRESH_SIM_MANAGEMENT:"refresh.sim-management",
    REFRESH_SAFETY_SUPPLIES_MANAGEMENT:"refresh.safety-supplies-management",
    REFRESH_SCHEDULING_LIST:"REFRESH_SCHEDULING_LIST",

    
}

export {bus as BUS};
export {eventsConfig as BUSEVENT};