import Layout from '@views/Layout.vue'
export default {
    path: '/cashier-settlement',
    component: Layout,
    children: [
        //往来单位结算
        {
            path: '/cashier-settlement/contacts-unit',
            name: 'Contacts-unit',
            component: () =>
                import ('@views/cashier-settlement/contacts-unit/contacts-unit'),
            meta: {
                keepAlive: true,
                isBack: false
            }
        },
        //往来单位结算明细
        {
            path: '/cashier-settlement/contacts-unit/billing-details',
            name: 'Billing-details',
            component: () =>
                import ('@views/cashier-settlement/contacts-unit/billing-details')
        },
        //外请车主结算
        {
            path: '/cashier-settlement/owner-outside',
            name: 'Owner-outside',
            component: () =>
                import ('@views/cashier-settlement/owner-outside/owner-outside'),
            meta: {
                keepAlive: true,
                isBack: false
            }
        },
        //外请车主结算明细
        {
            path: '/cashier-settlement/owner-outside/owner-outside-details',
            name: 'Owner-outside-details',
            component: () =>
                import ('@views/cashier-settlement/owner-outside/owner-outside-details')
        },
        //信息费报表
        {
            path: '/cashier-settlement/information-expense/information-expense',
            name: 'Information-expense',
            component: () =>
                import ('@views/cashier-settlement/information-expense/information-expense'),
            meta: {
                keepAlive: true,
            }
        },
        //出纳收付报表
        {
            path: '/cashier-settlement/cashier-payment/cashier-payment',
            name: 'Cashier-payment',
            component: () =>
                import ('@views/cashier-settlement/cashier-payment/cashier-payment'),
                meta: {
                    keepAlive: true,
                    isBack: false
                }
        },

        //出纳收付报表 - 实付交易数明细
        {
            path: '/cashier-settlement/cashier-payment/cashier-payment-details',
            name: 'Cashier-payment-details',
            component: () =>
                import ('@views/cashier-settlement/cashier-payment/cashier-payment-details'),
        },
    ]
}
