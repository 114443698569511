import Layout from '@views/Layout.vue'

export default {
  path: '/sanitation-system-management',
  component: Layout,
  children: [
    {     //系统管理-权限分配
      name: 'Role-manage',
      path: '/sanitation-system-management/role-manage/role-manage',
      component: () => import('@views/sanitation-system-management/role-manage/role-manage'),
      meta: {
        keepAlive: true,
        isBack: false,
        title: '权限分配',
      }
    },
    { //系统管理-权限分配-编辑
      name: 'Role-manage-edit',
      path: '/sanitation-system-management/role-manage/role-manage-edit',
      component: () => import('@views/sanitation-system-management/role-manage/role-manage-edit'),
      meta: {
        title: '编辑角色',
      }
    },
    {
      name: 'Role-manage-details',
      path: '/sanitation-system-management/role-manage/role-manage-details',
      component: () => import('@views/sanitation-system-management/role-manage/role-manage-details'),
      meta: {
        title: '角色详情',
      }
    },
    {
      name: 'Add-role',
      path: '/sanitation-system-management/role-manage/add-role',
      component: () => import('@views/sanitation-system-management/role-manage/add-role'),
      meta: {
        title: '新增角色',
      }
    },
    {
      name: 'User-manage',
      path: '/sanitation-system-management/user-manage/user-manages',
      component: () => import('@views/sanitation-system-management/user-manage/user-manages'),
      meta: {
        keepAlive: true,
        isBack: false,
        title: '用户管理',
      }
    },
    {
      name: 'Add-user',
      path: '/sanitation-system-management/user-manage/add-user',
      component: () => import('@views/sanitation-system-management/user-manage/add-user'),
      meta: {
        title: '新增用户',
      }
    },
    {
      name: 'Update-user',
      path: '/sanitation-system-management/user-manage/update-user',
      component: () => import('@views/sanitation-system-management/user-manage/update-user'),
      meta: {
        title: '编辑用户',
      }
    },
    {
      name: 'User-details',
      path: '/sanitation-system-management/user-manage/user-details',
      component: () => import('@views/sanitation-system-management/user-manage/user-details'),
      meta: {
        title: '用户详情',
      }
    },
    {
      name: 'companyManagement',
      path: '/system-manage/company-management',
      component: () => import('@views/system-manage/company-management'),
      meta: {
        keepAlive: true,
        isBack: false,
        title: '企业管理',
      }
    },
    {
      name: 'transportAddressList',
      path: '/system-manage/transport-address/list',
      component: () => import('@views/system-manage/transport-address/list'),
      meta: {
        keepAlive: true,
        isBack: false,
        title: '托运地址',
      }
    },
    {
      name: 'transportAddressPublic',
      path: '/system-manage/transport-address/public',
      component: () => import('@views/system-manage/transport-address/public'),
      meta: {
        title: '地址明细',//公共
      },
    },
    {
      name: 'transportAddressPerson',
      path: '/system-manage/transport-address/person',
      component: () => import('@views/system-manage/transport-address/person'),
      meta: {
        title: '地址明细',//个人
      },
    }
  ]
}
