<template>
  <div class="chart-line">
    <div class="line" ref="line"></div>
  </div>
</template>

<script>
  import echarts from 'echarts'
  const seriesItem = {
    name: null,
    type: 'line',
    emptyCircle: 'circle',
    data: [],
    lineStyle: {
      normal: {
          width: 4,
          color: null,
          shadowColor: null,
          shadowBlur: 5,
          shadowOffsetY: 10
        }
    },
    itemStyle: {
      normal: {
        color: null,
        borderColor: '#ffffff',
        borderWidth: 3
      }
    },
    symbol: 'circle',
    symbolSize: 8,
    showSymbol: false,
    smooth: true,
    smoothMonotone: 'none'
  }
  const option = {
    tooltip: {
      trigger: 'axis',
      backgroundColor: '#ffffff',
      textStyle: {
        color: '#666666'
      },
      extraCssText: 'box-shadow:0px 2px 6px 0px rgba(0, 0, 0, 0.14);width:156px;box-sizing: border-box;padding-left:14px',
      axisPointer: {
        lineStyle: {
          color: '#D9D9D9'
        }
      }
    },
    legend: {
      color: [],
      data: [],
      left: 'center',
      bottom: 'bottom'
    },
    grid: {
      top: '8%',
      left: '2%',
      right: '2%',
      bottom: '3%',
      height: '85%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: [],
      axisLine: {
        lineStyle: {
          color: "#999"
        },
      },
      axisTick: {
        alignWithLabel: true
      }
    },
    yAxis: {
      type: 'value',
      splitLine: {
        lineStyle: {
          type: 'dashed',
          color: '#DDD'
        }
      },
      axisLine: {
        show: false,
        lineStyle: {
          color: "#333"
        },
      },
      nameTextStyle: {
        color: "#999"
      },
      splitArea: {
        show: false
      }
    },
    series: []
  };
  export default {
    props: {
      lineData: {
        default: function () {
          return []
        },
        type: Array
      },
      tooltip: {
        default: function() {
          return {}
        },
      }
    },
    data () {
      return {}
    },
    watch: {
      "lineData": function(){
        this.drawLine()
      }
    },
    methods: {
      drawLine(){
        let lineOption = option
        lineOption.series = []
        lineOption.legend.color = []
        lineOption.legend.data = []
        this.lineData.forEach((e, i)=>{
          let seriesItems = this.$_.cloneDeep(seriesItem)
          lineOption.series.push(seriesItems)
          lineOption.series[i].name = e.name
          lineOption.series[i].lineStyle.normal.color = 'rgba('+ e.color +'1)'
          lineOption.series[i].lineStyle.normal.shadowColor = 'rgba('+ e.color +'0.4)'
          lineOption.series[i].itemStyle.normal.color = 'rgba('+ e.color +'1)'
          lineOption.series[i].data = e.data
          lineOption.legend.color.push('rgba('+ e.color +'1)')
          lineOption.legend.data.push(e.name)
        })
        if(this.lineData[0]){
          lineOption.xAxis.data = this.lineData[0].xData
        }
        let line = echarts.init(this.$refs.line)
        line.setOption(lineOption)
        window.addEventListener('resize', function () {
          line.resize()
        })
      }
    },
    mounted () {
      this.drawLine()
    }
  }
</script>

<style lang='sass' scoped>
.chart-line
  width: 100%
  height: 376px
  .line
    width: 100%
    height: 100%
</style>
