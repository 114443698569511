import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routerPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(error => error)
}

// 引入除开index.js以外的js文件
const files = require.context('.', true, /\.js$/)
let routerArray = []
files.keys().forEach(key => {
    if (key === './index.js') return
    const exportDefault = files(key).default

    // 设置默认第一个
    exportDefault.children.push({
        path: '',
        redirect: exportDefault.children.length ? exportDefault.children[0].path : '/home'
    })
    routerArray = routerArray.concat(exportDefault)
})

const routes = [{
        path: '/',
        name: 'Login',
        component: () =>
            import('@views/login/login'),
        meta: {
            ignoreAuth: true
        }
    },
    {
        path: '/data-b1',
        name: 'DataB1',
        component: () =>
            import('@views/command/index'),
    },
    {
        path: '/big-data-screen',
        name: 'Big-data-screen',
        component: () =>
            import('@views/big-data-screen/index'),
        meta: {
            keepAlive: false,
            title:"指挥大屏"
        }
    },
    {
        path: '*',
        redirect: {
            path: '/home'
        }
    }
].concat(routerArray)

const router = new VueRouter({
    base: process.env.BASE_URL,
    routes
})

// https://router.vuejs.org/zh/guide/advanced/navigation-guards.html
router.beforeEach((to, from, next) => {
    if (!sessionStorage.getItem('loginData') && to.path !== '/') {
        return next('/')
    }
    next()
})

export default router