<template>
  <el-cascader
    v-model="myValue"
    placeholder="请选择"
    :options="bidList"
    :show-all-levels="false"
    filterable
    v-bind="$attrs"
    v-on="$listeners"
  ></el-cascader>
</template>
<script>
/**
 * 单选级联下拉菜单 for 标段
 */
export default {
  name: "ayl-cascader-for-bid",

  inheritAttrs: false,

  props: {
    value: {},
  },

  data() {
    return {
      debug:0,
      myValue: this.value,
      bidList: []
    };
  },

  watch: {
    value: function(v, ov) {
      this.log(v);
      // this.$nextTick(function(){
        this.myValue = v;
      // });
    }
  },

  created(){

  },

  async mounted() {
    this.bidList = await this.$api_hw.common_getBidNameAndIdList({});
  },

  updated() {
      
  },
};
</script>
<style scoped>
</style>