import Layout from '@views/Layout.vue'

export default {
  path: '/outgoing-allot',
  component: Layout,
  children: [{
    name: 'outgoingAllot',
    path: '/outgoing-allot/outgoing-allot',
    component: () => import('@views/outgoing-allot/outgoing-allot'),
    meta: {
      keepAlive: true,
      isBack: false
    }
  },{
    name: 'allot',
    path: '/outgoing-allot/allot',
    component: () => import('@views/outgoing-allot/allot')
  }]
}
