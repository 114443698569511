<template>
  <div class="ayl-table">
    <ayl-search-box-with-query-object :query="table.query" :search-data='table.searchData' @on-search-change='onSearchChange'>
      <span slot="before">
        <slot name="ctrl-before"></slot>
      </span>
      <span slot="after" style="width: 100%">
        <slot name="ctrl-button"></slot>
      </span>
    </ayl-search-box-with-query-object>
    <el-table :data="table.data" class="ayl-table" v-loading="table.loading" size="medium"
              max-height="auto"
              :height="table.tableHeight"
              element-loading-text='数据加载中...'
              :header-cell-style="getRowClass"
              ref="table"
              @selection-change="handleSelectionChange"
              @sort-change="sortChange">

      <!--多选-->
      <el-table-column
              type="selection"
              :width="table.columns[0].width||'50px'"
              v-if="!isRadioRight && table.columns && table.columns.some(t => t.type === 'selection')"></el-table-column>
      <!--单选-->
      <el-table-column
              :width="table.columns[0].width||'50px' "
              v-if="!isRadioRight && table.columns && table.columns.some(t => t.type === 'radio')"
              :label="table.columns[0].title||' '"
              :align="table.columns[0].align||'center'">
        <template slot-scope="scope">
          <!-- 下面这个必须写一行，否则label会一直显示 -->
          <el-radio v-model="table.columns[0].model" :label="scope.$index" @change="onRadio(scope.row)">{{``}}</el-radio>
        </template>
      </el-table-column>        
      
      <el-table-column
              v-for="(column, idx) in (table.columns && table.columns.filter(t => t.type !== 'selection' && t.type !== 'radio'))"
              :key="idx"
              :type="column.type"
              :label="column.title"
              :prop="column.key"
              :fixed="column.fixed"
              :width="column.width"
              :align="column.align || 'left'"
              :show-overflow-tooltip='column.showTooltip'
              :sortable="column.sortable"
              :render-header="column.renderHeader"
              :formatter="column.formatter">
        <template slot-scope="scope">
          <cell v-if="column.render" :column='column' :row='scope.row' :index='scope.$index'
                :render='column.render'></cell>
          <span v-else>
            <span v-if="column.filter">{{$filters[column.filter](scope.row[column.key])}}</span>
            <span v-if="column.$index">{{scope.$index+1}}</span>
            <span v-if="column.enumType">{{$enums[column.enumType].getName(scope.row[column.key])}}</span>
            <span v-if="!column.filter && !column.enumType && !column.$index">{{scope.row[column.key] || '- -'}}</span>
            <!--<span v-if="!column.filter && !column.enumType && !column.$index">{{scope.row[column.key] || '- -'}}</span>-->
          </span>
          <!--<cell v-else :column='column' :row='scope.row' :index='scope.$index' :render='column.render'></cell>-->
        </template>

        <template v-if="column.children">
          <el-table-column
                v-for="(column1, idx) in (column.children && column.children.filter(t => t.type !== 'selection' && t.type !== 'radio'))"
                :key="idx"
                :type="column1.type"
                :label="column1.title"
                :prop="column1.key"
                :fixed="column1.fixed"
                :width="column1.width"
                :align="column1.align || 'left'"
                :show-overflow-tooltip='column1.showTooltip'
                :sortable="column1.sortable"
                :render-header="column1.renderHeader"
                :formatter="column1.formatter">
            <template slot-scope="scope">
              <cell v-if="column1.render" :column1='column1' :row='scope.row' :index='scope.$index'
                    :render='column1.render'></cell>
              <span v-else>
                <span v-if="column1.filter">{{$filters[column1.filter](scope.row[column1.key])}}</span>
                <span v-if="column1.$index">{{scope.$index+1}}</span>
                <span v-if="column1.enumType">{{$enums[column1.enumType].getName(scope.row[column1.key])}}</span>
                <span v-if="!column1.filter && !column1.enumType && !column1.$index">{{scope.row[column1.key] || '- -'}}</span>
                <!--<span v-if="!column.filter && !column.enumType && !column.$index">{{scope.row[column.key] || '- -'}}</span>-->
              </span>
              <!--<cell v-else :column='column' :row='scope.row' :index='scope.$index' :render='column.render'></cell>-->
            </template>
          </el-table-column>
        </template>
      </el-table-column>

      <!--多选-->
      <el-table-column
              type="selection"
              :width="table.columns[0].width||'50px'"
              v-if="isRadioRight && table.columns && table.columns.some(t => t.type === 'selection')"></el-table-column>
      <!--单选-->
      <el-table-column
              :width="table.columns[0].width||'50px' "
              v-if="isRadioRight && table.columns && table.columns.some(t => t.type === 'radio')"
              :label="table.columns[0].title||' '"
              :align="table.columns[0].align||'center'">
        <template slot-scope="scope">
          <!-- 下面这个必须写一行，否则label会一直显示 -->
          <el-radio v-model="table.columns[0].model" :label="scope.$index" @change="onRadio(scope.row)">{{``}}</el-radio>
        </template>
      </el-table-column>
    </el-table>

    <!--@size-change="handleSizeChange"-->
    <!--:page-size="100"-->
            
    <div v-if="scope === 'TMS'">
      <el-pagination
              background
              v-if="table.pagination"
              @current-change="$search(table)"
              :page-size="10"
              :current-page.sync="table.pagination.currentPage"
              layout="total, prev, pager, next, jumper"
              :total="table.pagination.totalNum">
      </el-pagination>
    </div>
    <div v-if="scope === 'SANITATION'">
      <el-pagination
              class="hw-pagination"
              background
              v-if="table.pagination"
              @current-change="$search(table)"
              :page-size="10"
              :current-page.sync="table.pagination.currentPage"
              layout="total, prev, pager, next, jumper"
              :total="table.pagination.totalNum">
      </el-pagination>
    </div>
  </div>
</template>
<script>
  import cell from './cell'

  export default {
    components: {
      cell
    },
    created () {
      this.$set(this.table, 'loading', false)
      if (!this.table.hidePagination) {
        this.$set(this.table, 'pagination', {
          currentPage: 1,
          totalNum: 0
        })
      }
    },
    props: {
      table: {
        type: Object,
        required: true
      },
      isRadioRight:{
        type:Boolean,
        default:false
      }
    },
    data () {
      return {
        scope: sessionStorage.getItem('scope', ''),
        multipleSelection: []
      }
    },

    watch:{
      "table.query":{
        deep: true,
        handler: function(v, ov) {
          let self = this;
          self.log(v,ov);
          v && (async function(){            
            await self.$search(self.table);
          })();
        }
      }
    },

    methods: {
      onSearchChange (d) {
        this.$search(this.table, d)
        if (!this.table.hidePagination) {
          this.$set(this.table.pagination, 'currentPage', 1)
        }
      },
      getRowClass ({rowIndex}) {
        if (rowIndex === 0) {
          return 'background:#FAFAFA;color:#262626;font-weight:600;'
        } else {
          return ''
        }
      },
      handleSelectionChange (val) {
        this.$emit('selection-change', val)
      },
      onRadio (val) {
        this.$emit('radio-change', val)
      },
      async sortChange ({column, prop, order}) {
        this.$set(this.table.query, 'orderMode', order === 'ascending' ? 'asc' : 'desc')
        this.$set(this.table.query, 'orderRule', prop)
        await this.$search(this.table)
      }
    }
  }
</script>

<style lang='sass' scoped>
  .ayl-table
    /deep/ .el-pagination
      margin-top: 24px
      text-align: right
    /deep/ .hw-pagination.is-background .el-pager li:not(.disabled).active
        background-color: $-color-primary-3
        color: #FFFFFF
</style>
