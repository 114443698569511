export default {
    data() {
        return {
            /**
             * 数组[0]:true(获取)/false(默认不获取) - 必传
             * 数组[1]：第几个搜索框 - 必传
             * 数组[2]：获取下数据 - 必传
             * author: suyonggang
             */
            common_getBidNameAndIdList: [false, 0, null], //获取标段下拉数据
            districtInfo_getDistinctCareUnit: [false, 0, null], // 养护企业信息下拉
            common_listFirstParty: [false, 0, null], // 获取所有甲方单位下拉数据
            common_getDistinctManageUnit: [false, 0, null] //获取主管单位下拉数据
        }
    },
    computed: {
        log() {
            if (!!this.debug || !!this.DEBUG) {
                return window.console.log;
            }else return ()=>{}
        }
    },
    async mounted() {
        // 获取标段下拉数据
        if (this.common_getBidNameAndIdList[0]) {
            let arr = this.common_getBidNameAndIdList
            await this.$api_hw.common_getBidNameAndIdList({}).then(res => {
                if (this.table)
                    this.table.searchData[arr[1]].option = res;
                if (this.personnelTable)
                    this.personnelTable.searchData[arr[1]].option = res;
                if (this.vehiclesTable)
                    this.vehiclesTable.searchData[arr[1]].option = res
                if (this.warningSettingTable)
                    this.warningSettingTable.searchData[arr[1]].option = res
                if (this.emergencyResponseTable)
                    this.emergencyResponseTable.searchData[arr[1]].option = res
                if (this.emergencySuppliesTable)
                    this.emergencySuppliesTable.searchData[arr[1]].option = res
                if (this.emergencyDrillTable)
                    this.emergencyDrillTable.searchData[arr[1]].option = res
            });
        }
        // 养护企业信息下拉
        if (this.districtInfo_getDistinctCareUnit[0]) {
            let arr = this.districtInfo_getDistinctCareUnit
            await this.$api_hw.districtInfo_getDistinctCareUnit({}).then(res => {
                let data = res.map(item => {
                    return {
                        value: item,
                        label: item
                    }
                })

                if (this.table)
                    this.table.searchData[arr[1]].option = data;
                if (this.personnelTable)
                    this.personnelTable.searchData[arr[1]].option = data;
                if (this.emergencyResponseTable)
                    this.emergencyResponseTable.searchData[arr[1]].option = data;
                if (this.emergencySuppliesTable)
                    this.emergencySuppliesTable.searchData[arr[1]].option = data;
                if (this.emergencyDrillTable)
                    this.emergencyDrillTable.searchData[arr[1]].option = data;
            });
        }
        // 获取所有甲方单位
        if (this.common_listFirstParty[0]) {
            await this.$api_hw.common_listFirstParty({}).then(res => {
                this.table.searchData[this.common_listFirstParty[1]].option = res;
            });
        }
        // 获取主管单位
        if (this.common_getDistinctManageUnit[0]) {
            let data = this.common_getDistinctManageUnit
            await this.$api_hw.common_getDistinctManageUnit({}).then(res => {
                let arr = res.map(item => {
                    return {
                        value: item,
                        label: item
                    }
                })
                if (this.table)
                    this.table.searchData[data[1]].option = arr;
                if (this.emergencyResponseTable)
                    this.emergencyResponseTable.searchData[data[1]].option = arr;
                if (this.emergencySuppliesTable)
                    this.emergencySuppliesTable.searchData[data[1]].option = arr;
                if (this.emergencyDrillTable)
                    this.emergencyDrillTable.searchData[data[1]].option = arr;
            });
        }
    }
}