<template>
  <div class="ayl-main mb24px">
    <div class="ayl-main-top p24px" :style=" 'text-align:'+ isCenter+';padding:'+padding" v-if="isTop">
      <span class="title">{{title}}</span>
      <div class="curp" v-if="isCollapse" @click="show=!show">
        <span>{{isText()}}</span>
        <i class="iconfont" :class="{'iconicon-test':show,'iconicon-test1':!show}"></i>
      </div>
    </div>
    <el-collapse-transition>
      <div v-show="show">
        <slot name="main"/>
      </div>
    </el-collapse-transition>
  </div>
</template>

<script>
  export default {
    props: {
      isTop: {
        type: Boolean,
        default: true
      },
      title: {
        type: String,
        default: ''
      },
      isCollapse: {
        type: Boolean,
        default: false
      },
      isCenter: {
        type: String,
        default: 'left'
      },
      padding: {
        type: String,
        default: ''
      },
    },
    data () {
      return {
        text: '展开',
        show: true
      }
    },
    methods:{
      isText(){
        return this.show ? '收起' : '展开'
      }
    }
  }
</script>

<style lang='sass' scoped>
.ayl-main
  background: rgba(255,255,255,1)
  box-shadow: 1px 0 6px 0 rgba(5,117,230,0.1)
  border-radius: 2px
  &-top
    border-bottom: 1px solid rgba(232,232,232,1)
    @include clearfix
    .title
      font-size: $fontX
      color: $black
    div
      float: right
      i
        color: $blue
        margin-left: 10px
        transition: transform .35s
    .icon90
      transform: rotate(7deg) !important
</style>
