import Layout from '@views/Layout.vue'

export default {
    path: '/operation',
    component: Layout,
    children: [{ //往来单位维护
            path: '/operation/operations-management',
            name: 'Operations-management',
            component: () =>
                import ('@views/operation/operations-management/operations-management'),
                meta: {
                    keepAlive: true,
                    isBack: false
                }
        },
        /**
         * 新增往来单位维护
         */
        {
            path: '/operation/operations-management/add-operations-management',
            name: 'Add-operations-management',
            component: () =>
                import ('@views/operation/operations-management/add-operations-management')
        },
        /**
         * 新增往来单位维护
         */
        {
            path: '/operation/operations-management/edit-operations-management',
            name: 'Edit-operations-management',
            component: () =>
                import ('@views/operation/operations-management/edit-operations-management')
        },
        /**
         * 内部驾驶员维护
         */
        {
            path: '/operation/internal-officer',
            name: 'Internal-officer',
            component: () =>
                import ('@views/operation/internal-officer/internal-officer'),
                meta: {
                    keepAlive: true,
                    isBack: false
                }
        },
        /**
         * 新增内部驾驶员维护
         */
        {
            path: '/operation/internal-officer/add-internal-officer',
            name: 'Add-internal-officer',
            component: () =>
                import ('@views/operation/internal-officer/add-internal-officer')
        },
        /**
         * 编辑内部驾驶员维护
         */
        {
            path: '/operation/internal-officer/edit-internal-officer',
            name: 'Edit-internal-officer',
            component: () =>
                import ('@views/operation/internal-officer/edit-internal-officer')
        },
        /**
         * 车辆信息维护
         */
        {
            path: '/operation/vehicle-information',
            name: 'Vehicle-information',
            component: () =>
                import ('@views/operation/vehicle-information/vehicle-information')
        },
        /**
         * 外请车主维护
         */
        {
            path: '/operation/outside-management',
            name: 'Outside-management',
            component: () =>
                import ('@views/operation/outside-management/outside-management'),
                meta: {
                    keepAlive: true,
                    isBack: false
                }
        },
        /**
         * 新增外请车主维护
         */
        {
            path: '/operation/outside-management/add-outside-management',
            name: 'Add-outside-management',
            component: () =>
                import ('@views/operation/outside-management/add-outside-management')
        },
        /**
         * 编辑外请车主维护
         */
        {
            path: '/operation/outside-management/edit-outside-management',
            name: 'Edit-outside-management',
            component: () =>
                import ('@views/operation/outside-management/edit-outside-management')
        },
    ]
}