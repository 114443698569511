<template>
  <div class="content-main-right">
    <div style="height: 66px; display: inline;" v-if="!mapData.isShow">
      <div
        class="el-input el-input--mini el-input--suffix"
        style="padding: 16px 21px 16px 16px; width: 320px; height: 66px;float: left;"
      >
        <input
          id="tipinput"
          type="text"
          autocomplete="off"
          class="el-input__inner"
          placeholder="请输入地址"
          maxlength="40"
          style="height: 32px;"
          v-model="searchText"
        />
      </div>
      <el-button
        style="float: left; margin-top: 16px;"
        type="success"
        class="btn-success-hw"
        @click="onSearch"
      >查询</el-button>
      <el-button
        style="float: left; margin-left: 21px; margin-top: 16px;"
        class="btn-hw"
        @click="onClear"
      >清除所有覆盖物</el-button>
      <div class="tips">提示：点地图设置标注,右键单击标注删除。</div>
      <div v-if="debug">
        mapData:{{mapData}}
        <hr />
        getScopeData:{{getScopeData}}
      </div>
    </div>
    <div id="container" class="container"></div>
    <div id="panel"></div>
    <div id="buttons"></div>
    <slot></slot>
  </div>
</template>

<script>
/**
 * 本地图组件主要信息含：
 * 1个标注点，
 * 1个围栏范围。
 * 两个围栏，
 * 未知围栏数量，
 * 未知标记点数量，带名称、自定义图标
 * 一条线，
 * 未知条数线，
 */

export default {
  name: "ayl-map-multiple-polygon",
  props: {
    //地图数据
    value: {
      type: Object
      // default:{
      //   lnglat: null,      //标注点的经纬度，数组，形如[118,36]
      //   scope: null,       //围栏范围，经纬度点阵数组，形如[[118,36],[117,32],[116,113]]
      //   move2center:true,  //是否居中显示
      // }
    },
    strokeColor: {
      //多边形线颜色
      type: String,
      default: "#ff0000"
    },
    fillColor: {
      //多边形填充色
      type: String,
      default: "#42B983"
    },
    markerTitle: {
      //marker名称
      type: String,
      default: "",
    },
    markerIcon: {
      //marke图标
      type: String,
      default: "https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png",
    },
  },
  data() {
    return {
      debug: 0,
      mapData: this.value,
      searchText: "",
      map: null, //高德插件
      auto: null, //高德插件
      placeSearch: null, //高德插件
      polygon: null, //高德多边形
      polygon1: null, //高德多边形1
      polygonItem: null,   //多个多边形集合
      allLineScopeData: [],   //路线
      getPolygonList: null,   //新绘制单条线路
      drawnObject: null, //新绘的覆盖物
      // markers:[],
      polygonData1: [],
      markerItem: null,      //多个点
      allMarkerData: [],     //新的覆盖点
    };
  },
  methods: {
    initAmap() {
      let self = this;
      // eslint-disable-next-line no-undef
      self.map = new AMap.Map("container", {
        resizeEnable: true,
        zoom: 11
      });
      self.map.plugin(
        [
          "AMap.ToolBar",
          "AMap.Autocomplete",
          "AMap.PlaceSearch"
          // "AMap.PolyEditor",
        ],
        function() {
          self.toolBar = new AMap.ToolBar({
            position: "RT",
            ruler: false,
            locate: true
          });
          self.map.addControl(self.toolBar);

          self.auto = new AMap.Autocomplete({
            input: "tipinput"
          });

          self.placeSearch = new AMap.PlaceSearch({
            pageSize: 5, // 单页显示结果条数
            pageIndex: 1, // 页码
            // city: "010", // 兴趣点城市
            citylimit: true, //是否强制限制在设置的城市内搜索
            map: self.map, // 展现结果的地图实例
            panel: "panel", // 结果列表将在此容器中进行展示。
            autoFitView: true // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
          });

          AMap.event.addListener(self.auto, "complete", function(ev) {
            document.querySelector("#panel").innerHTML = "";
          });
          AMap.event.addListener(self.auto, "select", function(ev) {
            self.placeSearch.search(ev.poi.name); //关键字查询查询
          });
          AMap.event.addListener(self.placeSearch, "selectChanged", function(ev) {
            let lnglat = ev.selected.marker.getPosition();
            self.mapData.lnglat = [lnglat.lng, lnglat.lat];
            // console.log(lnglat.lng,lnglat.lat);
          });
          AMap.event.addListener(self.placeSearch, "markerClick", function(ev) {
            let lnglat = ev.marker.getPosition();
            self.mapData.lnglat = [lnglat.lng, lnglat.lat];
          });
        }
      );

      //鼠标点击添加点标记
      // self.map.off("click").on("click", function(ev) {
      //   // 触发事件的地理坐标，AMap.LngLat 类型
      //   var lnglat = ev.lnglat;
      //   self.log([lnglat.lng, lnglat.lat]);
      //   self.mapData.lnglat = [lnglat.lng, lnglat.lat];
      // });

      self.mapData.move2center = true;
    },

    onSearch() {
      if (this.searchText && this.searchText.trim()) {
        this.placeSearch.search(this.searchText.trim());
      }
    },
    onClear() {
      let self = this;
      self.mapData.lnglat = null;
      self.mapData.scope = null;
      self.mapData.scope1 = null;
      self.map.clearMap();
      document.querySelector("#panel").innerHTML = "";
    },

    polygonClick(e) {
      console.log(e)
      var infoWindow = new AMap.InfoWindow({offset: new AMap.Pixel(0, -30)});
      infoWindow.setContent(e.target.content);
      infoWindow.open(self.map, e.target.getPosition());
    },
  },
  watch: {
    //界面渲染逻辑必须统一放在这里
    mapData: {
      deep: true,
      handler: function(v, ov) {
        let self = this;
        //console.log("---------ayl-map watch mapData change---------", v);
        //绘制标段区域
        //todo

        //根据经纬度绘制点标记
        if (self.mapData) {
          let lnglat = self.mapData.lnglat;
          if (self.marker) {
            self.map.remove(self.marker);
            self.marker = null;
          }
          if (lnglat && lnglat.length >= 2) {
            self.marker = new AMap.Marker({
              position: new AMap.LngLat(lnglat[0], lnglat[1]),
              size: new AMap.Size(18, 18),
              title: self.mapData.markerTitle,
              icon: self.mapData.markerIcon,
              viewMode: '3D',
              pitch: 60,
              anchor: 'top-center'
            });
            self.map.add(self.marker);
            self.marker.setLabel({
                offset: new AMap.Pixel(0, -3),  //设置文本标注偏移量
                content: self.mapData.markerTitle, //设置文本标注内容
                direction: 'top' //设置文本标注方位
            });
            self.map.setFitView([self.marker])
          }
        }

        

        //回显绘制范围（多边形）
        if (self.polygon) {
          self.polygon.setMap(null);
          self.polygon = null;
        }
        if (self.polygon1) {
          self.polygon1.setMap(null);
          self.polygon1 = null;
        }
        if (self.polygonItem) {
          self.polygonItem.setMap(null);
          self.polygonItem = null;
        }
        if (self.drawnObject) {
          self.drawnObject.setMap(null);
          self.drawnObject = null;
        }
        if (self.getPolygonList) {
          self.getPolygonList.setMap(null);
          self.getPolygonList = null;
        }
        if (self.mapData.scope) {
          self.polygon = new AMap.Polygon({
            path: self.getScopeData, //设置多边形边界路径
            strokeColor: self.mapData.strokeColor || self.strokeColor, //线颜色
            strokeWeight: 2, //线宽
            strokeOpacity: 1, //线透明度
            fillOpacity: 0.4, //填充透明度
            fillColor: self.mapData.fillColor || self.fillColor, //填充色
            bubble: true,
            zIndex: 1,
          });
          self.map.add(self.polygon);
        }

        //两个多边形
        if (self.mapData.scope1) {
          self.polygon1 = new AMap.Polygon({
            path: self.getScopeOneData, //设置多边形边界路径
            strokeColor: '#F2090A', //线颜色
            strokeWeight: 2, //线宽
            strokeOpacity: 1, //线透明度
            fillOpacity: 0.4, //填充透明度
            fillColor: 'rgba(242,9,10,0.15)', //填充色
            bubble: true,
            zIndex: 1
          });
          self.map.add(self.polygon1);
          self.map.setFitView([ self.polygon1 ])
        }

        //多个多边形
        if (self.mapData.polygonData) {
          self.polygonData1.map( v => {
            self.map.remove(v)
          })
          var infoWindow = new AMap.InfoWindow({offset: new AMap.Pixel(0, -30)});
          for(let i =0; i < self.getPolygonData.length; i++) {
            self.polygonItem = new AMap.Polygon({
              path: self.getPolygonData[i], //设置多边形边界路径
              strokeColor: '#F2090A', //线颜色
              strokeWeight: 2, //线宽
              strokeOpacity: 1, //线透明度
              fillOpacity: 0.4, //填充透明度
              fillColor: 'rgba(242,9,10,0.15)', //填充色
              bubble: true,
              zIndex: 1,
              //extData: "<div> 123456787654</div>"
            });
            self.map.add(self.polygonItem)       
            //self.polygonItem.content = '我是第' + (i + 1) + 'polygonItem'
            //self.polygonItem.on('click', self.polygonClick);
           // self.polygonItem.emit('click', {target: self.polygonItem});
            self.polygonData1.push(self.polygonItem)
          }
          self.map.setFitView() 
          
        }
         

        //回显多条道路
        if (self.mapData.lineScopeData) {
          // if(self.allLineScopeData.length !== 0) {
          //   self.allLineScopeData.map( v => {
          //     self.map.remove(v)
          //   })
          // }
          self.allLineScopeData.map( v => {
            self.map.remove(v)
          })
          for(let i = 0; i< self.getPolygonScopeData.length; i++) {
            self.drawnObject = new AMap.Polyline({
              path: self.getPolygonScopeData[i], //设置线段边界路径
              strokeColor: '#F2090A', //线颜色
              borderWeight: 2, //线宽
              strokeOpacity: 1, //线透明度
              strokeStyle: "solid",
              lineJoin: "round",
              lineCap: "round",
              bubble: false,
              zIndex: 10
            });
            self.map.add(self.drawnObject);
            self.map.setFitView([ self.drawnObject ]) 
            self.allLineScopeData.push(self.drawnObject)
          }
          
        }

        // 回显一条道路
        if (self.mapData.lineScope) {
          self.getPolygonList = new AMap.Polyline({
            path: self.getPolygonScope, //设置线段边界路径
            strokeColor: '#F2090A', //线颜色
            borderWeight: 2, //线宽
            strokeOpacity: 1, //线透明度
            strokeStyle: "solid",
            lineJoin: "round",
            lineCap: "round",
            bubble: false,
            zIndex: 10
          });
          self.map.add(self.getPolygonList);
          self.map.setFitView([ self.getPolygonList ]) 
        }

        //多个点
        if (self.mapData.markerData) {
          self.allMarkerData.map( v => {
            self.map.remove(v)
          })
          for(let i = 0; i< self.getMarkerData.length; i++){
            self.markerItem = new AMap.Marker({
              position: new AMap.LngLat(self.getMarkerData[i].position[0],self.getMarkerData[i].position[1]),
              size: new AMap.Size(18, 18),
              title: self.getMarkerData[i].title,
              icon: self.getMarkerData[i].icon,
              viewMode: '3D',
              pitch: 60,
              anchor: 'top-center'
            })
            self.map.add(self.markerItem);
            self.allMarkerData.push(self.markerItem)
            self.markerItem.setLabel({
                offset: new AMap.Pixel(0, -3),  //设置文本标注偏移量
                content: self.getMarkerData[i].title, //设置文本标注内容
                direction: 'top' //设置文本标注方位
            });
          }
          if(self.mapData.markerData.length > 0) {
             self.map.setFitView()
          }
         
        }

        // 缩放地图到合适的视野级别
        if (self.mapData.move2center) {
          !self.mapData.scope && self.marker && self.map.setFitView(self.marker);
          self.mapData.scope && self.polygon && self.map.setFitView(self.polygon);
          self.mapData.move2center = false;
        }
      }
    }
  },
  computed: {
    getMapData: {
      get() {
        this.log("---------ayl-map computed---------");
        return this.mapData;
      }
    },
    getMarkerData: {
      get() {
        return this.$_.cloneDeep(this.mapData.markerData)
      }
    },
    getScopeData: {
      get() {
        return this.$_.cloneDeep(this.mapData.scope);
      }
    },
    getScopeOneData: {
      get() {
        return this.$_.cloneDeep(this.mapData.scope1);
      }
    },
    getPolygonData: {
      get() {
        return this.$_.cloneDeep(this.mapData.polygonData);
      }
    },
    getPolygonScopeData: {
      get() {
        return this.$_.cloneDeep(this.mapData.lineScopeData);
      }
    },
     getPolygonScope: {
      get() {
        return this.$_.cloneDeep(this.mapData.lineScope);
      }
    },
  },
  mounted() {
    this.initAmap();
    this.log("---------ayl-map mounted---------");
  },
  activated() {
    this.log("---------ayl-map activated---------");
  }
};
</script>

<style scoped>
#panel {
  width: 40%;
  position: relative;
  top: -710px;
  left: 0px;
}
#buttons {
  position: relative;
  top: -710px;
  float: right;
  padding: 16px;
  margin-right: 60px;
}
.tips{
  float: right;
  width: 280px;
  padding-top: 25px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  color:#999;
}
.container {
    width: 100%;
    height: calc( 100vh - 10px );
}

.info{
  position: relative;
  top: 0;
  right: 0;
  min-width: 0;
}
</style>
