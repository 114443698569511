<template>
  <div>
    <div v-if="scope === 'TMS'" class="bread-nav">
      <span v-for="(item,key) in nav" :key="key">
        <a :class="[!item.path ? 'shallow': 'active']" @click="jump(item)">{{item.name}}</a>
        <!-- 使用&nbsp;替换空格显示 modify by alexChen 2019-6-12 -->
        <span v-show="key < (nav.length - 1) && nav[key + 1].name">&nbsp;/&nbsp;</span>
      </span>
    </div>
    <div v-if="scope === 'SANITATION'" id="tags-view-container" class="tags-view-container">
      <scroll-pane ref="scrollPane" class="tags-view-wrapper">
        <span
          v-for="item in allTags"
          :key="item.key"
          :class="{'active':isActive(item)}"
          class="tags-view-item"
          @click="onGo2tag(item)"
        >
          {{ item.title }}
          <span
            v-if="!isHome(item)"
            class="el-icon-close"
            :id="'ayl_berad_nav_btn_close_'+item.key"
            @click="onCloseTag($event,item);"
          />
        </span>
        <!-- {{allTags}} -->
      </scroll-pane>
    </div>
  </div>
</template>

<script>
import ScrollPane from "./ScrollPane";

const DEFAULT = "ayl__default";

class Tag {
  constructor(key, title, path, toPathAfterCloseTag) {
    /**键，英文 */
    this.key = key;
    /**汉字名称 */
    this.title = title;
    /**完整路径 */
    this.path = path;
    /**关闭该tag之后的跳转路径*/
    this.toPathAfterCloseTag = toPathAfterCloseTag;
  }
}

function makeTag(route) {
  if (!route) return null;
  let tmp = route;
  let k = tmp.name;
  let t = tmp.meta.title;
  let p = tmp.fullPath;
  return new Tag(k ? String(k).toLowerCase() : DEFAULT, t, p);
}

const SSKEY = "AYL_TAG_NAV";
const SSKEY_LAST_PATH = "AYL_TAG_NAV_LAST_PATH";

export default {
  components: { ScrollPane },

  props: {
    nav: {
      //for TMS
      default: function() {
        return [];
      },
      type: Array
    }
  },

  data() {
    return {
      debug: 0,
      scope: sessionStorage.getItem("scope", ""),
      tagsMap: null,
      tagsMapChangeTracker: 1, //https://stackoverflow.com/questions/37130105/does-vue-support-reactivity-on-map-and-set-data-types
      home: new Tag("home", "首页", "/home")
    };
  },

  methods: {
    jump (item) {
      if (!item.path || item.path === '#') {
        return
      }
      if (item.path === 'go(-1)') {
        this.$router.go(-1)
        return
      }
      this.$router.push({path: item.path, query: item.query})
    },
    init() {
      let sf = this;
      //本地尚未缓存，也即未调用过本组件，手动初始化
      if (sf.getTagsFromDB().size == 0) {
        if (sf.tagsMap == null) sf.tagsMap = new Map();
        sf.tagsMap.set(sf.home.key, sf.home);
      } else {
        //有缓存直接读缓存
        sf.tagsMap = sf.getTagsFromDB();
      }
      // sf.log("init", sf.tagsMap);
      //填入当前tag
      if(sf.currTag.title)
        sf.tagsMap.set(sf.currTag.key, sf.currTag);
      sf.tagsMapChangeTracker++;
      //保存到缓存里
      sf.saveTags2db();
    },
    saveLastTagPath(path) {
      sessionStorage.setItem(SSKEY_LAST_PATH, path);
    },
    saveTags2db() {
      let sf = this;
      sessionStorage.setItem(SSKEY, JSON.stringify(sf.m2a(sf.tagsMap)));
    },
    getLastTagPath() {
      let p = sessionStorage.getItem(SSKEY_LAST_PATH);
      return p ? p : this.home.path;
    },
    getTagsFromDB() {
      let sf = this;
      let map = null;
      try {
        map = sf.a2m(JSON.parse(sessionStorage.getItem(SSKEY)));
      } catch (e) {}
      return map != null ? map : new Map();
    },
    isHome(tag) {
      return tag.key === this.home.key;
    },
    isActive(tag) {
      return (
        tag.key ===
        (this.$route.name ? String(this.$route.name).toLowerCase() : DEFAULT)
      );
    },
    onGo2tag(tag) {
      let sf = this;
      sf.$router.push({
        path: tag.path
      });
    },
    onCloseTag(event, tag) {
      let sf = this;
      sf.deleteTag(tag);
      if (sf.isActive(tag)) {
        //上一个标签是否还存在（也即有没有被关掉）
        //若是，跳过去；否则，跳回allTags的最后一项。
        //ps:目前只能记住上一步，上上一步，上上上……步尚未实现
        let path;
        // debugger;
        if (tag.toPathAfterCloseTag) {
          //指定跳转url
          path = tag.toPathAfterCloseTag;
        } else {
          //未指定跳转url
          path = sf.allTags.some(item => {
            return item.path === sf.getLastTagPath();
          })
            ? sf.getLastTagPath()
            : sf.allTags[sf.allTags.length - 1].path;
        }
        // debugger
        sf.$router.push({
          path: path
        });
        sf.log("CLOSE_NAV", "实际跳到:", path);
      }
      //阻止冒泡
      if (event) {
        try {
          event.stopPropagation();
        } catch (e) {
          window.event.cancelBubble = true; //IE浏览器
        }
      }
    },
    deleteTag(tag) {
      let sf = this;
      sf.tagsMap = sf.getTagsFromDB();
      sf.tagsMap.delete(tag.key);
      sf.saveTags2db();
      sf.tagsMapChangeTracker++;
    },
    /**
     * map转数组
     */
    m2a(map) {
      let arr = [];
      if (map)
        for (let [k, v] of map) {
          arr.push(v);
        }
      return arr;
    },
    /**
     * 数组转map
     */
    a2m(arr) {
      let map = new Map();
      arr.forEach(item => {
        map.set(item.key, item);
      });
      return map;
    }
  },

  computed: {
    currTag() {
      return makeTag(this.$route);
    },
    allTags() {
      let sf = this;
      return sf.tagsMapChangeTracker && sf.m2a(sf.tagsMap);
    }
  },

  watch: {
    $route: {
      // immediate: true,
      handler: function(v, ov) {
        let sf = this;
        let vTag = makeTag(v);
        //若当前的标签的key、title、path有一项为空，则不处理
        if (!vTag || !vTag.key || !vTag.title || !vTag.path) return;
        if (ov) {
          let ovTag = makeTag(ov);
          if (vTag.key !== ovTag.key) {
            sf.saveLastTagPath(ovTag.path ? ovTag.path : sf.home.path);
          }
        }
        sf.tagsMap = sf.getTagsFromDB(); //别的页面也许操作了标签，这里要同步数据
        if(sf.currTag.title)
          sf.tagsMap.set(sf.currTag.key, sf.currTag);
        sf.saveTags2db();
        sf.tagsMapChangeTracker++;
        // sf.log("-----watch----- end", sf.tagsMap);
      }
    }
  },

  created() {
    // this.log("-----created-----");
    this.init();
  },
  mounted() {
    let sf = this;
    BUS.$on(BUSEVENT.CLOSE_NAV, d => {
      // d可能是数组，[route,toPath]
      let tag = makeTag(Array.isArray(d) ? d[0] : d);
      if (Array.isArray(d) && d.length >= 2) tag.toPathAfterCloseTag = d[1];
      if (tag != null && tag.key === sf.currTag.key) {
        tag.toPathAfterCloseTag &&
          sf.log("CLOSE_NAV", "将跳到", tag.toPathAfterCloseTag);
        sf.onCloseTag(sf.$event, tag);
        // let btn = document.getElementById("ayl_berad_nav_btn_close_" + tag.key);
        // btn && btn.click();
      }
    });
  },
  beforeDestroy() {
    // BUS.$un
  }
};
</script>

<style lang='sass' scoped>
.bread-nav
  height: $beradNavHeight
  line-height: $beradNavHeight - 1px
  padding-left: 34px
  width: 100%
  position: relative

  &:after
    content: " "
    position: absolute
    width: 2px
    height: 12px
    background: $-color-primary-1
    border-radius: 1px
    top: 50%
    left: $padding-base
    transform: translateY(-50%)
    .active
      cursor: pointer
    .shallow
      color: $-font-color-1
    
  .bread-nav-hw
    height: $beradNavHeight
    line-height: $beradNavHeight - 1px
    padding-left: 34px
    width: 100%
    position: relative

    &:after
      content: " "
      position: absolute
      width: 2px
      height: 12px
      background: $-color-primary-3
      border-radius: 1px
      top: 50%
      left: $padding-base
      transform: translateY(-50%)
    .active
      cursor: pointer
    .shallow
      color: $-font-color-1
    
</style>

<style lang="scss" scoped>
.tags-view-container {
  height: 34px;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #d8dce5;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);
  .tags-view-wrapper {
    .tags-view-item {
      display: inline-block;
      position: relative;
      cursor: pointer;
      height: 26px;
      line-height: 26px;
      border: 1px solid #d8dce5;
      color: #495060;
      background: #fff;
      padding: 0 8px;
      font-size: 12px;
      margin-left: 5px;
      margin-top: 4px;
      &:first-of-type {
        margin-left: 15px;
      }
      &:last-of-type {
        margin-right: 15px;
      }
      &.active {
        background-color: #42b983;
        color: #fff;
        border-color: #42b983;
        &::before {
          content: "";
          background: #fff;
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          position: relative;
          margin-right: 2px;
        }
      }
    }
  }
  .contextmenu {
    margin: 0;
    background: #fff;
    z-index: 3000;
    position: absolute;
    list-style-type: none;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
    li {
      margin: 0;
      padding: 7px 16px;
      cursor: pointer;
      &:hover {
        background: #eee;
      }
    }
  }
}
</style>

<style lang="scss">
//reset element css of el-icon-close
.tags-view-wrapper {
  .tags-view-item {
    .el-icon-close {
      width: 16px;
      height: 16px;
      vertical-align: 2px;
      border-radius: 50%;
      text-align: center;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transform-origin: 100% 50%;
      &:before {
        transform: scale(0.6);
        display: inline-block;
        vertical-align: -3px;
      }
      &:hover {
        background-color: #b4bccc;
        color: #fff;
      }
    }
  }
}
</style>
