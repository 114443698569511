export default {
    //性别
    sex: {
        list: [{
                value: 0,
                label: '男',
            },
            {
                value: 1,
                label: '女',
            },
        ]
    },
    personStatus: {
        list: [{
                value: 0,
                label: '在职',
            },
            {
                value: 1,
                label: '离职',
            },
        ]
    },
    //人员类型
    personnelType: {
        list: [{
            value: 1,
            label: '管理人员',
            children: [{
                    value: '项目经理',
                    label: '项目经理'
                },
                {
                    value: '项目副经理',
                    label: '项目副经理'
                },
                {
                    value: '片区负责人',
                    label: '片区负责人'
                },
                {
                    value: '车队长',
                    label: '车队长'
                },
                {
                    value: '小组长',
                    label: '小组长'
                },
                {
                    value: '安全生产管理员',
                    label: '安全生产管理员'
                },
            ]
        }, {
            value: 2,
            label: '一线作业人员',
            children: [{
                    value: '一线保洁人员',
                    label: '一线保洁人员'
                },
                {
                    value: '垃圾收集工',
                    label: '垃圾收集工'
                },
                {
                    value: '设备操作工',
                    label: '设备操作工'
                },
            ]
        }, {
            value: 3,
            label: '作业司机',
            children: [{
                    value: '扫路车司机',
                    label: '扫路车司机'
                },
                {
                    value: '清洗车司机',
                    label: '清洗车司机'
                },
                {
                    value: '垃圾收集车司机',
                    label: '垃圾收集车司机'
                },
                {
                    value: '钩臂车司机',
                    label: '钩臂车司机'
                },
                {
                    value: '平板车司机',
                    label: '平板车司机'
                },
            ],
        }, {
            value: 4,
            label: '公厕人员',
            children: [{
                    value: '公厕保洁员',
                    label: '公厕保洁员'
                },
                {
                    value: '水电工',
                    label: '水电工'
                },
            ],
        }, {
            value: '其它',
            label: '其它',
            //children: [],
        }],
        // 查询条件列表
        allList: function () {
            return [{
                label: '全部',
                value: null
            }].concat(this.list)
        },
        // 根据枚举值获取名称
        getName: function (val) {
            switch (val) {
                default:
                    return val
            }
        }
    },
    personnelTypeNew: {
        list: [{
            value: 1,
            label: '管理人员',
            children: [{
                    value: 0,
                    label: '项目经理'
                },
                {
                    value: 1,
                    label: '项目副经理'
                },
                {
                    value: 2,
                    label: '片区负责人'
                },
                {
                    value: 3,
                    label: '车队长'
                },
                {
                    value: 4,
                    label: '小组长'
                },
                {
                    value: 5,
                    label: '安全生产管理员'
                },
            ]
        }, {
            value: 2,
            label: '一线作业人员',
            children: [{
                    value: 6,
                    label: '一线保洁人员'
                },
                {
                    value: 7,
                    label: '垃圾收集工'
                },
                {
                    value: 8,
                    label: '设备操作工'
                },
            ]
        }, {
            value: 3,
            label: '作业司机',
            children: [{
                    value: 9,
                    label: '扫路车司机'
                },
                {
                    value: 10,
                    label: '清洗车司机'
                },
                {
                    value: 11,
                    label: '垃圾收集车司机'
                },
                {
                    value: 12,
                    label: '勾臂车司机'
                },
                {
                    value: 13,
                    label: '平板车司机'
                },
            ],
        }, {
            value: 4,
            label: '公厕人员',
            children: [{
                    value: 14,
                    label: '公厕保洁员'
                },
                {
                    value: 15,
                    label: '水电工'
                },
            ],
        }, {
            value: 16,
            label: '其它',
            //children: [],
        }],
    },
    //车辆类型
    carType: {
        list: [{
                value: 1,
                label: '管理用车',
            },
            {
                value: 2,
                label: '道路保洁车辆',
                children: [{
                        value: 21,
                        label: '扫路车',
                    },
                    {
                        value: 22,
                        label: '清洗车',
                    },
                    {
                        value: 23,
                        label: '垃圾收集车',
                    },
                ]
            },
            {
                value: 3,
                label: '垃圾清运车辆',
                children: [{
                        value: 31,
                        label: '勾臂车',
                    },
                    {
                        value: 32,
                        label: '密闭式后装压缩车',
                    },
                    {
                        value: 33,
                        label: '桶装垃圾运输车',
                    },
                    {
                        value: 34,
                        label: '自装卸式垃圾车',
                    },
                    {
                        value: 35,
                        label: '自卸平板车',
                    },
                ]
            },
            {
                value: 4,
                label: '其他',
                children: [{
                        value: 41,
                        label: '箱式运输车',
                    },
                    {
                        value: 42,
                        label: '路面养护车',
                    },
                    {
                        value: 43,
                        label: '起重货车',
                    },
                ]
            },
        ]
    },
    //权属类型
    belongType: {
        list: [{
            label: '自有-智连',
            value: '自有-智连'
        },
        {
            label: '租赁',
            value: '租赁'
        },
        {
            label: '挂靠',
            value: '挂靠'
        },
        {
            label: '外请',
            value: '外请'
        },
        {
            label: '自有-三正',
            value: '自有-三正'
        }
        ]
    },
    //动力类型
    powerType: {
        list: [{
                value: 0,
                label: '柴油车',
            },
            {
                value: 1,
                label: '汽油车',
            },
            {
                value: 2,
                label: '新能源车',
            },
            {
                value: 3,
                label: '已做PDF改造的柴油车',
            },
        ]
    },
    //车辆状态
    carStatus: {
        list: [{
                value: 0,
                label: '在用',
            },
            {
                value: 1,
                label: '维修',
            },
            {
                value: 2,
                label: '闲置',
            },
            {
                value: 3,
                label: '作废',
            },
        ]
    },
    //油箱类型
    fuelTankType: {
        list: [{
                value: 0,
                label: `长方形`,
            },
            {
                value: 1,
                label: `圆形`,
            },
            {
                value: 2,
                label: `D形`,
            },
            {
                value: 3,
                label: `椭圆形`,
            },
        ]
    },
    //垃圾中转类型
    transferType: {
        list: [{
                value: 0,
                label: `固定`,
            },
            {
                value: 1,
                label: `移动`,
            },
        ]
    },
    //环保垃圾屋类型
    ecoType: {
        list: [{
                value: 0,
                label: `固定`,
            },
            {
                value: 1,
                label: `移动`,
            },
        ]
    },
    //环保垃圾屋用途
    application: {
        list: [{
                value: 0,
                label: `垃圾分类`,
            },
            {
                value: 1,
                label: `摆放工具`,
            },
            {
                value: 2,
                label: `垃圾收集`,
            },
        ]
    },
    //产权所有
    propertyRights: {
        list: [{
                value: 0,
                label: `政府投入`,
            },
            {
                value: 1,
                label: `企业自有`,
            },
        ]
    },
    //高压清洗类型
    highPressureCleanType: {
        list: [{
                value: 0,
                label: `冷水`,
            },
            {
                value: 1,
                label: `热水`,
            },
            {
                value: 2,
                label: `蒸汽`,
            },
        ]

    },
    //垃圾压缩箱的颜色
    garbageColorType: {
        list: [{
                value: 0,
                label: `白色`,
            },
            {
                value: 1,
                label: `蓝色`,
            },
            {
                value: 2,
                label: `绿色`,
            },
        ]
    },
    //垃圾压缩箱的容积
    garbageVolumeType: {
        list: [{
                value: 0,
                label: `8立方以下`,
            },
            {
                value: 1,
                label: `8-10立方`,
            },
            {
                value: 2,
                label: `10立方以上`,
            },
        ]
    },
    //垃圾压缩箱的类别
    garbageType: {
        list: [{
                value: 0,
                label: `分体`,
            },
            {
                value: 1,
                label: `连体`,
            },
        ]
    },
    //设备类型
    deviceType: {
        list: [{
                value: 0,
                label: `视频终端`,
            },
            {
                value: 1,
                label: `定位终端`,
            },
            {
                value: 2,
                label: `其他`,
            },
        ]
    },
    //设备应用对象
    objectType: {
        list: [{
                value: 0,
                label: `车辆`,
            },
            {
                value: 1,
                label: `人员（手环）`,
            },
        ]
    },
    //设备管理-协议类型
    protocolType: {
        list: [{
                label: 'TCP',
                value: 'TCP'
            },
            {
                label: '部标808',
                value: '部标808'
            },
            {
                label: '部标1078',
                value: '部标1078'
            },
            {
                label: '苏标',
                value: '苏标'
            },
            {
                label: '艾依灵自定义协议',
                value: '艾依灵自定义协议'
            },
            {
                label: '其他',
                value: '其他'
            },
        ],
        // 查询条件列表
        getListForPeople() {
            return this.list.filter(function (item) {
                return ["TCP", "其他"].includes(item.value);
            });
        },
        getListForCar() {
            return this.list.filter(function (item) {
                return ["部标808", "部标1078", "苏标", "艾依灵自定义协议", "其他"].includes(item.value);
            });
        },
    },
    //道路类别
    roadType: {
        list: [{
                value: 0,
                label: `工业道路`,
            },
            {
                value: 1,
                label: `城中村道路`,
            },
            {
                value: 2,
                label: `公路`,
            },
        ]
    },
    //主次干道
    roadLevel: {
        list: [{
                value: 0,
                label: `主干道`,
            },
            {
                value: 1,
                label: `次干道`,
            },
        ]
    },
    //保洁等级
    cleanLevel: {
        list: [{
                value: 0,
                label: `特级`,
            },
            {
                value: 1,
                label: `一级`,
            },
            {
                value: 2,
                label: `二级`,
            },
            {
                value: 3,
                label: `三级`,
            },
        ]
    },
    checkBoxOptions: {
        list: [{
                value: 0,
                label: `否`,
            },
            {
                value: 1,
                label: `是`,
            },
        ]
    },
    //国土地块-属于性质
    belong: {
        list: [{
                value: 0,
                label: `村`,
            },
            {
                value: 1,
                label: `居委会`,
            },
        ]
    },

    //实时监管 - 说明
    instructionsType: {
        list: [{
            type: '环卫工人：',
            icon: 'iconrenyuanzaigang2',
            state: '正常在岗',
            color: '#1ABC9C',
            value: 0,
        }, {
            type: '环卫工人：',
            icon: 'iconrenyuanzaigang',
            state: '异常在岗',
            color: '#FF8C1A',
            value: 1,
        }, {
            type: '环卫工人：',
            icon: 'iconrenyuanzaigang1',
            state: '不在岗',
            color: '#AAADB3',
            value: 2,
        }, {
            type: '车辆：',
            icon: 'iconcheliangzuoye',
            state: '有效作业中',
            color: '#1ABC9C',
            value: 0,
        }, {
            type: '车辆：',
            icon: 'iconcheliangzuoye1',
            state: '非作业中',
            color: '#FF8C1A',
            value: 1,
        }, {
            type: '车辆：',
            icon: 'iconcheliangzuoye2',
            state: '报警',
            color: '#F0090A',
            value: 2,
        }, {
            type: '车辆：',
            icon: 'iconcheliangzuoye3',
            state: '离线',
            color: '#AAADB3',
            value: 3,
        }],
    },

    //实时监管-tabs 
    tabsButtonList: {
        list: [{
            btn: '网格',
        }, {
            btn: '人员',
        }, {
            btn: '车辆',
        }, {
            btn: '作业路线',
        }, {
            btn: '其它信息',
        }, ],
    },
    //新增作业规则-tabs
    operatingRulesList: {
        list: [{
                label: 0,
                value: '电子围栏'
            },
            {
                label: 1,
                value: '区域限速'
            },
            {
                label: 2,
                value: '道路超速'
            },
            {
                label: 3,
                value: '作业超速'
            },
            {
                label: 4,
                value: '异常离岗'
            },
            {
                label: 5,
                value: '超时静止'
            }
        ],
    },

    // 新增规则枚举
    RegionType: {
        list: [{
            label: '全部',
            value: '全部'
        }, {
            label: '圆形',
            value: '圆形'
        }, {
            label: '直线',
            value: '直线'
        }, {
            label: '矩形',
            value: '矩形'
        }],
    },
    //作业类型
    jobType: {
        list: [{
                value: `0`,
                label: `机械化清洗`,
            },
            {
                value: `1`,
                label: `机械化清扫`,
            },
        ]
    },
    //审核状态
    checkStatusType: {
        list: [{
            value: 0,
            label: '未提交'
        }, {
            value: 1,
            label: '待审核'
        }, {
            value: 2,
            label: '已通过'
        }, {
            value: 3,
            label: '未通过'
        }],
        // 根据枚举值获取名称
        getName: function (val) {
            switch (val) {
                case 0:
                    return '未提交'
                case 1:
                    return '待审核'
                case 2:
                    return '已通过'
                case 3:
                    return '未通过'
                default:
                    return val
            }
        }
    },
    // 合同状态
    contractStatusOption: {
        list: [{
                value: 0,
                label: '再用'
            },
            {
                value: 1,
                label: '终止'
            },
            {
                value: 2,
                label: '续约'
            },
        ],
    },
    //公厕管理类别
    manageType: {
        list: [{
                value: 0,
                label: '市政'
            },
            {
                value: 1,
                label: '转运站附近'
            },
            {
                value: 2,
                label: '集贸市场'
            },
            {
                value: 3,
                label: '公园'
            },
            {
                value: 4,
                label: '绿地'
            },
            {
                value: 5,
                label: '广场'
            },
            {
                value: 6,
                label: '商业设施'
            },
            {
                value: 7,
                label: '加油站'
            },
            {
                value: 8,
                label: '地铁'
            },
            {
                value: 9,
                label: '汽车站'
            },
            {
                value: 10,
                label: '火车站'
            },
            {
                value: 11,
                label: '机场'
            },
            {
                value: 12,
                label: '医院'
            },
            {
                value: 13,
                label: '口岸'
            },
            {
                value: 14,
                label: '旅游景区'
            },
            {
                value: 15,
                label: '文体旅游设施'
            },
            {
                value: 16,
                label: '金融电信营业厅'
            },
            {
                value: 17,
                label: '其它'
            },
        ],
        manageList(val) {
            return {
                0: "市政",
                1: "转运站附近",
                2: "集贸市场",
                3: "公园",
                4: "绿地",
                5: "广场",
                6: "商业设施",
                7: "加油站",
                8: "地铁",
                9: "汽车站",
                10: "火车站",
                11: "机场",
                12: "医院",
                13: "口岸",
                14: "旅游景区",
                15: "文体旅游设施",
                16: "金融电信营业厅",
                17: "其它",
            } [val]
        }

    },
    //公厕类别
    wcType: {
        list: [{
                value: 0,
                label: '固定式'
            },
            {
                value: 1,
                label: '移动式'
            }
        ]
    },
    // 申诉对象
    complainObjectType: {
        list: [{
                value: 0,
                label: '人员'
            },
            {
                value: 1,
                label: '车辆'
            },
        ],
    },

    //不合格原因
    nonconformityType: {
        list: [{
                value: '迟到',
                label: '迟到'
            },
            {
                value: '早退',
                label: '早退'
            },
            {
                value: '缺勤',
                label: '缺勤'
            },
            {
                value: '作业里程不足',
                label: '作业里程不足'
            },
        ]
    },

    //规则类别
    rulesTypeOption: {
        list: [{
                value: 0,
                label: "电子围栏"
            },
            {
                value: 1,
                label: "区域限速"
            },
            {
                value: 2,
                label: "道路超速"
            },
            {
                value: 3,
                label: "作业超速"
            },
            {
                value: 4,
                label: "异常离岗"
            },
            {
                value: 5,
                label: "超时静止"
            }
        ]
    },
    // 预警状态
    eventStatusOption: {
        list: [{
                value: 0,
                label: "预警持续中"
            },
            {
                value: 1,
                label: "预警结束"
            },
            {
                value: 2,
                label: "预警解除"
            },
            {
                value: 3,
                label: "预警已处理"
            }
        ]
    },
    // 预警类型
    eventTypeOption: {
        // 人员
        persons: [{
                value: 0,
                label: "迟到预警"
            },
            {
                value: 1,
                label: "离岗预警"
            },
            {
                value: 2,
                label: "静止预警"
            },
            {
                value: 3,
                label: "轨迹一致预警"
            },
            {
                value: 4,
                label: "作业里程不足预警"
            },
        ],
        // 车辆
        cars: [{
                value: 5,
                label: "机扫晚点预警"
            },
            {
                value: 6,
                label: "机洗晚点预警"
            },
        ]
    },

    // 培训类型
    trainingTypeOption: {
        list: [{
                value: 0,
                label: "“三项岗位”人员安全培训"
            },
            {
                value: 1,
                label: "新入职人员安排培训"
            },
            {
                value: 2,
                label: "日常安全培训和安全会议"
            },
        ]
    },

    // list1审核状态（城管局）：[0-未提交，1-待审核，2-不通过，3-通过]
    // list2审核状态（非城管局）：[1-待审核，2-不通过，3-通过]
    checkStatusOption: {
        list1: [{
                value: 0,
                label: "未提交"
            },
            {
                value: 1,
                label: "待审核"
            },
            {
                value: 2,
                label: "通过"
            },
            {
                value: 3,
                label: "不通过"
            },
        ],
        list2: [{
                value: 1,
                label: "待审核"
            },
            {
                value: 2,
                label: "通过"
            },
            {
                value: 3,
                label: "不通过"
            },
        ]
    },
    // 设备应用对象
    applicationObjectType: {
        list: [{
                value: 0,
                label: '车辆'
            },
            {
                value: 1,
                label: '人员'
            },
        ],
    },
    // 异常类型
    abnormalTypeOption: {
        list: [{
                value: 1,
                label: '体温异常'
            },
            {
                value: 2,
                label: '心率异常'
            },
            {
                value: 3,
                label: '血压异常'
            },
            {
                value: 4,
                label: '血氧异常'
            },
        ]
    },
    //sim 运营商
    ServiceProvider: {
        // 枚举列表
        list: [{
                label: '中国移动',
                value: '0'
            },
            {
                label: '中国联通',
                value: '1'
            },
            {
                label: '中国电信',
                value: '2'
            }
        ],
        // 查询条件列表
        allList: function () {
            return [{
                label: '全部',
                value: null
            }].concat(this.list)
        },
        // 根据枚举值获取名称
        getServiceProvider(val) {
            return {
                '0': '中国移动',
                '1': '中国联通',
                '2': '中国电信'
            } [val]
        },
    },
    //收集点类型
    collectType: {
        list: [{
                label: '固定砌砖式',
                value: 0
            },
            {
                label: '移动环保型',
                value: 1
            },
            {
                label: '露天型',
                value: 2
            },
            {
                label: '垃圾房',
                value: 3
            },
            {
                label: '其他',
                value: 4
            }
        ]
    },
    //垃圾桶类型
    trashType: {
        list: [{
                label: '660L',
                value: 0
            },
            {
                label: '240L',
                value: 1
            },
            {
                label: '铁桶',
                value: 2
            },
            {
                label: '胶桶',
                value: 3
            },
            {
                label: '其他',
                value: 4
            }
        ]
    },
    //特殊道路类型
    specialRoadType: {
        list: [{
                label: '人行天桥',
                value: 0
            },
            {
                label: '地下通道',
                value: 1
            },
            {
                label: '水沟',
                value: 2
            },
            {
                label: '施工道路',
                value: 3
            }
        ]
    },

    //人员作业报警报警类型
    personnelAlarmNames: {
        list: [{
            value: '关机报警',
            label: '关机报警',
        }, {
            value: 'SOS报警',
            label: 'SOS报警',
        }, {
            value: 1,
            label: '出勤报警',
            children: [
                { value: '迟到报警', label: '迟到报警' },
                { value: '早退报警', label: '早退报警' },
                { value: '缺勤报警', label: '缺勤报警' },
            ]
        }, {
            value: 2,
            label: '作业报警',
            children: [
                { value: '异常离岗报警', label: '异常离岗报警' },
                { value: '超时静止报警', label: '超时静止报警' },
                { value: '作业里程不足报警', label: '作业里程不足报警' },
                { value: '一人多设备报警', label: '一人多设备报警' },
            ]
        }],
        // 查询条件列表
        // allList: function() {
        //     return [{
        //         label: '全部',
        //         value: null
        //     }].concat(this.list)
        // },
        // 根据枚举值获取名称
        getName: function(val) {
            switch (val) {
                default: return val
            }
        }
    },
}