import Layout from '@views/Layout.vue'

export default {
    path: '/district-management',
    component: Layout,
    children: [{ //标段信息
            name: 'District-info',
            path: '/district-management/district-info',
            component: () =>
                import ('@views/district-management/district-info/district-info'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '标段信息',
            }
        }, { //人员调入
            name: 'Personnel-transferred',
            path: '/district-management/district-info/personnel-transferred',
            component: () =>
                import ('@views/district-management/district-info/personnel-transferred'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '人员调入',
            }
        }, { //车辆调入
            name: 'Vehicle-transferred',
            path: '/district-management/district-info/vehicle-transferred',
            component: () =>
                import ('@views/district-management/district-info/vehicle-transferred'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '车辆调入',
            }
        }, { //城中村调入
            name: 'Urban-village-transferred',
            path: '/district-management/district-info/urban-village-transferred',
            component: () =>
                import ('@views/district-management/district-info/urban-village-transferred'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '城中村调入',
            }
        }, { //市政道路调入
            name: 'City-road-transferred',
            path: '/district-management/district-info/city-road-transferred',
            component: () =>
                import ('@views/district-management/district-info/city-road-transferred'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '城中村调入',
            }
        }, { //环保垃圾屋调入
            name: 'Garbage-green-transferred',
            path: '/district-management/district-info/garbage-green-transferred',
            component: () =>
                import ('@views/district-management/district-info/garbage-green-transferred'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '环保垃圾屋调入',
            }
        }, { //垃圾中转站调入
            name: 'Garbage-transfer-transferred',
            path: '/district-management/district-info/garbage-transfer-transferred',
            component: () =>
                import ('@views/district-management/district-info/garbage-transfer-transferred'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '垃圾中转站调入',
            }
        }, { //垃圾收集点调入
            name: 'Garbage-collection-transferred',
            path: '/district-management/district-info/garbage-collection-transferred',
            component: () =>
                import ('@views/district-management/district-info/garbage-collection-transferred'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '垃圾收集点调入',
            }
        }, { //国土地块调入
            name: 'Land-transfer',
            path: '/district-management/district-info/land-transfer',
            component: () =>
                import ('@views/district-management/district-info/land-transfer'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '国土地块调入',
            }
        }, { //公厕调入
            name: 'Public-toilets-transfer',
            path: '/district-management/district-info/public-toilets-transfer',
            component: () =>
                import ('@views/district-management/district-info/public-toilets-transfer'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '公厕调入',
            }
        }, { //新增标段
            name: 'AddDistrict',
            path: '/district-management/district-info/add-district',
            component: () =>
                import ('@views/district-management/district-info/add-district'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '新增标段',
            }
        }, { //编辑标段
            name: 'EditDistrict',
            path: '/district-management/district-info/edit-district',
            component: () =>
                import ('@views/district-management/district-info/edit-district'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '编辑标段',
            }
        }, { //查看标段
            name: 'DistrictDetails',
            path: '/district-management/district-info/district-details',
            component: () =>
                import ('@views/district-management/district-info/district-details'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '查看标段',
            }
        },

        /**
         * 人员管理-list
         */
        {
            name: 'Personnel-info',
            path: '/district-management/personnel-info',
            component: () =>
                import ('@views/district-management/personnel-info/personnel-info'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '人员管理',
            }
        },
        /**
         * 人员管理-add
         */
        {
            name: 'Add-personnel',
            path: '/district-management/personnel-info/add-personnel',
            component: () =>
                import ('@views/district-management/personnel-info/add-personnel'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '新增人员',
            }
        },
        /**
         * 人员管理-edit
         */
        {
            name: 'Edit-personnel',
            path: '/district-management/personnel-info/edit-personnel',
            component: () =>
                import ('@views/district-management/personnel-info/edit-personnel'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '编辑人员',
            }
        },
                /**
         * 人员管理-info
         */
        {
            name: 'Info-personnel',
            path: '/district-management/personnel-info/info-personnel',
            component: () =>
                import ('@views/district-management/personnel-info/info-personnel'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '编辑人员',
            }
        },
        /**
         * 人员管理-匹配人员
         */
        {
            name: 'Match-people',
            path: '/district-management/personnel-info/match-people',
            component: () =>
                import ('@views/district-management/personnel-info/match-people'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '匹配人员',
            }
        },
        /**
         * 历史标段
         */
        {
            name: 'Historical-blocks',
            path: '/district-management/historical-blocks/historical-blocks',
            component: () =>
                import ('@views/district-management/historical-blocks/historical-blocks'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '历史标段',
            }
        },
        /**
         * 车辆管理
         */
        {
            name: 'Vehicle-management',
            path: '/district-management/vehicle-management/vehicle-management',
            component: () =>
                import ('@views/district-management/vehicle-management/vehicle-management'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '车辆管理',
            }
        },
        /**
         * 车辆管理-新增车辆
         */
        {
            name: 'Add-car',
            path: '/district-management/vehicle-management/add-car',
            component: () =>
                import ('@views/district-management/vehicle-management/add-car'),
            meta: {
                title: '新增车辆',
            }
        },
        /**
         * 车辆管理-编辑车辆
         */
        {
            name: 'Edit-car',
            path: '/district-management/vehicle-management/edit-car',
            component: () =>
                import ('@views/district-management/vehicle-management/edit-car'),
            meta: {
                title: '编辑车辆',
            }
        },
        /**
         * 车辆管理-车辆匹配
         */
        {
            name: 'Matching-car',
            path: '/district-management/vehicle-management/matching-car',
            component: () =>
                import ('@views/district-management/vehicle-management/matching-car'),
            meta: {
                title: '车辆匹配设备',
            }
        },
        /**
         * 车辆管理-查看详情
         */
        {
            name: 'Car-details',
            path: '/district-management/vehicle-management/car-details',
            component: () =>
                import ('@views/district-management/vehicle-management/car-details'),
            meta: {
                title: '车辆详情',
            }
        },
        /**
         * 车队管理
         */
        {
            name: 'fleet-list',
            path: '/district-management/fleet-management/list',
            component: () =>
                import ('@views/district-management/fleet-management/list'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '车队管理',
            }
        },
        /**
         * 车队管理-新增车队
         */
        {
            name: 'fleet-add',
            path: '/district-management/fleet-management/add',
            component: () =>
                import ('@views/district-management/fleet-management/add'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '新增车队',
            }
        },
        /**
         * 车队管理-编辑车队
         */
        {
            name: 'fleet-edit',
            path: '/district-management/fleet-management/edit',
            component: () =>
                import ('@views/district-management/fleet-management/edit'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '编辑车队',
            }
        },
        /**
         * 车队管理-分配车队
         */
        {
            name: 'fleet-distribution',
            path: '/district-management/fleet-management/distribution',
            component: () =>
                import ('@views/district-management/fleet-management/distribution'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '分配车队',
            }
        },
        /**
         * 车队管理-车队信息
         */
        {
            name: 'fleet-info',
            path: '/district-management/fleet-management/info',
            component: () =>
                import ('@views/district-management/fleet-management/info'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '车队信息',
            }
        },
        /**
         * 设备管理
         */
        {
            name: 'equipment-list',
            path: '/district-management/equipment-management/list',
            component: () =>
                import ('@views/district-management/equipment-management/list'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '设备管理',
            }
        },
        /**
         * 设备管理-设备匹配SIM卡
         */
        {
            name: 'equipment-match',
            path: '/district-management/equipment-management/match',
            component: () =>
                import ('@views/district-management/equipment-management/match'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '设备匹配SIM卡',
            }
        },
        /**
         * 设备管理-新增设备
         */
        {
            name: 'equipment-add',
            path: '/district-management/equipment-management/add',
            component: () =>
                import ('@views/district-management/equipment-management/add'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '新增设备',
            }
        },
        /**
         * 设备管理-编辑设备
         */
        {
            name: 'equipment-edit',
            path: '/district-management/equipment-management/edit',
            component: () =>
                import ('@views/district-management/equipment-management/edit'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '编辑设备',
            }
        },
        /**
         * SIM卡管理
         */
        {
            name: 'Sim-management',
            path: '/district-management/sim-management/sim-management',
            component: () =>
                import ('@views/district-management/sim-management/sim-management'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: 'SIM卡管理',
            }
        },
        /**
         * SIM卡管理-SIM卡信息
         */
        {
            name: 'Sim-informationt',
            path: '/district-management/sim-management/sim-information',
            component: () =>
                import ('@views/district-management/sim-management/sim-information'),
            meta: {
                title: 'SIM卡信息',
            }
        },
        /**
         * SIM卡管理-新增SIM
         */
        {
            name: 'Add-sim',
            path: '/district-management/sim-management/add-sim',
            component: () =>
                import ('@views/district-management/sim-management/add-sim'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '新增SIM卡',
            }
        },
        /**
         * SIM卡管理-编辑SIM
         */
        {
            name: 'Edit-sim',
            path: '/district-management/sim-management/edit-sim',
            component: () =>
                import ('@views/district-management/sim-management/edit-sim'),
            meta: {
                title: '编辑SIM卡',
            }
        },
        /**
         * 城中村管理
         */
        {
            name: 'urban-villages-list',
            path: '/district-management/urban-villages-management/list',
            component: () =>
                import ('@views/district-management/urban-villages-management/list'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '城中村管理',
            }
        },
        /**
         * 城中村管理-新增
         */
        {
            name: 'urban-villages-add',
            path: '/district-management/urban-villages-management/add',
            component: () =>
                import ('@views/district-management/urban-villages-management/add'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '新增城中村',
            }
        },
        /**
         * 城中村管理-编辑
         */
        {
            name: 'urban-villages-edit',
            path: '/district-management/urban-villages-management/edit',
            component: () =>
                import ('@views/district-management/urban-villages-management/edit'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '编辑城中村',
            }
        },
        /**
         * 城中村管理-详情
         */
        {
            name: 'villages-details-info',
            path: '/district-management/urban-villages-management/info',
            component: () =>
                import ('@views/district-management/urban-villages-management/info'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '城中村详情',
            }
        },
        /**
         * 垃圾中转站管理
         */
        {
            name: 'transfer-station-list',
            path: '/district-management/transfer-station-management/list',
            component: () =>
                import ('@views/district-management/transfer-station-management/list'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '垃圾中转站',
            }
        },
        /**
         * 新增垃圾中转站
         */
        {
            name: 'transfer-station-add',
            path: '/district-management/transfer-station-management/add',
            component: () =>
                import ('@views/district-management/transfer-station-management/add'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '新增垃圾中转站',
            }
        },
        /**
         * 编辑垃圾中转站
         */
        {
            name: 'transfer-station-edit',
            path: '/district-management/transfer-station-management/edit',
            component: () =>
                import ('@views/district-management/transfer-station-management/edit'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '编辑垃圾中转站',
            }
        },
        /**
         * 垃圾中转站详情
         */
        {
            name: 'transfer-station-info',
            path: '/district-management/transfer-station-management/info',
            component: () =>
                import ('@views/district-management/transfer-station-management/info'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '垃圾中转站详情',
            }
        },
        /**
         * 市政道路
         */
        {
            name: 'municipal-road-list',
            path: '/district-management/municipal-road/list',
            component: () =>
                import ('@views/district-management/municipal-road/municipal-road-management'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '市政道路',
            }
        },
        /**
         * 市政道路-新增
         */
        {
            name: 'municipal-road-add',
            path: '/district-management/municipal-road/add',
            component: () =>
                import ('@views/district-management/municipal-road/add-municipal-road'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '新增市政道路',
            }
        },
        /**
         * 市政道路-修改
         */
        {
            name: 'municipal-road-edit',
            path: '/district-management/municipal-road/edit',
            component: () =>
                import ('@views/district-management/municipal-road/edit-municipal-road'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '编辑市政道路',
            }
        },
        /**
         * 市政道路-详情
         */
        {
            name: 'municipal-road-info',
            path: '/district-management/municipal-road/info',
            component: () =>
                import ('@views/district-management/municipal-road/info'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '市政道路详情',
            }
        },
        /**
         * 垃圾收集点-列表
         */
        {
            name: 'refuse-collection-point-list',
            path: '/district-management/refuse-collection-point/list',
            component: () =>
                import ('@views/district-management/refuse-collection-point/list'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '垃圾收集点',
            }
        },
        /**
         * 垃圾收集点-新增
         */
        {
            name: 'refuse-collection-point-add',
            path: '/district-management/refuse-collection-point/add',
            component: () =>
                import ('@views/district-management/refuse-collection-point/add'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '新增垃圾收集点',
            }
        },
        /**
         * 垃圾收集点-编辑
         */
        {
            name: 'refuse-collection-point-edit',
            path: '/district-management/refuse-collection-point/edit',
            component: () =>
                import ('@views/district-management/refuse-collection-point/edit'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '编辑垃圾收集点',
            }
        },
        /**
         * 垃圾收集点-详情
         */
        {
            name: 'refuse-collection-point-info',
            path: '/district-management/refuse-collection-point/info',
            component: () =>
                import ('@views/district-management/refuse-collection-point/info'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '垃圾收集点详情',
            }
        },
        /**
         * 国土地块
         */
        {
            name: 'plot-list',
            path: '/district-management/plot-management/list',
            component: () =>
                import ('@views/district-management/plot-management/list'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '国土地块',
            }
        },
        /**
         * 国土地块-新增
         */
        {
            name: 'plot-add',
            path: '/district-management/plot-management/add',
            component: () =>
                import ('@views/district-management/plot-management/add'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '新增国土地块',
            }
        },
        /**
         * 国土地块-编辑
         */
        {
            name: 'plot-edit',
            path: '/district-management/plot-management/edit',
            component: () =>
                import ('@views/district-management/plot-management/edit'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '编辑国土地块',
            }
        },
        /**
         * 国土地块-详情
         */
        {
            name: 'plot-info',
            path: '/district-management/plot-management/info',
            component: () =>
                import ('@views/district-management/plot-management/info'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '国土地块详情',
            }
        },
        /**
         * 环保垃圾屋
         */
        {
            name: 'green-garbage-house-list',
            path: '/district-management/green-garbage-house/list',
            component: () =>
                import ('@views/district-management/green-garbage-house/list'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '环保垃圾屋',
            }
        },
        /**
         * 环保垃圾屋-add
         */
        {
            name: 'green-garbage-house-add',
            path: '/district-management/green-garbage-house/add',
            component: () =>
                import ('@views/district-management/green-garbage-house/add'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '新增环保垃圾屋',
            }
        },
        /**
         * 环保垃圾屋-编辑
         */
        {
            name: 'green-garbage-house-edit',
            path: '/district-management/green-garbage-house/edit',
            component: () =>
                import ('@views/district-management/green-garbage-house/edit'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '编辑环保垃圾屋',
            }
        },
        /**
         * 环保垃圾屋-详情
         */
        {
            name: 'green-garbage-house-info',
            path: '/district-management/green-garbage-house/info',
            component: () =>
                import ('@views/district-management/green-garbage-house/info'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '保垃圾屋详情',
            }
        },
        /**
         * 公厕管理
         */
        {
            name: 'public-toilets-list',
            path: '/district-management/public-toilets/list',
            component: () =>
                import ('@views/district-management/public-toilets/list'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '公厕管理',
            }
        },
        /**
         * 公厕管理-新增
         */
        {
            name: 'public-toilets-add',
            path: '/district-management/public-toilets/add',
            component: () =>
                import ('@views/district-management/public-toilets/add'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '新增公厕',
            }
        },
        /**
         * 公厕管理-编辑
         */
        {
            name: 'public-toilets-edit',
            path: '/district-management/public-toilets/edit',
            component: () =>
                import ('@views/district-management/public-toilets/edit'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '编辑公厕',
            }
        },
        /**
         * 公厕管理-详情
         */
        {
            name: 'public-toilets-info',
            path: '/district-management/public-toilets/info',
            component: () =>
                import ('@views/district-management/public-toilets/info'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '公厕详情',
            }
        },
        /**
         * 车辆作业路线
         */
        {
            name: 'Vehicle-operation-management',
            path: '/district-management/vehicle-operation/vehicle-operation-management',
            component: () =>
                import ('@views/district-management/vehicle-operation/vehicle-operation-management'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '车辆作业路线',
            }
        },
        /**
         * 车辆作业路线-新增
         */
        {
            name: 'Add-vehicle-operation',
            path: '/district-management/vehicle-operation/add-vehicle-operation',
            component: () =>
                import ('@views/district-management/vehicle-operation/add-vehicle-operation'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '新增车辆作业路线',
            }
        },
        /**
         * 车辆作业路线-编辑
         */
        {
            name: 'Edit-vehicle-operation',
            path: '/district-management/vehicle-operation/edit-vehicle-operation',
            component: () =>
                import ('@views/district-management/vehicle-operation/edit-vehicle-operation'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '编辑车辆作业路线',
            }
        },
        /**
         * 车辆作业路线-详情
         */
        {
            name: 'Details-vehicle-operation',
            path: '/district-management/vehicle-operation/details-vehicle-operation',
            component: () =>
                import ('@views/district-management/vehicle-operation/details-vehicle-operation'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '车辆作业路线详情',
            }
        },
        /**
         * 人员作业网格
         */
        {
            name: 'personnel-work-list',
            path: '/district-management/personnel-work/list',
            component: () =>
                import ('@views/district-management/personnel-work/list'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '人员作业网格',
            }
        },
        /**
         * 人员作业网格 - 新增
         */
        {
            name: 'personnel-work-add',
            path: '/district-management/personnel-work/add',
            component: () =>
                import ('@views/district-management/personnel-work/add'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '新增人员作业网格',
            }
        },
        /**
         * 人员作业网格 - 编辑
         */
        {
            name: 'personnel-work-edit',
            path: '/district-management/personnel-work/edit',
            component: () =>
                import ('@views/district-management/personnel-work/edit'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '编辑人员网格作业',
            }
        },

        /**
         * 人员作业网格 - 详情
         */
        {
            name: 'personnel-work-info',
            path: '/district-management/personnel-work/info',
            component: () =>
                import ('@views/district-management/personnel-work/info'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '人员作业网格业详情',
            }
        },
        /**
         * 排班管理-list
         */
        {
            name: 'scheduling-list',
            path: '/district-management/scheduling/list',
            component: () =>
                import ('@views/district-management/scheduling/list'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '排班管理',
            }
        },
        /**
         * 排班管理-人员排班分配
         */
        {
            name: 'personnel-scheduling',
            path: '/district-management/scheduling/personnel-scheduling',
            component: () =>
                import ('@views/district-management/scheduling/personnel-scheduling'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '人员排班分配',
            }
        },
        /**
         * 排班管理-复制排班人员
         */
        {
            name: 'copy-scheduling',
            path: '/district-management/scheduling/copy-scheduling',
            component: () =>
                import ('@views/district-management/scheduling/copy-scheduling'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '复制排班人员',
            }
        },
        /**
         * 排班管理-垃圾清扫车车辆排班-排班车辆list
         */
        {
            name: 'Vehicle-scheduling',
            path: '/district-management/scheduling/vehicle-list',
            component: () =>
                import ('@views/district-management/scheduling/vehicle-list'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '排班车辆列表',
            }
        },
        /**
         * 排班管理-垃圾清扫车车辆排班-排班司机list
         */
        {
            name: 'Driver-scheduling',
            path: '/district-management/scheduling/driver-list',
            component: () =>
                import ('@views/district-management/scheduling/driver-list'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '排班司机列表',
            }
        },
        /**
         * 标段审核记录
         */
        {
            name: 'Audit-records',
            path: '/district-management/audit-records/audit-records',
            component: () =>
                import ('@views/district-management/audit-records/audit-records'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '标段审核记录',
            }
        },
        /**
         * 标段审核
         */
        {
            name: 'Block-audit-management',
            path: '/district-management/block-audit/block-audit-management',
            component: () =>
                import ('@views/district-management/block-audit/block-audit-management'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '标段审核',
            }
        },
        /**
         * 标段审核-标段审核详情
         */
        {
            name: 'Block-audit-details',
            path: '/district-management/block-audit/block-audit-details',
            component: () =>
                import ('@views/district-management/block-audit/block-audit-details'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '标段审核详情',
            }
        },
        /**
         * 标段审核记录-标段审核记录详情
         */
        {
            name: 'Audit-records-details',
            path: '/district-management/audit-records/audit-records-details',
            component: () =>
                import ('@views/district-management/audit-records/audit-records-details'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '标段审核记录详情',
            }
        },
        /**
         * 工资管理
         */
        {
            name: 'Salary-management',
            path: '/district-management/salary-management/salary-management',
            component: () =>
                import ('@views/district-management/salary-management/salary-management'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '工资管理',
            }
        },
        /**
         * 工资管理 - 发放工资
         */
        {
            name: 'Add-salary',
            path: '/district-management/salary-management/add-salary',
            component: () =>
                import ('@views/district-management/salary-management/add-salary'),
            meta: {
                title: '发放工资',
            }
        },
        /**
         * 工资管理 - 编辑发放工资
         */
        {
            name: 'Edit-pay-salaries',
            path: '/district-management/salary-management/edit-pay-salaries',
            component: () =>
                import ('@views/district-management/salary-management/edit-pay-salaries'),
            meta: {
                title: '编辑发放工资',
            }
        },
         /**
         * 工资管理 - 发放工资详情
         */
        {
            name: 'Details-salary',
            path: '/district-management/salary-management/details-salary',
            component: () =>
                import ('@views/district-management/salary-management/details-salary'),
            meta: {
                title: '工资详情',
            }
        },
        /**
         * 申诉管理
         */
        {
            name: 'Complaint-management',
            path: '/district-management/complaint-management/complaint-management',
            component: () =>
                import ('@views/district-management/complaint-management/complaint-management'),
            meta: {
                keepAlive: true,
                isBack: false,
                title: '申诉管理',
            }
        },
    ]
}