import Layout from '@views/Layout.vue'

export default {
    path: '/operation-safety-management',
    component: Layout,
    children: [{ //作业规则管理
        name: 'Operating-rules',
        path: '/operation-safety-management/operating-rules/operating-rules',
        component: () =>
            import ('@views/operation-safety-management/operating-rules/operating-rules'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '作业规则管理',
        }
    },{ //作业规则管理-新增
        name: 'Add-operating-rules',
        path: '/operation-safety-management/operating-rules/add',
        component: () =>
            import ('@views/operation-safety-management/operating-rules/add'),
        meta: {
            title: '新增作业规则',
        }
    },{ //作业规则管理-编辑
        name: 'Edit-operating-rules',
        path: '/operation-safety-management/operating-rules/edit',
        component: () =>
            import ('@views/operation-safety-management/operating-rules/edit'),
        meta: {
            title: '编辑作业规则',
        }
    },{ //作业规则管理-分配规则
        name: 'Allocation-rules',
        path: '/operation-safety-management/operating-rules/allocation-rules',
        component: () =>
            import ('@views/operation-safety-management/operating-rules/allocation-rules'),
        meta: {
            title: '分配规则',
        }
    },{ //作业预警管理
        name: 'Operational-early-warning-management',
        path: '/operation-safety-management/operational-early-warning-management/list',
        component: () =>
            import ('@views/operation-safety-management/operational-early-warning-management/list'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '作业预警管理',
        }
    },{ //作业预警管理 - 新增
        name: 'Add-operational-early-warning',
        path: '/operation-safety-management/operational-early-warning-management/add',
        component: () =>
            import ('@views/operation-safety-management/operational-early-warning-management/add'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '新增作业预警规则',
        }
    },
    { //作业预警管理 - 编辑
        name: 'Edit-operational-early-warning',
        path: '/operation-safety-management/operational-early-warning-management/edit',
        component: () =>
            import ('@views/operation-safety-management/operational-early-warning-management/edit'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '新增作业预警规则',
        }
    },
    { //人员健康管理
        name: 'Personnel-health-management',
        path: '/operation-safety-management/personnel-health-management/personnel-health-management',
        component: () =>
            import ('@views/operation-safety-management/personnel-health-management/personnel-health-management'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '人员健康管理',
        }
    },{ //人员健康管理 - 明细
        name: 'Personnel-health-detail',
        path: '/operation-safety-management/personnel-health-management/personnel-health-detail',
        component: () =>
            import ('@views/operation-safety-management/personnel-health-management/personnel-health-detail'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '人员健康明细',
        }
    },{ //安全培训记录
        name: 'Safety-training-record',
        path: '/operation-safety-management/safety-training-record/list',
        component: () =>
            import ('@views/operation-safety-management/safety-training-record/list'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '安全培训记录',
        }
    },{ //安全培训记录 - 新增
        name: 'Add-safety-training-record',
        path: '/operation-safety-management/safety-training-record/add',
        component: () =>
            import ('@views/operation-safety-management/safety-training-record/add'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '新增安全培训记录',
        }
    },{ //安全培训记录 - 编辑
        name: 'Edit-safety-training-record',
        path: '/operation-safety-management/safety-training-record/edit',
        component: () =>
            import ('@views/operation-safety-management/safety-training-record/edit'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '编辑安全培训记录',
        }
    },{ //安全培训记录 - 详情
        name: 'Info-safety-training-record',
        path: '/operation-safety-management/safety-training-record/info',
        component: () =>
            import ('@views/operation-safety-management/safety-training-record/info'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '安全培训记录详情',
        }
    },{ //企业安全自查
        name: 'Safety-check',
        path: '/operation-safety-management/safety-check/safety-check',
        component: () =>
            import ('@views/operation-safety-management/safety-check/safety-check'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '企业安全自查',
        }
    },{ //企业安全自查 - 新增
        name: 'Add-safety-check',
        path: '/operation-safety-management/safety-check/add-safety-check',
        component: () =>
            import ('@views/operation-safety-management/safety-check/add-safety-check'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '新增企业安全自查',
        }
    },{ //企业安全自查 - 编辑
        name: 'Edit-safety-check',
        path: '/operation-safety-management/safety-check/edit-safety-check',
        component: () =>
            import ('@views/operation-safety-management/safety-check/edit-safety-check'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '编辑企业安全自查',
        }
    },{ //企业安全自查 - 详情
        name: 'Info-safety-check',
        path: '/operation-safety-management/safety-check/info-safety-check',
        component: () =>
            import ('@views/operation-safety-management/safety-check/info-safety-check'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '企业安全自查详情',
        }
    },{ //应急管理
        name: 'Emergency-management',
        path: '/operation-safety-management/emergency-management/emergency-management',
        component: () =>
            import ('@views/operation-safety-management/emergency-management/emergency-management'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '应急管理',
        }
    },{ //应急管理-新增应急预案
        name: 'Add-emergency-response',
        path: '/operation-safety-management/emergency-management/add-emergency-response',
        component: () =>
            import ('@views/operation-safety-management/emergency-management/add-emergency-response'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '新增应急预案',
        }
    },{ //应急管理-编辑应急预案
        name: 'Edit-emergency-response',
        path: '/operation-safety-management/emergency-management/edit-emergency-response',
        component: () =>
            import ('@views/operation-safety-management/emergency-management/edit-emergency-response'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '编辑应急预案',
        }
    },{ //应急管理-应急预案详情
        name: 'Info-emergency-response',
        path: '/operation-safety-management/emergency-management/info-emergency-response',
        component: () =>
            import ('@views/operation-safety-management/emergency-management/info-emergency-response'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '应急预案详情',
        }
    },{ //应急管理-新增应急物资管理
        name: 'Add-emergency-supplies',
        path: '/operation-safety-management/emergency-management/add-emergency-supplies',
        component: () =>
            import ('@views/operation-safety-management/emergency-management/add-emergency-supplies'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '新增应急物资管理',
        }
    },{ //应急管理-编辑应急物资管理
        name: 'Edit-emergency-supplies',
        path: '/operation-safety-management/emergency-management/edit-emergency-supplies',
        component: () =>
            import ('@views/operation-safety-management/emergency-management/edit-emergency-supplies'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '编辑应急物资管理',
        }
    },{ //应急管理-应急物资管理详情
        name: 'Info-emergency-supplies',
        path: '/operation-safety-management/emergency-management/info-emergency-supplies',
        component: () =>
            import ('@views/operation-safety-management/emergency-management/info-emergency-supplies'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '应急物资管理详情',
        }
    },{ //应急管理-新增应急演练
        name: 'Add-emergency-drill',
        path: '/operation-safety-management/emergency-management/add-emergency-drill',
        component: () =>
            import ('@views/operation-safety-management/emergency-management/add-emergency-drill'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '新增应急演练',
        }
    },{ //应急管理-编辑应急演练
        name: 'Edit-emergency-drill',
        path: '/operation-safety-management/emergency-management/edit-emergency-drill',
        component: () =>
            import ('@views/operation-safety-management/emergency-management/edit-emergency-drill'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '编辑应急演练',
        }
    },{ //应急管理-应急演练详情
        name: 'Info-emergency-drill',
        path: '/operation-safety-management/emergency-management/info-emergency-drill',
        component: () =>
            import ('@views/operation-safety-management/emergency-management/info-emergency-drill'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '应急演练详情',
        }
    },{ //应急管理-新增应急处理记录
        name: 'Add-management',
        path: '/operation-safety-management/emergency-management/add-emergency',
        component: () =>
            import ('@views/operation-safety-management/emergency-management/add-emergency'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '新增应急处理记录',
        }
    },{ //应急管理-编辑应急处理记录
        name: 'Edit-management',
        path: '/operation-safety-management/emergency-management/edit-emergency',
        component: () =>
            import ('@views/operation-safety-management/emergency-management/edit-emergency'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '编辑应急处理记录',
        }
    },{ //应急管理-应急处理记录详情
        name: 'Info-management',
        path: '/operation-safety-management/emergency-management/info-emergency',
        component: () =>
            import ('@views/operation-safety-management/emergency-management/info-emergency'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '应急处理记录编辑',
        }
    },{ //安全事故记录
        name: 'Safety-incident-record',
        path: '/operation-safety-management/safety-incident-record/safety-incident-record',
        component: () =>
            import ('@views/operation-safety-management/safety-incident-record/safety-incident-record'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '安全事故记录',
        }
    },{ //安全事故记录-新增
        name: 'Add-safety-incident',
        path: '/operation-safety-management/safety-incident-record/add-safety-incident',
        component: () =>
            import ('@views/operation-safety-management/safety-incident-record/add-safety-incident'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '新增安全事故记录',
        }
    },{ //安全事故记录-处置
        name: 'Disposal-safety-incident',
        path: '/operation-safety-management/safety-incident-record/disposal-safety-incident',
        component: () =>
            import ('@views/operation-safety-management/safety-incident-record/disposal-safety-incident'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '处置安全事故记录',
        }
    },{ //安全事故记录-详情
        name: 'Info-safety-incident',
        path: '/operation-safety-management/safety-incident-record/info-safety-incident',
        component: () =>
            import ('@views/operation-safety-management/safety-incident-record/info-safety-incident'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '安全事故记录详情',
        }
    },{ //安全物资管理
        name: 'Safety-supplies-management',
        path: '/operation-safety-management/safety-supplies-management/safety-supplies-management',
        component: () =>
            import ('@views/operation-safety-management/safety-supplies-management/safety-supplies-management'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '安全物资管理',
        }
    },{ //安全物资管理 - 新增
        name: 'Add-safety-supplies',
        path: '/operation-safety-management/safety-supplies-management/add-safety-supplies',
        component: () =>
            import ('@views/operation-safety-management/safety-supplies-management/add-safety-supplies'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '新增安全物资管理',
        }
    },{ //安全物资管理 - 编辑
        name: 'Edit-safety-supplies',
        path: '/operation-safety-management/safety-supplies-management/edit-safety-supplies',
        component: () =>
            import ('@views/operation-safety-management/safety-supplies-management/edit-safety-supplies'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '编辑安全物资管理',
        }
    },{ //安全物资管理 - 详情
        name: 'Info-safety-supplies',
        path: '/operation-safety-management/safety-supplies-management/info-safety-supplies',
        component: () =>
            import ('@views/operation-safety-management/safety-supplies-management/info-safety-supplies'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '安全物资管理详情',
        }
    },{ //安全培训审核
        name: 'Safety-training-audit',
        path: '/operation-safety-management/safety-training-audit/safety-training-audit',
        component: () =>
            import ('@views/operation-safety-management/safety-training-audit/safety-training-audit'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '安全培训审核',
        }
    },{ //应急预案审核
        name: 'Contingency-plan-audit',
        path: '/operation-safety-management/contingency-plan-audit/contingency-plan-audit',
        component: () =>
            import ('@views/operation-safety-management/contingency-plan-audit/contingency-plan-audit'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '应急预案审核',
        }
    },{ //应急物资审核
        name: 'Emergency-supplies-audit',
        path: '/operation-safety-management/emergency-supplies-audit/emergency-supplies-audit',
        component: () =>
            import ('@views/operation-safety-management/emergency-supplies-audit/emergency-supplies-audit'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '应急物资审核',
        }
    },{ //应急演练审核
        name: 'Emergency-drill-audit',
        path: '/operation-safety-management/emergency-drill-audit/emergency-drill-audit',
        component: () =>
            import ('@views/operation-safety-management/emergency-drill-audit/emergency-drill-audit'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '应急演练审核',
        }
    },{ //企业安全自查审核
        name: 'Safety-check-audit',
        path: '/operation-safety-management/safety-check-audit/safety-check-audit',
        component: () =>
            import ('@views/operation-safety-management/safety-check-audit/safety-check-audit'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '企业安全自查审核',
        }
    },
    { //应急处置审核 
        name: 'Emergency-response-audit',
        path: '/operation-safety-management/emergency-response-audit/emergency-response-audit',
        component: () =>
            import ('@views/operation-safety-management/emergency-response-audit/emergency-response-audit'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '应急处置审核',
        }
    },{ //安全事故审核 
        name: 'Safety-accidents-audit',
        path: '/operation-safety-management/safety-accidents-audit/safety-accidents-audit',
        component: () =>
            import ('@views/operation-safety-management/safety-accidents-audit/safety-accidents-audit'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '安全事故审核',
        }
    },{ //安全物资审核 
        name: 'Safety-supplies-audit',
        path: '/operation-safety-management/safety-supplies-audit/safety-supplies-audit',
        component: () =>
            import ('@views/operation-safety-management/safety-supplies-audit/safety-supplies-audit'),
        meta: {
            keepAlive: true,
            isBack: false,
            title: '安全物资审核',
        }
    },]
}