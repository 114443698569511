<template>
  <video
    id="player"
    height="300"
    width="600"
    class="video-js vjs-big-play-centered"
    controls
    autoplay
    loop
    ish265
    islive
    hasvideo
    hasaudio
    lib="/static"
  >
    <source src="http://alarm-attachment.oss-cn-shanghai.aliyuncs.com/1111222200010020051915403_DSM_1_6504.ts?Expires=1599554226&OSSAccessKeyId=LTAIOJS1fJVz6FGc&Signature=zVzS%2Fae9YAs2L%2FyzVOX7a7O7pAE%3D" type="video/x-flv" />
  </video>
</template>
<script>
import videojs from 'video.js';
import 'videojsflvh265car';
/**
 * 注意，这只是demo，演示播放器的使用方法，不可应用到业务中
 */
export default {
  data () {
      return {
        player: null,
        shortName: null,
        // loading: true,
      }
    },
  mounted() {
    this.player = videojs("player", {
      techOrder: ["html5", "flvh265"],
      controlBar: {
        pictureInPictureToggle: false, //we don't support pictureInPicture now
      },
    });
    this.player.on("error",function(){
          console.log(this.error());
        });

        this.player.on("loadstart",function(){
          console.log("loadstart");
          let that =this
          // that.loading = true
          console.time()
          
        });
        this.player.on("loadedmetadata",function(){
          console.log("loadedmetadata");
          // this.loading = false
          console.timeEnd()
        });
  },
};
</script>
<style>
</style>