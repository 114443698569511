<template>
  <div class="content-main-right" v-bind="$attrs" v-on="$listeners">
    <div style="height: 66px; display: inline;" v-if="!mapData.isShow">
      <div
        class="el-input el-input--mini el-input--suffix"
        style="padding: 16px 21px 16px 16px; width: 320px; height: 66px;float: left;"
      >
        <input
          id="tipinput"
          type="text"
          autocomplete="off"
          class="el-input__inner"
          placeholder="请输入地址"
          maxlength="40"
          style="height: 32px;"
          v-model="searchText"
        />
      </div>
      <el-button
        style="float: left; margin-top: 16px;"
        type="success"
        class="btn-success-hw"
        @click="onSearch"
      >查询</el-button>
      <el-button
        v-if="enableDraw"
        style="float: left; margin-left: 21px; margin-top: 16px;"
        class="btn-hw"
        @click="onClear"
      >清除所有覆盖物</el-button>
      <div class="tips" v-if="enableDraw">提示：点地图设置标注,右键单击标注删除。</div>
      <div v-if="debug">
        mapData:{{mapData}}
        <hr />
        getScopeData:{{getScopeData}}
      </div>
    </div>
    <div id="container" style="width: 100%; height: 711px;"></div>
    <div id="panel"></div>
    <div id="buttons"></div>
    <slot></slot>
  </div>
</template>

<script>
import bz_ljsjd from "@/assets/images/hw-icon/bz_ljsjd.png"
import bz_ljzzz from "@/assets/images/hw-icon/bz_ljzzz.png"
import bz_gc from "@/assets/images/hw-icon/bz_gc.png"
import bz_ljhbw from "@/assets/images/hw-icon/bz_ljhbw.png"

/**
 * 本地图组件主要信息含：
 * 1个标注点，
 * 1个围栏范围。
 */

export default {
  name: "ayl-map",

  inheritAttrs: false,

  props: {
    //地图数据
    value: {
      type: Object
      // default:{
      //   lnglat: null,      //标注点的经纬度，数组，形如[118,36]
      //   scope: null,       //围栏范围，经纬度点阵数组，形如[[118,36],[117,32],[116,113]]
      //   move2center:true,  //是否居中显示
      // }
    },
    strokeColor: {
      //多边形线颜色
      type: String,
      default: "#ff0000"
    },
    fillColor: {
      //多边形填充色
      type: String,
      default: "#42B983"
    },
    icon:{
      type:String,
      default:""
    },
    //是否允许绘制
    enableDraw: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      debug: 0,
      mapData: this.value,
      searchText: "",
      map: null, //高德插件
      auto: null, //高德插件
      placeSearch: null, //高德插件
      polygon: null, //高德多边形
      icons:{"ljsjd":bz_ljsjd,"ljzzz":bz_ljzzz,"gc":bz_gc,"ljhbw":bz_ljhbw},
    };
  },

  methods: {
    initAmap() {
      let self = this;
      // eslint-disable-next-line no-undef
      self.map = new AMap.Map("container", {
        resizeEnable: true,
        zoom: 11
      });
      self.map.plugin(
        [
          "AMap.ToolBar",
          "AMap.Autocomplete",
          "AMap.PlaceSearch"
          // "AMap.PolyEditor",
        ],
        function() {
          self.toolBar = new AMap.ToolBar({
            position: "RT",
            ruler: false,
            locate: true
          });
          self.map.addControl(self.toolBar);

          self.auto = new AMap.Autocomplete({
            input: "tipinput"
          });

          self.placeSearch = new AMap.PlaceSearch({
            pageSize: 5, // 单页显示结果条数
            pageIndex: 1, // 页码
            // city: "010", // 兴趣点城市
            citylimit: true, //是否强制限制在设置的城市内搜索
            map: self.map, // 展现结果的地图实例
            panel: "panel", // 结果列表将在此容器中进行展示。
            autoFitView: true // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
          });

          AMap.event.addListener(self.auto, "complete", function(ev) {
            document.querySelector("#panel").innerHTML = "";
          });
          AMap.event.addListener(self.auto, "select", function(ev) {
            self.placeSearch.search(ev.poi.name); //关键字查询查询
          });
          AMap.event.addListener(self.placeSearch, "selectChanged", function(
            ev
          ) {
            let lnglat = ev.selected.marker.getPosition();
            self.mapData.lnglat = [lnglat.lng, lnglat.lat];
            // console.log(lnglat.lng,lnglat.lat);
          });
          AMap.event.addListener(self.placeSearch, "markerClick", function(ev) {
            let lnglat = ev.marker.getPosition();
            self.mapData.lnglat = [lnglat.lng, lnglat.lat];
          });
        }
      );

      //鼠标点击添加点标记
      self.map.off("click").on("click", function(ev) {
        if(!self.enableDraw) return;
        // 触发事件的地理坐标，AMap.LngLat 类型
        var lnglat = ev.lnglat;
        self.log([lnglat.lng, lnglat.lat]);
        self.mapData.lnglat = [lnglat.lng, lnglat.lat];
      });

      self.mapData.move2center = true;
    },

    onSearch() {
      if (this.searchText && this.searchText.trim()) {
        this.placeSearch.search(this.searchText.trim());
      }
    },
    onClear() {
      let self = this;
      self.mapData.lnglat = null;
      self.mapData.scope = null;
      self.map.clearMap();
      document.querySelector("#panel").innerHTML = "";
    }
  },

  watch: {
    //界面渲染逻辑必须统一放在这里
    value: {
      deep: true,
      handler: function(v, ov) {
        let self = this;

        //因为self.mapData是个引用类型，所以不用强制赋值。
        //但是似乎不符合vue的规范。
        // self.mapData = v;
        
        self.$nextTick();//因为地图可能不会刷新，所以需要执行$nextTick()
        

        self.log("---------ayl-map watch mapData change---------", v.lnglat);


        //根据经纬度绘制标记点
        if (self.mapData) {
          let lnglat = self.mapData.lnglat;
          if (self.marker) {
            self.map.remove(self.marker);
            self.marker = null;
          }
          if (lnglat && lnglat.length >= 2) {
            self.marker = new AMap.Marker({
              position: new AMap.LngLat(lnglat[0], lnglat[1]),
              icon:self.icons[self.icon],
              anchor:self.icons[self.icon]?"center":"bottom-center",
              offset: new AMap.Pixel(0,0),
              title: ""
            });
            self.marker.on("rightclick", function() {
              if(self.enableDraw)
                self.mapData.lnglat = null;
            });
            self.map.add(self.marker);
          }
        }

        //回显绘制围栏范围（多边形）
        if (self.polygon) {
          self.polygon.setMap(null);
          self.polygon = null;
        }
        if (self.mapData.scope) {
          self.polygon = new AMap.Polygon({
            path: self.getScopeData, //设置多边形边界路径
            strokeColor: self.mapData.strokeColor || self.strokeColor, //线颜色
            strokeWeight: 2, //线宽
            strokeOpacity: 1, //线透明度
            fillOpacity: 0.4, //填充透明度
            fillColor: self.mapData.fillColor || self.fillColor, //填充色
            bubble: true,
            zIndex: 1
          });
          self.map.add(self.polygon);
        }

        // 缩放地图到合适的视野级别
        if (self.mapData.move2center) {
          !self.mapData.scope &&
            self.marker &&
            self.map.setFitView(self.marker);
          self.mapData.scope &&
            self.polygon &&
            self.map.setFitView(self.polygon);
          self.mapData.move2center = false;
        }
      }
    }
  },

  computed: {
    getMapData: {
      get() {
        return this.mapData;
      }
    },
    getScopeData: {
      get() {
        return this.$_.cloneDeep(this.mapData.scope);
      }
    }
  },

  mounted() {
    this.initAmap();
  }
};
</script>

<style scoped>
#panel {
  width: 40%;
  position: relative;
  top: -710px;
  left: 0px;
}
#buttons {
  position: relative;
  top: -710px;
  float: right;
  padding: 16px;
  margin-right: 60px;
}
.tips {
  float: right;
  width: 280px;
  padding-top: 25px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  color:red;
}
</style>
