var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left-main"},[_c('span',{staticClass:"left-title"},[_vm._v(_vm._s(_vm.customInfo.pageTitle))]),(_vm.scope === 'TMS')?_c('div',{staticClass:"left-nav"},[_c('el-menu',{attrs:{"router":"","unique-opened":"","default-active":_vm.routerPath}},_vm._l((_vm.env == 'test' || _vm.env == 'production'
          ? _vm.menus
          : _vm.menusChildren),function(item,idx){return _c('div',{key:idx},[(!item.children.length)?_c('el-menu-item',{class:{
            'menus-active':
              !_vm.$route.meta.JumpPath &&
              _vm.$route.path.split('/')[1] === item.path.split('/')[1],
          },attrs:{"index":item.path}},[_c('i',{staticClass:"iconfont",class:item.icon}),_c('span',[_vm._v(_vm._s(item.name))])]):_c('el-submenu',{attrs:{"index":idx + ''}},[_c('template',{slot:"title"},[_c('i',{staticClass:"iconfont",class:item.icon}),_c('span',[_vm._v(_vm._s(item.name))])]),_c('el-menu-item-group',_vm._l((item.children),function(child,childIdx){return _c('div',{key:childIdx},[(!child.children.length)?_c('el-menu-item',{attrs:{"index":child.path}},[_vm._v(" "+_vm._s(child.name)+" ")]):_c('el-submenu',{attrs:{"index":idx + '1'}},[_c('div',{staticStyle:{"margin-left":"32px"},attrs:{"slot":"title"},slot:"title"},[_c('span',[_vm._v(_vm._s(child.name))])]),_vm._l((child.children),function(childItem,childItemIdx){return _c('el-menu-item',{key:childItemIdx,attrs:{"index":childItem.path}},[_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(childItem.name))])])})],2)],1)}),0)],2)],1)}),0)],1):_vm._e(),(_vm.scope === 'SANITATION')?_c('div',{staticClass:"left-nav-hw"},[_c('el-menu',{attrs:{"router":"","unique-opened":"","default-active":_vm.routerPath}},_vm._l((_vm.env == 'test' || _vm.env == 'production'
          ? _vm.menus
          : _vm.menusChildrenHw),function(item,idx){return _c('div',{key:idx},[(!item.children.length)?_c('el-menu-item',{class:{
            'menus-active':
              !_vm.$route.meta.JumpPath &&
              _vm.$route.path.split('/')[1] === item.path.split('/')[1],
          },attrs:{"index":item.path}},[_c('i',{staticClass:"iconfont",class:item.icon}),_c('span',[_vm._v(_vm._s(item.name))])]):_c('el-submenu',{attrs:{"index":idx + ''}},[_c('template',{slot:"title"},[_c('i',{staticClass:"iconfont",class:item.icon}),_c('span',[_vm._v(_vm._s(item.name))])]),_c('el-menu-item-group',_vm._l((item.children),function(child,childIdx){return _c('div',{key:childIdx},[(!child.children.length)?_c('el-menu-item',{attrs:{"index":child.path}},[_vm._v(" "+_vm._s(child.name)+" ")]):_c('el-submenu',{attrs:{"index":idx + '1'}},[_c('div',{staticStyle:{"margin-left":"32px"},attrs:{"slot":"title"},slot:"title"},[_c('span',[_vm._v(_vm._s(child.name))])]),_vm._l((child.children),function(childItem,childItemIdx){return _c('el-menu-item',{key:childItemIdx,attrs:{"index":childItem.path}},[_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(childItem.name))])])})],2)],1)}),0)],2)],1)}),0)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }