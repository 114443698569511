import Vue from 'vue'
import filters from './filters'
import enums from './enums'
import enums_hw from './enums-hw'
import api from '../api/config' //物流
import api_hw from '../api/config-hw' //环卫
import _ from './lodash'
import utils from './func'
import validator from './validator'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'


Vue.prototype.$dayjs = dayjs
dayjs.locale('zh-cn')

Vue.prototype.$enums = enums
Vue.prototype.$enums_hw = enums_hw
Vue.prototype.$filters = filters
Vue.prototype.$api = api
Vue.prototype.$api_hw = api_hw
Vue.prototype.$_ = _
Vue.prototype.$utils = utils
Vue.prototype.$validator = validator

for (let key in filters) {
    Vue.filter(key, filters[key])
}

/**
 * 流表格处理
 */
Vue.prototype.$search = async(table, val) => {
    if (!sessionStorage.getItem('loginData')) return
    if (table && table.api) {
        // console.log("////////////////","api:",table.api.name,"/////// event value:",val)
        let query = _.cloneDeep(table.query) || {}
        if (!table.hidePagination) {
            // console.log(val)
            query.pageSize = 10
            query.currentPage = val || (table.pagination && table.pagination.currentPage) || 1
        } else {
            query.pageSize = 200
            query.currentPage = 1
        }
        if (query.queryContent) {
            query.queryContent = query.queryContent.replace(/\s|\t+/g, "");
        }
        table.loading = true
        try {
            let data = await table.api(query)
            if (data instanceof Array) {
                table.data = data
            } else {
                table.data = data.data
                table.other = _.omit(data, ['data', 'totalNum'])
                table.pagination && (table.pagination.totalNum = data.totalNum)
            }
        } catch (e) {
            table.loading = false
        }
        table.loading = false
    }else if(!table.api){
        throw new Error("传给表格组件的api尚未定义!");
    }
}

// cookie操作
Window.prototype.$Cookie = {
    //设置cookie
    setCookie(name, value, hours, path) {
        var name = escape(name) //进行编码
        var value = escape(value) //进行编码
        var expires = new Date()
        expires.setTime(expires.getTime() + hours * 3600000)
        path = path == '' ? '' : ';path=' + path
        var _expires = (typeof hours) == 'string' ? '' : ';expires=' + expires.toUTCString()
        document.cookie = name + '=' + value + _expires + path
    },
    //获取cookie
    getCookieValue(name) {
        var name = escape(name) //进行编码
            //读cookie属性，这将返回文档的所有cookie
        var allcookies = document.cookie
            //查找名为name的cookie的开始位置
        name += '='
        var pos = allcookies.indexOf(name)
            //如果找到了具有该名字的cookie，那么提取并使用它的值
        if (pos != -1) { //如果pos值为-1则说明搜索"version="失败
            var start = pos + name.length //cookie值开始的位置
            var end = allcookies.indexOf(';', start) //从cookie值开始的位置起搜索第一个";"的位置,即cookie值结尾的位置
            if (end == -1) end = allcookies.length //如果end值为-1说明cookie列表里只有一个cookie
            var value = allcookies.substring(start, end) //提取cookie的值
            value = unescape(value) //对它解码
            return (value)
        } else return '' //搜索失败，返回空字符串
    },
    //删除cookie
    deleteCookie(name, path) {
        var name = escape(name)
        var expires = new Date(0)
        path = path == '' ? '' : ';path=' + path
        document.cookie = name + '=' + ';expires=' + expires.toUTCString() + path
    },
    // 清空cookie
    delectAllCookie() {
        let keys = document.cookie.match(/[^ =;]+(?=\=)/g)
        if (keys) {
            for (var i = keys.length; i--;)
                document.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString()
        }
    }
}