<template>
  <div class="pie">
    <div class="pie-main" ref="pie"></div>
  </div>
</template>

<script>
  import echarts from 'echarts'
  const option = {
    color: ["#0575E6", "#0091FF", "#33ADFF", "#66C7FF","#99DFFF"],
    tooltip: {
        trigger: 'item',
        formatter: function (params) {
          return params.data.label + ':' + + params.data.score
        },
    },
    series: [{
        name: '艾依灵',
        type: 'funnel',
        left: '10%',
        top: 60,
        //x2: 80,
        bottom: 60,
        width: '80%',
        // height: {totalHeight} - y - y2,
        min: 0,
        max: 100,
        minSize: '0%',
        maxSize: '100%',
        sort: 'descending',
        gap: 2,
        label: {
          show: true,
          position: 'inside',
          formatter: function (params) {
            return params.data.label
          },
        },
        itemStyle: {
            normal: {
                borderColor: '#fff',
                borderWidth: 1
            },
            color: '#000000'
        },
        data: [],
    }]
};
  export default {
    props: {
      driveData: {
        default: function () {
          return []
        },
        type: Array
      }
    },
    data () {
      return {
        timer: null,
        total: 0,
        labelList: []
      }
    },
    watch: {
      "driveData": function(){
        this.drawFunnel()
      }
    },
    methods: {
      drawFunnel (){
        let pieOption = option
        let pie = echarts.init(this.$refs.pie)
        pieOption.series[0].data = this.driveData
        pie.setOption(pieOption)
        window.addEventListener('resize', function () {
          pie.resize()
        })
      }
    },
    mounted () {
      this.drawFunnel()
    }
  }
</script>

<style lang='sass' scoped>
.pie
  width: 100%
  height: 100%
  position: relative
  &-main
    width: 100%
    height: 100%
</style>
