/* eslint-disable no-console */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// UI 组件
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/sass/element-variables.scss';
Vue.use(ElementUI, {
  size: 'mini',
  zIndex: 3000
});

// 格式化浏览器样式
import 'normalize.css'
// 公共SASS文件
import './assets/sass/main.sass'

//引入全局配置文件
import config from './config'
Vue.prototype.$config = config //全局原型污染，但是因为历史依赖，本版本已经没办法再解耦，留待下版本解耦

import './utils/global'
import './api/global'
import './components/global/global'

//js-cookie
import Cookies from 'js-cookie'
Vue.prototype.$Cookies = Cookies

//图片预览
import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'
import 'vue-directive-image-previewer/dist/assets/style.css'
Vue.use(VueDirectiveImagePreviewer, {
  zIndex: 99999
})


import VideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css' //样式
//import 'vue-video-player/node_modules/video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css' //样式
import 'videojs-flash'
Vue.use(VideoPlayer)

// 全局混入
import mixins_global from './mixins/global'
Vue.mixin(mixins_global)


Vue.config.productionTip = process.env.NODE_ENV !== "production"
Vue.config.silent = process.env.NODE_ENV === "production"

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');