<template>
  <div>
    <!-- {{value}} -->
    <div class="avatar" v-if="!value">
      <label for="cover_three" class="upload-label">
        <input
          type="file"
          hidden
          @change="onUploadFiles($event)"
          accept="image/png, image/jpeg"
          ref="uploadCoverOne"
          id="cover_three"
        />
        <div class="avatar-item-license-icon" v-if="!value">
          <i class="avatar-icon iconfont iconshangchuan"></i>
          <p class="avatar-text">点击上传</p>
        </div>
      </label>
    </div>
    <!--图片-->
    <div class="order-img avatar" style="display: inline-block;vertical-align: top;" v-else>
      <el-image
        :src="!localImageUrl?``:localImageUrl"
        v-image-preview
        fit="cover"
        style="width: 100%;height: 100%;border-radius: 4px"
      ></el-image>
      <i class="el-icon-error close-icon" v-if="value" @click="onClearUpload"></i>
    </div>
    <div style="display: inline-block;">
      <span class="tips">提示：上传的图片需要是.JPG .PNG等图像文件格式</span>
    </div>
  </div>
</template>
<script>
/**
 * 【注意！！】本组件只支持只支持单文件上传，
 * 若需要上传多个文件，请使用“ayl-upload-files（待封装）”
 */
export default {
  name: "ayl-upload-single-image",
  props: {
    value: {
      type: String
    },
    maxSize:{
      type:Number,
      default:10*1024
    }
  },
  data() {
    return {
      isEditMode: false,
      imageUrl: this.value,
      localImageUrl: null
    };
  },
  methods: {
    async onUploadFiles(ev) {
      let self = this;
      if (ev.target.files.length === 0) return;
      if (ev.target.files[0].size > 1024 * self.maxSize) {
        self.$message.warning(`上传的图片大小不能超过 ${self.maxSize} kb`);
        return;
      }
      let file = ev.target.files[0];
      let formData = new FormData();
      formData.append("file", file);
      formData.append(
        "ailivingToken",
        JSON.parse(sessionStorage.getItem("loginData")).Token
      );
      try {
        self.imageUrl = await self.$api.UploadImg(formData); //放一个由阿里云OSS生成的filekey就行
        self.localImageUrl = self.$utils.getObjectURL(file); //获取本地图片地址
      } catch (e) {
        self.$message.warning("上传图片失败：" + e);
      }

      // 配合v-mode语法糖，定义input事件
      self.$emit("input", self.imageUrl);

      // 不清空input的值，下次选取不到相同的照片
      let oFileInput = document.getElementById("cover_three");
      oFileInput.value = null;
    },
    //  清除环保垃圾屋附件
    onClearUpload() {
      this.imageUrl = null;
      this.localImageUrl = null;
      // 配合v-mode语法糖，定义input事件
      this.$emit("input", this.imageUrl);
    }
  },
  watch: {
    value: function(v, ov) {
      // console.log(
      //   "---------ayl-upload-single-image watch value change---------",
      //   v,
      //   ov
      // );
      let self = this;
      self.isEditMode = !!self.value; //判断是否编辑模式
      (async function() {
        if (self.isEditMode) {
          let queryData = {
            ailivingToken: JSON.parse(sessionStorage.getItem("loginData"))
              .Token,
            fileKey: self.value
          };
          self.localImageUrl = await self.$api_hw.common_getFileUrlFromOSS(queryData);
          // console.log(self.localImageUrl); //获取网络图片地址
        }
      })().catch(function(e) {
        console.error(e);
      });
    }
  },
  computed: {},
  mounted() {
    // let self = this;
    // console.log("---------ayl-upload-single-image mounted---------", self.value);
  },
  updated() {
    // let self = this;
    // console.log("---------ayl-upload-single-image updated---------localImageUrl:", self.localImageUrl);
    // self.$nextTick();
  },
  activated() {
    let self = this;
    // console.log("---------ayl-upload-single-image activated---------", self.value);
    self.isEditMode = !!self.value; //判断是否编辑模式
    (async function() {
      if (self.isEditMode) {
        let queryData = {
          ailivingToken: JSON.parse(sessionStorage.getItem("loginData")).Token,
          fileKey: self.value
        };
        self.localImageUrl = await self.$api_hw.common_getFileUrlFromOSS(
          queryData
        ); //获取网络图片地址
      }
    })().catch(function(e) {
      console.error(e);
    });
  }
};
</script>
<style lang='sass' scoped>
.avatar-uploader .el-upload
  border: 1px dashed #d9d9d9
  border-radius: 6px
  cursor: pointer
  position: relative
  overflow: hidden
  vertical-align: top

/deep/ .el-upload
  vertical-align: top

.avatar-uploader .el-upload:hover
  border-color: #409EFF

.avatar-uploader-icon
  font-size: 28px
  color: #8c939d
  width: 300px
  height: 150px
  line-height: 150px
  text-align: center

.avatar
  display: inline-block
  vertical-align: top
  width: 140px
  height: 140px
  border: 1px dashed rgba(217,217,217,1)
  border-radius: 4px
  position: relative
  z-index: 0

.avatar-item
  height: 70px
  position: absolute
  z-index: 1
  top: 38px
  left: 102px

.avatar-item-license
  height: 74px
  position: absolute
  z-index: 1
  top: 48px
  left: 114px

.avatar-item-license-icon
  height: 74px
  position: absolute
  z-index: 1
  top: 0
  left: 0
  right: 0
  bottom: 0
  margin: auto

.avatar-icon
  width: 48px
  height: 38px
  font-size: 48px
  display: block
  margin: 0 auto
  color: #bfbfbf

.avatar-text
  text-align: center
  font-size: 12px
  font-family: Microsoft YaHei
  font-weight: 400
  color: rgba(191,191,191,1)

.avatar-img
  width: 300px
  height: 150px

.tips
  width: 407px
  height: 22px
  margin-left: 10px
  font-size: 12px
  font-family: Microsoft YaHei
  font-weight: 400
  color: rgba(255,140,26,1)
  line-height: 32px
</style>