<template>
  <div class="layout">
    <ayl-left-nav/>
    <div class="app-main">
      <ayl-header/>
      <transition
              mode="out-in"
              name="el-fade-in-linear"
      >
        <keep-alive max="2">
          <router-view class="layout-content" v-if="$route.meta.keepAlive"/>
        </keep-alive>
      </transition>
      <transition
              mode="out-in"
              name="el-fade-in-linear"
      >
        <router-view class="layout-content" v-if="!$route.meta.keepAlive"/>
      </transition>
    </div>
    <!--<div class="app-main">-->
    <!--<div class="app-view" :class="{'expandMa': $store.state.menu.isExpand}">-->
    <!--<transition mode="out-in" name="el-fade-in-linear">-->
    <!--<router-view/>-->
    <!--</transition>-->
    <!--</div>-->
    <!--</div>-->
  </div>
</template>

<script>
  export default {
    data () {
      return {
        userID:sessionStorage.getItem('userIdentifier'),
      }
    },
    watch: {},
    methods: {},
    mounted () {
      //console.log('alex:' + this.userID)
    },
    computed: {}
  }
</script>

<style lang='sass' scoped>
  .layout
    width: 100%
    height: 100vh

    .app-main
      width: calc(100% - #{$sidebarWidth})
      display: inline-block
      height: 100%
      vertical-align: top

      .layout-content
        width: 100%
        height: calc(100% - #{$sidebarHeaderHeight})
        background-color: $-background-color-1
</style>
