/**
 * 将/components/global里面的组件设为全局组件
 */
import Vue from 'vue'
import $lodash from '../../utils/lodash'
import { BUS, BUSEVENT } from "@/components/global/bus.js";

(function (win) {
  win["BUS"] = BUS;
  win["BUSEVENT"] = BUSEVENT;

  //需要懒加载的组件
  // const lazyLoadComponents = ["AylDialog", "AylImage", "AylMap","AylMapPolygon", "AylUploadSingleImage", "AylUploadFiles","AylSearchBoxWithQueryObject","AylImgUpload","AylNewTable"];
  const lazyLoadComponents = [];
  const requireComponent = require.context(
    // 其组件目录的相对路径
    './',
    // 是否查询其子目录
    false,
    // 匹配基础组件文件名的正则表达式
    /(?<!(global|ayl-new-table)).vue$/
  )
  // let _sd = new Date().getTime();
  requireComponent.keys().forEach(fileName => {
    // 获取组件
    let componentConfig = requireComponent(fileName)
    // 获取组件的 PascalCase 命名
    let componentName = $lodash.upperFirst(
      $lodash.camelCase(
        // 剥去文件名开头的 `'./` 和结尾的扩展名
        fileName.replace(/^\.\/(.*)\.\w+$/, '$1')
      )
    );    
    // 全局注册组件
    if (lazyLoadComponents.includes(componentName)) {
      Vue.component(
        componentName,
        ()=>import(`${fileName}`) //按需异步加载
      );
    } else {
      // console.log(componentName);
      Vue.component(
        componentName,
        // 如果这个组件选项是通过 `export default` 导出的，
        // 那么就会优先使用 `.default`，
        // 否则回退到使用模块的根。
        componentConfig.default || componentConfig
      );
    }
  })

  // let _ed = new Date().getTime();
  // console.log("耗费时间:" + (_ed - _sd))
})(window);