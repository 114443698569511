<template>
  <div class="wrap">
    <div class="upload-img">
      <div
        class="no-ops"
        :style="{ width: imgWidth, height: imgHeight,'margin-right': imgListLength==1?'0':'10px','margin-bottom': imgListLength==1?'0':'10px'}"
        v-for="(item,index) in imgList"
        :key="index"
      >
        <div class="icon-box">
          <i class="el-icon-zoom-in" @click="bigImg(item,index)">
            <el-image class="img-btn" :src="item.url" :preview-src-list="bigImgList"></el-image>
          </i>
          <i class="el-icon-delete" v-if="isEdit" @click="deleteImg(item,index)"></i>
        </div>
        <img :src="item.url" alt />
      </div>

      <el-upload
        v-if="isEdit"
        :class="[imgList.length>=imgListLength?'avatar-uploader':'']"
        action="action"
        multiple
        :before-upload="bandleBefore"
        :file-list="imgList"
        :http-request="uploadImg"
        :style="{ width: imgWidth }"
      >
        <div class="icon-wrap" :style="{ width: imgWidth, height: imgHeight }">
          <div style="width: 100%">
            <i :class="uploadIcon"></i>
            <p style="width: 100%">{{uploadDesc}}</p>
          </div>
        </div>
      </el-upload>
    </div>
  </div>
</template>

<script>
export default {
  name: "AylImgUpload",
  props: {
    value: {
      type: Array,
      default: () => []
    },
    // 图片数组长度 默认上传一张图片
    imgListLength: {
      type: Number,
      default: 1
    },
    // 图片大小限制 单位/M 默认为5M
    imgSize: {
      type: Number,
      default: 5
    },
    // 自定宽度（支持百分比）
    imgWidth: {
      type: String,
      default: "146px"
    },
    // 自定义高度
    imgHeight: {
      type: String,
      default: "146px"
    },
    // 上传小图标说明
    uploadDesc: {
      type: String,
      default: ""
    },
    // 上传小图标
    uploadIcon: {
      type: String,
      default: "el-icon-plus"
    },
    // 是可编辑
    isEdit: {
      tyoe: Boolean,
      default: true
    }
  },
  data() {
    return {
      // 从OSS获取的key list
      imgUrls: [],
      // 用于绑定到控件 lits
      imgList: [],
      token: JSON.parse(sessionStorage.getItem("loginData")).Token,
      // 查看大图 list
      bigImgList: []
    };
  },
  watch: {
    value: {
      handler() {
        this.imgUrls = this.value;
        if (this.imgUrls.length > this.imgListLength) {
          this.$notify.error({
            title: "提示",
            message: `图片数量不能超过${this.imgListLength}张`
          });
          this.imgUrls.length = this.imgListLength;
        }
        let promise = this.imgUrls.map(async item => {
          if(item){
            return await this.$api_hw.common_getFileUrlFromOSS({
            ailivingToken: this.token,
            fileKey: item
          });
          }
        });
        Promise.all(promise).then(res => {
          this.bigImgList = res;
          this.imgList = res.map(item => {
            return { url: item };
          });
        });
      },
      deep: true
    }
  },
  methods: {
    // 上传前大小/格式限制
    bandleBefore(file) {
      let isImage = file.type.indexOf("image") != -1;
      let isLtM = file.size / 1024 / 1024 < this.imgSize;
      if (!isImage) {
        this.$message.error("上传图片格式只能为png、jpg、gif!");
      }
      if (!isLtM) {
        this.$message.error(`上传图片大小不能超过${this.imgSize}M`);
      }
      return isImage && isLtM;
    },

    // 删除照片
    deleteImg(img, index) {
      this.imgUrls.splice(index, 1);
      this.$emit("input", this.imgUrls);
    },
    // 放大图片
    bigImg(img) {
      console.log(img);
    },
    // 字定义上传（覆盖默认上传）
    async uploadImg(imgObj) {
      const file = imgObj.file;
      let imgFile = new FormData();
      imgFile.append("file", file);
      imgFile.append(
        "ailivingToken",
        JSON.parse(sessionStorage.getItem("loginData")).Token
      );
      let self = this;
      await self.$api.UploadImg(imgFile).then(res => {
        self.imgUrls.push(res);
        self.$emit("input", self.imgUrls);
      });
    }
  }
};
</script>

<style lang="sass" scoped>
.wrap
  display: block
/deep/ .avatar-uploader .el-upload 
  display: none

/deep/ .el-upload-list__item
  display: none;
  transition: none;

/deep/ .el-upload
  width: 100%

.upload-img
  display: flex
  flex-wrap: wrap
.no-ops 
  position: relative;
  width: 146px
  height: 146px
  margin-right: 10px
  margin-bottom: 10px
  overflow: hidden
  border: 1px dashed #c0ccda
  box-sizing: border-box
  border-radius: 4px
  & > img
    width: 100%
    height: 100%
  .icon-box
    display: flex;
    justify-content: center
    align-items: center
    position: absolute
    width: 100%
    height: 100%
    background-color: rgba(0,0,0,0)
    font-size: 24px;
    color: #fff;
    transition: 0.3s
    & > i
      display: none
      position: relative
      margin: 0 10px
      cursor: pointer
  .icon-box:hover
    background-color: rgba(0,0,0,0.4)
  .icon-box:hover > i
    display: block
    position: relative
  .img-btn
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
.icon-wrap
  width: 146px
  height: 146px
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap
  border: 1px dashed #c0ccda
  box-sizing: border-box
  border-radius: 4px;
  color: #c0ccda
  transition: 0.3s
  & i
    font-size: 40px;
.icon-wrap:hover
  color: #0575E6
  border-color: #0575E6
/deep/ .el-image__preview
  opacity: 0
</style>