import Layout from '@views/Layout.vue'

export default {
    path: '/accounting',
    component: Layout,
    children: [{
            path: '/accounting/customer-reconciliation',
            name: 'AccountingReconciliation',
            component: () =>
                import ('@views/accounting-reconciliation/customer-reconciliation'),
            meta: {
                keepAlive: true,
                isBack: false
            }
        },{
            // 对账单列表
            path: '/accounting/statement-account-list',
            name: 'statementAccountList',
            component: () =>
                import ('@views/accounting-reconciliation/statement-account-list'),
            meta: {
                // keepAlive: true,
                isBack: false
            }
        },{
            // 对账单列表明细
            path: '/accounting/statement-account-detail',
            name: 'statementAccountList',
            component: () =>
                import ('@views/accounting-reconciliation/statement-account-detail'),
            meta: {
                // keepAlive: true,
                isBack: false
            }
        }, {
            path: '/accounting/order-detailed',
            name: 'OrderDetailed',
            component: () =>
                import ('@views/accounting-reconciliation/order-detailed'),
            meta: {
                // keepAlive: true,
                // JumpPath: '/accounting/customer-reconciliation'
            }
        }, {
            path: '/accounting/customer-accounting',
            name: 'CustomerAccounting',
            component: () =>
              import ('@views/accounting-reconciliation/customer-accounting'),
            meta: {
                // keepAlive: true,
                // JumpPath: '/accounting/customer-reconciliation'
            }
        }, {
            path: '/accounting/customer-reconciliation-list',
            name: 'CustomerAccounting',
            component: () =>
              import ('@views/accounting-reconciliation/customer-reconciliation-list'),
            meta: {
                // keepAlive: true,
                // JumpPath: '/accounting/customer-reconciliation'
            }
        }, {
            path: '/accounting/inside-driver',
            name: 'InsideDriver',
            component: () =>
                import ('@views/accounting-reconciliation/inside-driver'),
            meta: {
                keepAlive: true,
                isBack: false
            }
        }, {
            path: '/accounting/inside-detailed',
            name: 'InsideDetailed',
            component: () =>
                import ('@views/accounting-reconciliation/inside-detailed'),
            meta: {
                // keepAlive: true,
                // JumpPath: '/accounting/inside-driver'
            }
        }, {
            path: '/accounting/inside-accounting',
            name: 'InsideAccounting',
            component: () =>
              import ('@views/accounting-reconciliation/inside-accounting'),
            meta: {
                // keepAlive: true,
                // JumpPath: '/accounting/inside-driver'
            }
        },{
            path: '/accounting/external-driver',
            name: 'ExternalDriver',
            component: () =>
                import ('@views/accounting-reconciliation/external-driver'),
            meta: {
                keepAlive: true,
                isBack: false
            }
        },{
            // 外请车对账
            path: '/accounting/outer-driver-accounting',
            name: 'ExternalDriver',
            component: () =>
                import ('@views/accounting-reconciliation/outer-driver-accounting'),
            meta: {
                keepAlive: true,
                isBack: false
            }
        },{
            // 外请车对账-列表
            path: '/accounting/outer-driver-list',
            name: 'OuterDriverList',
            component: () =>
                import ('@views/accounting-reconciliation/outer-driver-list'),
            meta: {
                // keepAlive: true,
                isBack: false
            }
        },{
            // 外请车对账-明细
            path: '/accounting/outer-driver-details',
            name: 'OuterDriverDetails',
            component: () =>
                import ('@views/accounting-reconciliation/outer-driver-details'),
            meta: {
                // keepAlive: true,
                isBack: false
            }
        }, {
            path: '/accounting/external-detailed',
            name: 'ExternalDetailed',
            component: () =>
                import ('@views/accounting-reconciliation/external-detailed'),
            meta: {
                // keepAlive: true,
                // JumpPath: '/accounting/external-driver'
            }
        }, {
            path: '/accounting/external-accounting',
            name: 'ExternalAccounting',
            component: () =>
              import ('@views/accounting-reconciliation/external-accounting'),
            meta: {
                // keepAlive: true,
                // JumpPath: '/accounting/external-driver'
            }
        },
        {
            path: '/accounting/orde-eaitDetailed',
            name: 'OrdeEaitDetailed',
            component: () =>
                import ('@views/accounting-reconciliation/orde-eaitDetailed'),
        }
    ]
}
