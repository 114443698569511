<template>
  <el-dialog
    :title="title_"
    :visible="visible"
    :showClose="false"
    :destroyOnClose="true"
    v-bind="$attrs"
  >
    <!-- {{value}} -->
    <slot></slot>
    <div id="plateContainer">
      <el-row v-loading="!loaded"></el-row>
    </div>
    <div slot="footer" class="dialog-footer" v-if="showBottomBtn">      
      <el-button type="success" @click="onConfirm">{{confirmButtonText}}</el-button>
      <el-button type="danger" @click="onCancel">{{cancelButtonText}}</el-button>
    </div>
  </el-dialog>
</template>
<script>
import Vue from "vue";
export default {
  name: "ayl-dialog",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default() {
        return {};
      }
    },
    async: {
      type: Boolean,
      default: false
    },
    viewUrl: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: "新窗口"
    },
    showBottomBtn: {
      type: Boolean,
      default: true
    },
    confirmButtonText: {
      type: String,
      default: "确 定"
    },
    cancelButtonText: {
      type: String,
      default: "关 闭"
    }
  },
  // model:{
  //   prop: 'value',
  //   event: 'updateData'
  // },
  data() {
    return {
      debug: 0,
      loaded: false,
      title_: this.title
    };
  },
  methods: {
    //取消按钮的点击响应
    onCancel() {
      this.onClose();
    },
    //确认按钮的点击响应
    onConfirm() {
      this.onClose();
    },
    //纯粹关闭弹窗，与具体交互无关
    onClose() {
      this.$emit("update:visible", false); //同步值到父组件
    }
  },
  watch: {
    visible: function(v, ov) {
      let self = this;
      if (self.async && self.viewUrl && v)
        // 动态添加需要的版式
        (function(url) {
          return import(`../../views${url}`).then(viewComponent => {
            const constructor = Vue.extend(viewComponent.default);
            const instance = new constructor();

            //传入数据给新加载的子组件: dataFromDialog 为 props
            instance.dataFromDialog = self.value;
            // self.log("父组件传来的数据是：",self.value);

            //监听子组件抛出的数据
            instance.$on("onClose", params => {
              //  params 为组件内部抛出的数据
              self.onClose();
            });

            // 挂载到 ID 为 plateContainer 的DOM元素
            instance.$mount("#plateContainer");

            self.log(url + " 加载成功");

            self.loaded = true;

            self.title_ = instance.nav[0].name;
          });
        })(self.viewUrl);
    }
  },
  mounted() {
    this.log("dialog mounted");
  },
  activated() {
    this.log("dialog activated");
  }
};
</script>
<style scoped>
</style>