<template>
  <div class="left-main">
    <span class="left-title">{{ customInfo.pageTitle }}</span>
    <!-- 智连 -->
    <div v-if="scope === 'TMS'" class="left-nav">
      <el-menu router unique-opened :default-active="routerPath">
        <!-- <div v-for="(item, idx) in menus" :key="idx"> -->
        <div
          v-for="(item, idx) in env == 'test' || env == 'production'
            ? menus
            : menusChildren"
          :key="idx"
        >
          <el-menu-item
            :index="item.path"
            v-if="!item.children.length"
            :class="{
              'menus-active':
                !$route.meta.JumpPath &&
                $route.path.split('/')[1] === item.path.split('/')[1],
            }"
          >
            <i class="iconfont" :class="item.icon"></i>
            <span>{{ item.name }}</span>
          </el-menu-item>
          <el-submenu :index="idx + ''" v-else>
            <template slot="title">
              <i class="iconfont" :class="item.icon"></i>
              <span>{{ item.name }}</span>
            </template>
            <el-menu-item-group>
              <div v-for="(child, childIdx) in item.children" :key="childIdx">
                <el-menu-item :index="child.path" v-if="!child.children.length">
                  <!-- :class="{'menus-active': $route.path.split('/')[2] === child.path.split('/')[2]}" -->
                  {{ child.name }}
                </el-menu-item>
                <el-submenu :index="idx + '1'" v-else>
                  <div slot="title" style="margin-left: 32px">
                    <span>{{ child.name }}</span>
                  </div>
                  <el-menu-item
                    v-for="(childItem, childItemIdx) in child.children"
                    :key="childItemIdx"
                    :index="childItem.path"
                  >
                    <span style="margin-left: 10px">{{ childItem.name }}</span>
                  </el-menu-item>
                </el-submenu>
              </div>
            </el-menu-item-group>
          </el-submenu>
        </div>
      </el-menu>
    </div>
    <!-- 环卫 -->
    <div v-if="scope === 'SANITATION'" class="left-nav-hw">
      <el-menu router unique-opened :default-active="routerPath">
        <div
          v-for="(item, idx) in env == 'test' || env == 'production'
            ? menus
            : menusChildrenHw"
          :key="idx"
        >
          <el-menu-item
            :index="item.path"
            v-if="!item.children.length"
            :class="{
              'menus-active':
                !$route.meta.JumpPath &&
                $route.path.split('/')[1] === item.path.split('/')[1],
            }"
          >
            <i class="iconfont" :class="item.icon"></i>
            <span>{{ item.name }}</span>
          </el-menu-item>
          <el-submenu :index="idx + ''" v-else>
            <template slot="title">
              <i class="iconfont" :class="item.icon"></i>
              <span>{{ item.name }}</span>
            </template>
            <el-menu-item-group>
              <div v-for="(child, childIdx) in item.children" :key="childIdx">
                <el-menu-item :index="child.path" v-if="!child.children.length">
                  <!-- :class="{'menus-active': $route.path.split('/')[2] === child.path.split('/')[2]}" -->
                  {{ child.name }}
                </el-menu-item>
                <el-submenu :index="idx + '1'" v-else>
                  <div slot="title" style="margin-left: 32px">
                    <span>{{ child.name }}</span>
                  </div>
                  <el-menu-item
                    v-for="(childItem, childItemIdx) in child.children"
                    :key="childItemIdx"
                    :index="childItem.path"
                  >
                    <span style="margin-left: 10px">{{ childItem.name }}</span>
                  </el-menu-item>
                </el-submenu>
              </div>
            </el-menu-item-group>
          </el-submenu>
        </div>
      </el-menu>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      customInfo: JSON.parse(JSON.parse(sessionStorage.getItem("customInfo"))),
      scope: sessionStorage.getItem("scope", ""),
      menus: JSON.parse(sessionStorage.getItem("menus")),
      menusChildrenHw: [
        {
          path: "/home",
          icon: "iconshouye",
          name: "首页",
          children: [],
        },
        {
          path: "/big-data-screen",
          icon: "iconzhihuizhongxin",
          name: "指挥大屏",
          children: [],
        },
        {
          path: "/operation-regulation",
          icon: "iconjiankongkongzhiguanlijianguan",
          name: "作业监管",
          children: [
            {
              path:
                "/operation-regulation/real-time-regulation/real-time-regulation",
              name: "实时监管",
              children: [],
            },
            {
              path:
                "/operation-regulation/historical-playback/historical-playback",
              name: "历史回放",
              children: [],
            },
          ],
        },
        {
          path: "/district-management",
          icon: "iconxiangmubiaoduanshezhi",
          name: "标段管理",
          children: [
            {
              path: "/district-management/district-info",
              name: "标段信息",
              children: [],
            },
            {
              path: "/district-management/district-contract",
              name: "标段合同",
              children: [],
            },
            {
              path: "/district-management/historical-blocks/historical-blocks",
              name: "历史标段",
              children: [],
            },
            {
              path: "/district-management/personnel-info",
              name: "人员管理",
              children: [],
            },
            {
              path:
                "/district-management/vehicle-management/vehicle-management",
              name: "车辆管理",
              children: [],
            },
            {
              path: "/district-management/fleet-management/list",
              name: "车队管理",
              children: [],
            },
            {
              path: "/district-management/equipment-management/list",
              name: "设备管理",
              children: [],
            },
            {
              path: "/district-management/sim-management/sim-management",
              name: "SIM卡管理",
              children: [],
            },
            {
              path: "/district-management/urban-villages-management/list",
              name: "城中村",
              children: [],
            },
            {
              path: "/district-management/municipal-road/list",
              name: "市政道路",
              children: [],
            },
            {
              path: "/district-management/transfer-station-management/list",
              name: "垃圾中转站",
              children: [],
            },
            {
              path: "/district-management/refuse-collection-point/list",
              name: "垃圾收集点",
              children: [],
            },
            {
              path: "/district-management/plot-management/list",
              name: "国土地块",
              children: [],
            },
            {
              path: "/district-management/green-garbage-house/list",
              name: "环保垃圾屋",
              children: [],
            },
            {
              path: "/district-management/public-toilets/list",
              name: "公厕管理",
              children: [],
            },
            {
              path:
                "/district-management/vehicle-operation/vehicle-operation-management",
              name: "车辆作业路线",
              children: [],
            },
            {
              path: "/district-management/personnel-work/list",
              name: "人员作业网格",
              children: [],
            },
            {
              path: "/district-management/scheduling/list",
              name: "排班管理",
              children: [],
            },
            {
              path: "/district-management/salary-management/salary-management",
              name: "工资管理",
              children: [],
            },
            {
              path:
                "/district-management/complaint-management/complaint-management",
              name: "申诉管理",
              children: [],
            },
            {
              path: "/district-management/audit-records/audit-records",
              name: "标段审核记录",
              children: [],
            },
            {
              path: "/district-management/block-audit/block-audit-management",
              name: "标段审核",
              children: [],
            },
          ],
        },
        {
          path: "alarm-management",
          icon: "iconbaojingguanli",
          name: "报警管理",
          children: [
            {
              path: "/alarm-management/alarm-center/alarm-center",
              name: "报警中心",
              children: [],
            },
            {
              path: "/alarm-management/evidence-center/evidence-center",
              name: "证据中心",
              children: [],
            },
          ],
        },
        {
          path: "/operation-safety-management",
          icon: "iconanquan1",
          name: "作业安全管理",
          children: [
            {
              path:
                "/operation-safety-management/operating-rules/operating-rules",
              name: "作业规则管理",
              children: [],
            },
            {
              path:
                "/operation-safety-management/operational-early-warning-management/list",
              name: "作业预警管理",
              children: [],
            },
            {
              path:
                "/operation-safety-management/personnel-health-management/personnel-health-management",
              name: "人员健康管理",
              children: [],
            },
            {
              path: "/operation-safety-management/safety-training-record/list",
              name: "安全培训记录",
              children: [],
            },
            {
              path: "/operation-safety-management/safety-check/safety-check",
              name: "企业安全自查",
              children: [],
            },
            {
              path:
                "/operation-safety-management/emergency-management/emergency-management",
              name: "应急管理",
              children: [],
            },
            {
              path:
                "/operation-safety-management/safety-incident-record/safety-incident-record",
              name: "安全事故记录",
              children: [],
            },
            {
              path:
                "/operation-safety-management/safety-supplies-management/safety-supplies-management",
              name: "安全物资管理",
              children: [],
            },
            {
              path:
                "/operation-safety-management/safety-training-audit/safety-training-audit",
              name: "安全培训审核",
              children: [],
            },
            {
              path:
                "/operation-safety-management/safety-check-audit/safety-check-audit",
              name: "企业安全自查审核",
              children: [],
            },
            {
              path:
                "/operation-safety-management/contingency-plan-audit/contingency-plan-audit",
              name: "应急预案审核",
              children: [],
            },
            {
              path:
                "/operation-safety-management/emergency-supplies-audit/emergency-supplies-audit",
              name: "应急物资审核",
              children: [],
            },
            {
              path:
                "/operation-safety-management/emergency-drill-audit/emergency-drill-audit",
              name: "应急演练审核",
              children: [],
            },
            {
              path:
                "/operation-safety-management/emergency-response-audit/emergency-response-audit",
              name: "应急处置审核",
              children: [],
            },
            {
              path:
                "/operation-safety-management/safety-accidents-audit/safety-accidents-audit",
              name: "安全事故审核",
              children: [],
            },
            {
              path:
                "/operation-safety-management/safety-supplies-audit/safety-supplies-audit",
              name: "安全物资审核",
              children: [],
            },
          ],
        },
        {
          path: "",
          icon: "iconxitong",
          name: "系统管理",
          children: [
            {
              path: "/sanitation-system-management/role-manage/role-manage",
              icon: "",
              name: "权限分配",
              children: [],
            },
            {
              path: "/sanitation-system-management/user-manage/user-manages",
              icon: "",
              name: "用户管理",
              children: [],
            },
          ],
        },
      ],
      menusChildren: [
        {
          path: "/home",
          icon: "iconshouye",
          name: "首页",
          children: [],
        },
        {
          path: "/data-b1",
          icon: "iconzhihuizhongxin",
          name: "指挥中心",
          children: [],
        },
        {
          path: "/search-order/search-order",
          icon: "icondingdan",
          name: "订单查询",
          children: [],
        },
        {
          path: "/consign-order/consign-order",
          icon: "icontuoyun",
          name: "托运录单",
          children: [],
        },
        {
          path: "/outgoing-allot/outgoing-allot",
          icon: "icontiaodu",
          name: "外发调度",
          children: [],
        },
        {
          path: "/examine/to-examine",
          icon: "iconjiaodanshenhe",
          name: "交单审核",
          children: [],
        },
        {
          path: "/order/order-accounting",
          icon: "iconhesuan",
          name: "订单核算",
          children: [],
        },
        {
          path: "",
          icon: "iconduizhang",
          name: "会计对账",
          children: [
            {
              path: "/accounting/customer-reconciliation",
              icon: "",
              name: "客户对账",
              children: [],
            },
            {
              path: "/accounting/inside-driver",
              icon: "",
              name: "内部驾驶员统计",
              children: [],
            },
            {
              path: "/accounting/external-driver",
              icon: "",
              name: "外请车主统计",
              children: [],
            },
            {
              path: "/accounting/outer-driver-accounting",
              icon: "",
              name: "外请车对账",
              children: [],
            },
          ],
        },
        {
          path: "",
          icon: "iconjiesuan",
          name: "出纳结算",
          children: [
            {
              path: "/cashier-settlement/contacts-unit",
              icon: "",
              name: "客户结算",
              children: [],
            },
            {
              path: "/cashier-settlement/owner-outside",
              icon: "",
              name: "外请车结算",
              children: [],
            },
            {
              path:
                "/cashier-settlement/information-expense/information-expense",
              icon: "",
              name: "信息费报表",
              children: [],
            },
            {
              path: "/cashier-settlement/cashier-payment/cashier-payment",
              icon: "",
              name: "出纳收付报表",
              children: [],
            },
          ],
        },
        {
          path: "/safety-center",
          icon: "iconanquan",
          name: "安全中心",
          children: [
            {
              path: "/safety-center/safety-monitoring",
              icon: "",
              name: "安全监控",
              children: [],
            },
            {
              path: "/safety-center/historical-playback",
              icon: "",
              name: "历史回放",
              children: [],
            },
            {
              path: "/safety-center/alarm-management",
              icon: "",
              name: "报警管理",
              children: [
                {
                  path: "/safety-center/alarm-management",
                  icon: "",
                  name: "报警中心",
                  children: [],
                },
                {
                  path: "/safety-center/evidence-center",
                  icon: "",
                  name: "证据中心",
                  children: [],
                },
              ],
            },
          ],
        },
        {
          path: "/oil-management",
          icon: "iconyouliang",
          name: "油耗管理",
          children: [
            {
              path: "/oil-info/oil-info",
              icon: "",
              name: "油量概况",
              children: [],
            },
            {
              path: "/oil-real/oil-real",
              icon: "",
              name: "实时油量",
              children: [],
            },
            {
              path: "/oil-query/oil-query",
              icon: "",
              name: "油量查询",
              children: [],
            },
            {
              path: "/oil-add/oil-add",
              icon: "",
              name: "加油情况",
              children: [],
            },
            {
              path: "/oil-drop/oil-drop",
              icon: "",
              name: "油量骤减",
              children: [],
            },
          ],
        },
        {
          path: "",
          icon: "icontongji",
          name: "业务报表",
          children: [
            {
              path: "/business-report/customer-statements",
              icon: "",
              name: "客户报表",
              children: [],
            },
            {
              path: "/business-report/driver-report",
              icon: "",
              name: "驾驶员报表",
              children: [],
            },
            {
              path: "/business-report/vehicl-report",
              icon: "",
              name: "车辆报表",
              children: [],
            },
            {
              path: "/business-report/business-type-report",
              icon: "",
              name: "业务类型报表",
              children: [],
            },
            {
              path: "/business-report/site-report",
              icon: "",
              name: "地点报表",
              children: [],
            },
            {
              path: "/business-report/status-report",
              icon: "",
              name: "状态报表",
              children: [],
            },
          ],
        },
        {
          path: "",
          icon: "iconjiashiyuan",
          name: "驾驶员管理",
          children: [
            {
              path: "/operation/internal-officer",
              icon: "",
              name: "内部驾驶员维护",
              children: [],
            },
            {
              path: "/operation/outside-management",
              icon: "",
              name: "外请车主维护",
              children: [],
            },
          ],
        },
        {
          path: "",
          icon: "iconcheliang",
          name: "车辆管理",
          children: [
            {
              path: "/vehicle-management/fleet-management",
              icon: "",
              name: "车队管理",
              children: [],
            },
            {
              path: "/vehicle-management/car-management",
              icon: "",
              name: "车辆信息",
              children: [],
            },
            {
              path: "/vehicle-management/equipment-management",
              icon: "",
              name: "设备管理",
              children: [],
            },
            {
              path: "/vehicle-management/sim-management",
              icon: "",
              name: "SIM卡管理",
              children: [],
            },
          ],
        },
        {
          path: "",
          icon: "icondanwei",
          name: "往来单位管理",
          children: [
            {
              path: "/operation/operations-management",
              icon: "",
              name: "往来单位维护",
              children: [],
            },
          ],
        },
        {
          path: "",
          icon: "iconxitong",
          name: "系统管理",
          children: [
            {
              path: "/system-manage/roleManage",
              icon: "",
              name: "权限分配",
              children: [],
            },
            {
              path: "/system-manage/userManage",
              icon: "",
              name: "人员管理",
              children: [],
            },
            {
              path: "/system-manage/company-management",
              icon: "",
              name: "企业管理",
              children: [],
            },
            {
              path: "/system-manage/transport-address/list",
              icon: "",
              name: "托运地址",
              children: [],
            },
          ],
        },
        {
          path: "/backup",
          icon: "iconshujukanban",
          name: "数据备份",
          children: [],
        },
      ],
    };
  },
  watch: {
    menus: function () {
      //console.log('1...')
      // this.drawMenus()
    },
  },
  computed: {
    env() {
      return process.env.NODE_ENV;
    },
    routerPath() {
      // console.log(this.$route.meta.JumpPath)
      // console.log(this.$route.path)
      return this.$route.meta.JumpPath
        ? this.$route.meta.JumpPath
        : this.$route.path;
    },
  },
  methods: {
    // drawMenus(){
    //   console.log('drawMenus...')
    // }
  },
  mounted() {
    // console.log(JSON.stringify(this.menusChildren))
    // console.log('菜单：' + this.$cookies.get('menus'))
  },
};
</script>
<style lang="sass" scoped>
  .left-main
    display: inline-block
    width: $sidebarWidth
    height: 100%
    vertical-align: top
    box-shadow: 2px 0 8px 0 rgba(255, 255, 255, 0.12)
    background-color: $-background-color
    position: relative
    z-index: 3

    .left-title
      display: block
      width: 100%
      height: $sidebarHeaderHeight
      font-weight: bold
      color: $-color-white
      font-size: $fontX
      text-align: center
      line-height: $sidebarHeaderHeight
      letter-spacing: 2px

    .left-nav
      height: calc(100% - #{$sidebarHeaderHeight})
      overflow-y: auto
      overflow-x: hidden

      /deep/ .el-menu
        border-right: 0
        background-color: $-background-color

      /deep/ .el-menu-item, /deep/ .el-submenu__title
        height: 48px
        line-height: 48px
        color: rgba($-color-white, .6)
        padding-left: 24px !important

        i
          color: rgba($-color-white, .6)
          margin-right: 10px

        &:hover
          background: rgba($color: $-color-primary-1, $alpha: 0.4)

      /deep/ .el-submenu .el-menu .el-menu-item
        padding-left: 58px !important

      /deep/ .el-menu-item.is-active, .active
        color: $-color-white
        background-color: $-color-primary-1

      .menus-active
        background-color: $-color-primary-1
        color: $-color-white

        i
          color: $-color-white
    
    .left-nav-hw
      height: calc(100% - #{$sidebarHeaderHeight})
      overflow-y: auto
      overflow-x: hidden

      /deep/ .el-menu
        border-right: 0
        background-color: $-background-color

      /deep/ .el-menu-item, /deep/ .el-submenu__title
        height: 48px
        line-height: 48px
        color: rgba($-color-white, .6)
        padding-left: 24px !important
        border-radius: 22px 0px 0px 22px

        i
          color: rgba($-color-white, .6)
          margin-right: 10px

        &:hover
          background: rgba($color: $-color-primary-3, $alpha: 0.4)

      /deep/ .el-submenu .el-menu .el-menu-item
        padding-left: 58px !important

      /deep/ .el-menu-item.is-active, .active
        color: $-color-white
        background-color: $-color-primary-3
        border-radius: 22px 0px 0px 22px

      .menus-active
        background-color: $-color-primary-3
        color: $-color-white

        i
          color: $-color-white
</style>

