import Layout from '@views/Layout.vue'

export default {
  path: '/system-manage',
  component: Layout,
  children: [{
    name: 'roleManage',
    path: '/system-manage/roleManage',
    component: () => import('@views/system-manage/roleManage/RoleManages.vue'),
    meta: {
        keepAlive: true,
        isBack: false
    }
  },
  {
    name: 'roleManageEdit',
    path: '/system-manage/roleManageEdit',
    component: () => import('@views/system-manage/roleManage/RoleManageEdit.vue')
  },
  {
    name: 'roleManageDetails',
    path: '/system-manage/roleManageDetails',
    component: () => import('@views/system-manage/roleManage/RoleManageDetails.vue')
  },
  {
    name: 'addRole',
    path: '/system-manage/addRole',
    component: () => import('@views/system-manage/roleManage/AddRole.vue')
  },
  {
    name: 'userManage',
    path: '/system-manage/userManage',
    component: () => import('@views/system-manage/userManage/UserManages.vue'),
    meta: {
        keepAlive: true,
        isBack: false
    }
  },
  {
    name: 'addUser',
    path: '/system-manage/addUser',
    component: () => import('@views/system-manage/userManage/AddUser.vue')
  },
  {
    name: 'updateUser',
    path: '/system-manage/updateUser',
    component: () => import('@views/system-manage/userManage/UpdateUser.vue')
  },
  {
    name: 'userDetails',
    path: '/system-manage/userDetails',
    component: () => import('@views/system-manage/userManage/UserDetails.vue')
  },
  {
    name: 'companyManagement',
    path: '/system-manage/company-management',
    component: () => import('@views/system-manage/company-management'),
    meta: {
        keepAlive: true,
        isBack: false
    }
  }]
}
