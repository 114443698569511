<template>
  <div class="ayl-flv" ref="aylVideo">
    <video
    ref="videoPlayerFlv"
    class="video-js vjs-big-play-centered"
    controls
    autoplay
    loop
    ish265
    islive
    hasvideo
    hasaudio
    lib="/static"
    v-if="shortName === '265'"
  >
    <source :src="url" type="video/x-flv" />
  </video>
  <!-- <div style="width:50px;height: 50px;" v-loading="loading"></div> -->
  <video
    ref="videoPlayerFlv"
    class="video-js vjs-big-play-centered"
    controls
    autoplay
    loop
    islive
    hasvideo
    hasaudio
    lib="/static"
    v-if="shortName === '264'"
  >
    <source :src="url" type="video/x-flv" />
  </video>
  </div>
</template>

<script>
/**
 * 播放h265 flv组件
 * url: (String) 例："https://ks3-cn-beijing.ksyun.com/ksplayer/h265/outside_demo/v1.1.3/720P2M30fpsh265-wasmtest.flv"
 * 页面引用： <ayl-fiv :url='播放地址'></ayl-fiv>
*/
  import videojs from 'video.js'
  import 'video.js/dist/video-js.css'
  import 'videojsflvh265car' 

  export default {
    props: ['url'],
    data () {
      return {
        player: null,
        shortName: null,
        // loading: true,
      }
    },
    created() {
      let urlLength = this.url.length
      // this.shortName = this.url.substring(urlLength-7,urlLength-4);
      this.shortName = '265';
    },
    methods: {
      initVideo () {
        // videojs.options.flash.swf = "./video-js.swf";
        this.player = videojs(this.$refs.videoPlayerFlv, {
        techOrder: [
            'html5',
            'flvh265'
        ],
        controlBar:{
            volumePanel:{inline:false},
            pictureInPictureToggle:false //we don't support pictureInPicture now
        },
        height: this.$refs.aylVideo.offsetHeight      ,
        width: this.$refs.aylVideo.offsetWidth,
        });

        this.player.on("error",function(){
          console.log(this.error());
        });

        this.player.on("progress",function(){
            console.log("正在请求数据 ");
        });

        this.player.on("loadstart",function(){
          console.log("loadstart");
          console.time()
          
        });
        this.player.on("loadedmetadata",function(){
          console.log("loadedmetadata");
          console.timeEnd()
        });
      }
    },
    mounted () {
      this.initVideo()
    },
    beforeDestroy () {
      if (this.player) {
        this.player.dispose()
        this.player = null
      }
    },
    // deactivated () {
    //   if (this.players) {
    //     this.players.dispose()
    //     this.players = null
    //   }
    // },
  }
</script>

<style lang='sass' scoped>
  .ayl-flv
    display: inline-block
</style>
