<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <!-- <router-view></router-view> -->
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      customInfo: JSON.parse(JSON.parse(sessionStorage.getItem("customInfo")))
    };
  },
  methods: {},
  mounted() {
    if (this.customInfo) {
      document.title = "" + this.customInfo.pageTitle;
      document.querySelector("link[rel*='icon']").href = _(this.customInfo.icon);
    }

    function _(url) {
      if(!url)return "";
      /*
      let i = url.lastIndexOf("/");
      if(!url.includes(document.location.origin))
        if (i != -1) {
          url = document.location.origin + url.substring(i);
        }
      console.log(url);    */  
      return url;
    }    
  }
};
</script>
<style lang="sass" scoped>
</style>
