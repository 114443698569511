import Layout from '@views/Layout.vue'

export default {
    path: '/backup',
    component: Layout,
    children: [{
        path: '/backup',
        name: 'Backup',
        component: () =>
            import ('@views/backup/index'),
    }]
}