<template>
  <div class="pie">
    <div class="pie-main" ref="pie"></div>
  </div>
</template>

<script>
  import echarts from 'echarts'
  const color = ["#FFA940", "#40A9FF", "#73D13D", "#9254DE", "#F759AB", "#fca4bb", "#f59a8f", "#fdb301", "#57e7ec", "#cf9ef1"]
  const option = {
    backgroundColor: "rgba(255,255,255,1)",
    color: color,
    legend: {
      orient: "horizontal",
      top: "85%",
      right: "15%",
      left: "15%",
      bottom: "5%",
      data: [],
      itemWidth: 8,
      itemHeight: 8,
      icon: 'circle',
      itemGap: 18,
      formatter: function(name){
        return '' +name
      },
      textStyle: {
        fontSize: 14
      }
    },
    tooltip: {
        trigger: 'item',
        formatter: "{b} ({d}%)"
    },
    series: [{
      type: 'pie',
      //饼图的扇区是否是顺时针排布
      clockwise: false,
      //最小的扇区角度（0 ~ 360）
      minAngle: 2,
      radius: ["40%", "65%"],
      center: ["50%", "50%"],
      avoidLabelOverlap: false,
      // 图形样式
      itemStyle: {
        normal: {
          borderColor: '#ffffff',
          borderWidth: 6,
        },
      },
      label: {
        normal: {
          show: false,
          position: 'center',
          formatter: '{text|{b}}\n{c} ({d}%)',
          rich: {
            text: {
              color: "#666",
              fontSize: 14,
              align: 'center',
              verticalAlign: 'middle',
              padding: 8
            },
            value: {
              color: "#8693F3",
              fontSize: 24,
              align: 'center',
              verticalAlign: 'middle',
            },
          }
        },
        emphasis: {
          show: false,
          textStyle: {
            fontSize: 22,
          }
        }
      },
      data: []
    }]
  };
  export default {
    props: {
      boardData: {
        default: function () {
          return []
        },
        type: Array
      }
    },
    data () {
      return {
        timer: null,
        total: 0,
        labelList: []
      }
    },
    methods: {
      drawLPie (){
        let pieOption = option
        let pie = echarts.init(this.$refs.pie)

        if(this.boardData.length===0) return
        pieOption.series[0].data = this.boardData
        pieOption.legend.data = this.boardData.map(e=>{
          return e.name
        })

        pie.setOption(pieOption)
        window.addEventListener('resize', function () {
          pie.resize()
        })
        return
        // 自动滚动
        pie.dispatchAction({type: 'highlight', seriesIndex: 0, dataIndex: this.total})
        this.timer = setInterval(() => {
          pie.dispatchAction({type: 'downplay', seriesIndex: 0, dataIndex: this.total})
          this.total++
          pie.dispatchAction({type: 'highlight', seriesIndex: 0, dataIndex: this.total})
          if (this.boardData.pieceList.length === this.total) {
            this.total = 0
            pie.dispatchAction({type: 'highlight', seriesIndex: 0, dataIndex: this.total})
          }
        }, 2000)
        setTimeout(()=> {
          // 添加鼠标移入事件
          pie.on('mouseover', params => {
            clearInterval(this.timer)
            for (let i = 0; i < this.boardData.pieceList.length; i++) {
              if (i !== params.dataIndex) {
                pie.dispatchAction({type: 'downplay', seriesIndex: 0, dataIndex: i})
              }
            }
          });
          // 添加鼠标移出事件
          pie.on('mouseout', params => {
            this.total = params.dataIndex
            pie.dispatchAction({type: 'highlight', seriesIndex: 0, dataIndex: this.total})
            this.timer = setInterval(() => {
              pie.dispatchAction({type: 'downplay', seriesIndex: 0, dataIndex: this.total})
              this.total++
              pie.dispatchAction({type: 'highlight', seriesIndex: 0, dataIndex: this.total})
              if (this.boardData.pieceList.length === this.total) {
                this.total = 0
                pie.dispatchAction({type: 'highlight', seriesIndex: 0, dataIndex: this.total})
              }
            }, 2000)
          })
        }, 1000);
      }
    },
    mounted () {
      this.drawLPie()
    },
    watch: {
      "boardData":function(){
        this.drawLPie()
      }
    }
  }
</script>

<style lang='sass' scoped>
.pie
  width: 100%
  height: 100%
  position: relative
  &-main
    width: 100%
    height: 100%
</style>
