<template>
  <div class="ayl-fts" ref="aylVideo">
    <video
    ref="videoPlayerTs"
    class="video-js vjs-big-play-centered"
    controls
    loop
    ish265
    islive
    hasvideo
    hasaudio
    lib="/static"
  >
    <source :src="url" type="video/x-flv" />
  </video>
  </div>
</template>

<script>
/**
 * 播放h265 flv组件
 * url: (String) 例："https://ks3-cn-beijing.ksyun.com/ksplayer/h265/outside_demo/v1.1.3/720P2M30fpsh265-wasmtest.flv"
 * 页面引用： <ayl-ts :url='播放地址'></ayl-ts>
*/
  import videojs from 'video.js'
  import 'video.js/dist/video-js.css'
  import 'videojsflvh265car' 

  export default {
    props: ['url'],
    data () {
      return {
        player: null,
      }
    },
    created() {
    },
    methods: {
      initVideo () {
        // videojs.options.flash.swf = "./video-js.swf";
        this.player = videojs(this.$refs.videoPlayerTs, {
        techOrder: [
            'html5',
            'flvh265'
        ],
        controlBar:{
            volumePanel:{inline:false},
            pictureInPictureToggle:false //we don't support pictureInPicture now
        },
        height: this.$refs.aylVideo.offsetHeight      ,
        width: this.$refs.aylVideo.offsetWidth,
        });

        this.player.on("error",function(){
          console.log(this.error());
        });

        this.player.on("loadstart",function(){
          console.log("loadstart");
          console.time()
          
        });
        this.player.on("loadedmetadata",function(){
          console.log("loadedmetadata");
          console.timeEnd()
        });
      }
    },
    mounted () {
      this.initVideo()
    },
    beforeDestroy () {
      if (this.player) {
        this.player.dispose()
        this.player = null
      }
    },
    // deactivated () {
    //   if (this.players) {
    //     this.players.dispose()
    //     this.players = null
    //   }
    // },
  }
</script>

<style lang='sass' scoped>
  .ayl-ts
    display: inline-block
</style>
