<template>
  <el-image
    v-bind="$attrs"
    v-on="$listeners"
    :src="finalUrl"
    :fit="fit"
    :lazy="lazy"
    v-image-preview
  ></el-image>
</template>
<script>
/**
 * 根据fileKey展示单张照片
 */
export default {
  name: "ayl-image",

  inheritAttrs: false,

  props: {
    value: {
      type: String,
      default: null
    },
    fit: {
      type: String,
      default: "cover"
    },
    lazy: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      debug: 0,
      url: this.value
    };
  },

  methods: {},

  computed: {
    finalUrl() {
      return !this.url ? `` : this.url; //过滤掉null情况时<el-image>的bug
    }
  },

  watch: {
    value: function(v, ov) {
      this.log(`---------ayl-image watch value---------`, v, ov);

      let self = this;
      (async function() {
        if (!self.value) {
          self.url = ``;
          return;
        }

        if (self.value.toLowerCase().startsWith("blob:")) {
          self.url = self.value;
          return;
        }

        let queryData = {
          ailivingToken: JSON.parse(sessionStorage.getItem("loginData")).Token,
          fileKey: self.value
        };
        self.url = await self.$api_hw.common_getFileUrlFromOSS(queryData);
      })().catch(function(e) {
        console.error(e);
      });
    }
  },

  mounted() {}
};
</script>
<style scoped>
</style>