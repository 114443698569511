<template>
  <div class="search-box" v-if="!$_.isEmpty(searchData)">
    <span class="ctrl-btn">
      <slot name="before" />
    </span>
    <div class="form" v-for="(item, idx) in searchDataIsVisible" :key="idx">

      <div v-if="item.type === 'select'" class="select">
        <span class="text">{{item.title}}</span>
        <el-select
          :value="getQueryObjectByStr(item.model)"
          :style="{'width': item.width}"
          :placeholder="item.placeholder"
          clearable
          filterable
          @change="(value)=>onSelectChange(value,item)"
        >
          <el-option
            v-for="(i, idx) in (item.enumType ? $enums[item.enumType].allList() : item.option)"
            :label="i.label"
            :value="i.value"
            :key="idx"
          />
        </el-select>
      </div>

      <div v-if="item.type === 'selectNew'" class="select">
        <span class="text">{{item.title}}</span>
        <el-select
          :value="getQueryObjectByStr(item.model)"
          :style="{'width': item.width}"
          :placeholder="item.placeholder"
          clearable
          filterable
          @change="(value)=>onSelectChange(value,item)"
        >
          <el-option v-for="i in item.option" :label="i.label" :value="i.label" :key="i.label" />
        </el-select>
      </div>

      <!--选择器不可删除为空-->
      <div v-if="item.type === 'selectClear'" class="selectClear">
        <span class="text">{{item.title}}</span>
        <el-select
          :value="getQueryObjectByStr(item.model)"
          :style="{'width': item.width}"
          :placeholder="item.placeholder"
          filterable
          @change="(value)=>onSelectChange(value,item)"
        >
          <el-option
            v-for="(i, idx) in (item.enumType ? $enums[item.enumType].allList() : item.option)"
            :label="i.label"
            :value="i.value"
            :key="idx"
          />
        </el-select>
      </div>

      <!--基础多选-->
      <div v-if="item.type === 'multiple'" class="select">
        <span class="text">{{item.title}}</span>
        <el-select
          :value="getQueryObjectByStr(item.model)"
          :style="{'width': item.width}"
          clearable
          multiple
          collapse-tags
          :placeholder="item.placeholder"
          @change="(value) => checkMultiple(value,item)"
        >
          <el-option v-for="(i, idx) in item.option" :label="i.label" :value="i.value" :key="idx" />
        </el-select>
      </div>


      <!--多选联级选择器-->
      <div v-if="item.type === 'cascader'" class="cascader">
        <span class="text">{{item.title}}</span>
        <el-cascader
          :style="{'width': item.width}"
          @change="(value) => cascaderChange(value,item)"
          :options="item.option"
          :props="{ multiple: true }"
          :show-all-levels="false"
          :placeholder="item.placeholder"
          clearable
          collapse-tags
        />
      </div>

      <!--单选联级选择器 -->
      <div v-if="item.type === 'cascader_radio'" class="cascader">
        <span class="text">{{item.title}}</span>
        <el-cascader
          :style="{'width': item.width}"
          @change="(value) => cascaderRadioChange(value,item)"
          :options="item.option"
          :show-all-levels="false"
          :placeholder="item.placeholder"
          clearable
        />
      </div>

      <!--多选联级选择器-两级-->
      <div v-if="item.type === 'cascader_two'" class="cascader">
        <span class="text">{{item.title}}</span>
        <el-cascader
          :style="{'width': item.width}"
          @change="(value) => cascaderTwoChange(value,item)"
          :options="item.option"
          :props="{ multiple: true }"
          :show-all-levels="false"
          :placeholder="item.placeholder"
          clearable
          collapse-tags
        />
      </div>

      <!--多选联级选择器 New by 马凯-->
      <div v-if="item.type === 'cascaderNew'" class="cascader">
        <span class="text">{{item.title}}</span>
        <el-cascader
          :style="{'width': item.width}"
          @change="(value) => onCascaderNewChange(value,item)"
          :options="item.option"
          :props="{ multiple: true }"
          :show-all-levels="false"
          :placeholder="item.placeholder"
          clearable
          collapse-tags
        />
      </div>
      <div v-if="item.type === 'dateSection_zl'" class="date-section">
        <span class="text">{{item.title}}</span>
        <el-date-picker
          class="date"
          format="yyyy-MM-dd"
          value-format="timestamp"
          v-model="zlDefaultVal"
          type="daterange"
          align="right"
          unlink-panels
          @change="(value)=>dateChange_zl(value,item)"
          start-placeholder="开始日期"
          range-separator="至"
          end-placeholder="结束日期"
          :picker-options=" item.isOptions && pickerOptions"
        />
      </div>

      <!--时间区间-->
      <div v-if="item.type === 'dateSection'" class="date-section">
        <span class="text">{{item.title}}</span>
        <el-date-picker
          class="date"
          :format="item.format?item.format:'yyyy-MM-dd'"
          value-format="timestamp"
          v-model="dateSection"
          type="daterange"
          align="right"
          unlink-panels
          @change="(value) => dateChange(value,item)"
          start-placeholder="开始日期"
          range-separator="至"
          end-placeholder="结束日期"
          :picker-options=" item.isOptions && pickerOptions"
        />
      </div>


      <!--时间区间 年月日-->
      <div v-if="item.type === 'dateSection_hw'" class="date-section">
        <span class="text">{{item.title}}</span>
        <el-date-picker
          class="date"
          :format="item.format?item.format:'yyyy-MM-dd'"
          value-format="timestamp"
          v-model="dateSection_hw"
          type="daterange"
          align="right"
          unlink-panels
          @change="(value) => dateChange_hw(value,item)"
          start-placeholder="开始日期"
          range-separator="至"
          end-placeholder="结束日期"
          :picker-options=" item.isOptions && pickerOptions"
        />
      </div>

      <!--时间-->
      <div v-if="item.type === 'date'">
        <span class="text">{{item.title}}</span>
        <el-date-picker
          v-model="date"
          :format="item.dateType === 'month' ? 'yyyy年MM月': 'yyyy-MM-dd'"
          class="date"
          value-format="yyyyMM"
          :type="item.dateType"
          @change="(value) => dateSingeChange(value,item)"
          placeholder="选择时间"
        />
      </div>

      <div v-if="item.type === 'input'" class="input">
        <el-input
          :value= "getQueryObjectByStr(item.model)"
          @input="(value) => onInputChange(value,item)"
          @clear="(value) => onInputChange(value,item)"
          clearable          
          :placeholder="item.placeholder"
          :maxlength="item.maxlength"
        >
          <!--<el-button @click="emitSearchChange" slot="append" icon="el-icon-search"></el-button>-->
          <!--<i @click="$search(table)" slot="suffix" class="el-input__icon el-icon-search"></i>-->
        </el-input>
      </div>
    </div>
    
    <div v-if="scope === 'SANITATION'" style="display: inline-block;" class="mr20px">
      <el-button type="success" class="btn-success-hw" @click="emitSearchChange">查 询</el-button>
    </div>
    <span class="ctrl-btn" style="flex: 1">
      <slot name="after" />
    </span>
  </div>
</template>
<script>
export default {
  props: {
    searchData: {
      type: Array,
      default() {
        return [];
      }
    },
    query: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      debug: 0,
      zlDefaultVal: [new Date().setTime(new Date(new Date().setMonth(new Date().getMonth() - 1))), new Date().getTime()],
      scope: sessionStorage.getItem("scope", ""),
      // 多选日期
      date: [],
      dateSection:[],
      dateSection_hw:[],

      pickerOptions: {
        shortcuts: [
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                new Date(new Date().setMonth(new Date().getMonth() - 1))
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                new Date(new Date().setMonth(new Date().getMonth() - 3))
              );
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近六个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(
                new Date(new Date().setMonth(new Date().getMonth() - 6))
              );
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      }
    };
  },
  methods: {
    emitSearchChange() {
      this.$emit("on-search-change", 1);
    },
    onInputChange(val, item){
      // debugger
      this.setQueryObjectByStr(item.model, val!==""?val:null);
      this.log('onInputChange',val);
      // this.emitSearchChange();
    },
    onSelectChange(val, item) {
      this.setQueryObjectByStr(item.model, val!==""?val:null);
      this.log('onSelectChange',val);
      // this.emitSearchChange();
    },
    //单选联级选择
    cascaderRadioChange(val, item) {
      if (val.length) {
        item.flat = val;
        this.setQueryObjectByStr(item.model, val[1]);
      } else {
        this.setQueryObjectByStr(item.model, null);
      }
      // this.emitSearchChange();
    },
    // cascader 值改变的时候
    cascaderChange(val, item) {
      if (val.length) {
        item.flat = val;
        this.setQueryObjectByStr(
          item.model,
          val.map(x => {
            return x[2];
          })
        );
      } else {
        this.setQueryObjectByStr(item.model, []);
      }
      // this.emitSearchChange();
    },
    // cascader_two 值改变的时候
    cascaderTwoChange(val, item) {
      if (val.length) {
        item.flat = val;
        this.setQueryObjectByStr(
          item.model,
          val.map(x => {
            return x[1] || x[0];
          })
        );
      } else {
        this.setQueryObjectByStr(item.model, []);
      }
      // this.emitSearchChange();
    },
    // 当 cascader2 值改变的时候  by 马波
    // 扩展这个组件的原因：枚举的value是数字，但是传给api要用汉字。
    // 以后的版本统一传汉字
    onCascaderNewChange(val, item) {
      if (val.length) {
        item.flat = val;
        this.setQueryObjectByStr(
          item.model,
          val.map(x => {
            let _t = x.length == 2 ? x[1] : x[0];
            return _getNameByVal(_t, item.option);
          })
        );
      } else {
        this.setQueryObjectByStr(item.model, []);
      }
      // this.emitSearchChange();

      function _getNameByVal(v, list) {
        for (let node of list) {
          let res = _findNode(v, node);
          // debugger
          if (res) {
            return res.label;
          }
        }
      }

      function _findNode(v, node) {
        let res = null;
        //若结点值符合条件
        if (node.value == v) {
          res = node;
        }
        //若有子节点
        else if (node.children && node.children.length) {
          for (let _node of node.children) {
            let tmp = _findNode(v, _node);
            if (tmp) {
              res = tmp;
              break;
            }
          }
        }
        return res;
      }
    },
    // 日期选择控件切换的回调
    dateChange(val, item) {
      if (val === null) {
        this.setQueryObjectByStr(item.modelName[0], null);
        this.setQueryObjectByStr(item.modelName[1], null);
      } else {
        //console.log(new Date(val[0]) ,new Date(val[1]))
        this.setQueryObjectByStr(item.modelName[0], val[0]);
        this.setQueryObjectByStr(
          item.modelName[1],
          this.$dayjs(
            this.$dayjs(val[1]).format("YYYY-MM-DD 23:59:59")
          ).valueOf()
        );
      }
      // this.emitSearchChange();
    },
    dateChange_zl(val, item) {
      console.log(item)
      let s = item.modelName[0]
      let e = item.modelName[1]
      if (!this.zlDefaultVal) {
        this.query[s] = null;
        this.query[e] = null;
      } else {
        this.query[s]= this.zlDefaultVal[0];
        this.query[e]= this.$dayjs(
          this.$dayjs(this.zlDefaultVal[1]).format("YYYY-MM-DD 23:59:59")
        ).valueOf();
      }
      // this.emitSearchChange();
    },
    // 日期选择控件切换的回调
    dateChange_hw(val, item) {
      if (val === null) {
        this.setQueryObjectByStr(item.modelName[0], null);
        this.setQueryObjectByStr(item.modelName[1], null);
      } else {
        //console.log(new Date(val[0]) ,new Date(val[1]))
        this.setQueryObjectByStr(
          item.modelName[0],
          this.$filters.str2ymd(val[0])
        );
        this.setQueryObjectByStr(
          item.modelName[1],
          this.$filters.str2ymd(
            this.$dayjs(
              this.$dayjs(val[1]).format("YYYY-MM-DD 23:59:59")
            ).valueOf()
          )
        );
      }
      // this.emitSearchChange();
    },
    // 日期单选控件切换的回调
    dateSingeChange(val, item) {
      this.setQueryObjectByStr(item.model, val);
      // this.emitSearchChange();
    },
    //
    checkMultiple(val, item) {
      this.setQueryObjectByStr(item.modelName, val);
      // this.emitSearchChange();
    },
    setQueryObjectByStr(str, val) {
      if (str.indexOf(".") != -1) {
        let arr = str.split(".");
        let obj = this.query;
        let len = arr.length;
        //取到object
        for (let i = 0; i < len; i++) {
          if (i != len - 1) {
            //i!=1
            if (arr[i].trim()) {
              obj = obj[arr[i]];
            }
          }
        }
        //给obejct的具体字段赋值
        obj[arr[len - 1]] = val;
        this.log("setQueryObjectByStr",obj[arr[len - 1]]);
      } else {
        this.query[str] = val;
      }
    }
  },
  computed: {
    searchDataIsVisible() {
      return this.searchData.filter(t => !t.hide);
    },
    getQueryObjectByStr() {
      let self = this;
      return function(str) {
        if (str.indexOf(".") != -1) {
          let arr = str.split(".");
          let obj = self.query;
          arr.forEach(item => {
            if (item.trim()) {
              obj = obj[item];
            }
          });
          self.log("getQueryObjectByStr",obj);
          return obj;
        } else {
          return self.query[str];
        }
      };
    }
  },
  mounted() {
    for (let i = 0; i < this.searchData.length; i++) {
      if (
        this.searchData[i].type === "multiple" &&
        this.searchData[i].model.length !== 0
      ) {
        this.query[this.searchData[i].modelName] = this.searchData[i].model;
      }
    }
  }
};
</script>
<style lang="sass">
.search-box
  display: flex
  flex-wrap: wrap
  margin-bottom: 12px

  .ctrl-btn
    display: flex
    height: 28px
    align-items: center

  .text
    margin-right: 14px
    margin-bottom: 5px
    font-weight: 400
    color: $-font-color-1

  .form
    margin-right: 15px
    margin-bottom: 8px

  .date
    width: 270px

  .el-select
    width: 120px

  .el-cascader
    width: 120px

  .input
    width: 200px

  /deep/ .el-range-separator
    width: 13%
</style>