import Layout from '@views/Layout.vue'

export default {
    path: '/district-management',
    component: Layout,
    children: [{ //标段合同
            name: 'District-contract',
            path: '/district-management/district-contract',
            component: () =>
                import ('@views/district-management/district-contract/district-contract'),
                meta: {
                    keepAlive: true,
                    isBack: false,
                    title: '标段合同',
                }
        },{ //新增合同
            name: 'Add-contract',
            path: '/district-management/district-contract/add-contract',
            component: () =>
                import ('@views/district-management/district-contract/add-contract'),
                meta: {
                    keepAlive: true,
                    isBack: false,
                    title: '新增合同',
                }
        },{ //编辑合同
            name: 'Edit-contract',
            path: '/district-management/district-contract/edit-contract',
            component: () =>
                import ('@views/district-management/district-contract/edit-contract'),
                meta: {
                    keepAlive: true,
                    isBack: false,
                    title: '编辑合同',
                }
        },{ //查看合同
            name: 'Info-contract',
            path: '/district-management/district-contract/info-contract',
            component: () =>
                import ('@views/district-management/district-contract/info-contract'),
                meta: {
                    keepAlive: true,
                    isBack: false,
                    title: '合同信息',
                }
        }
    ]
}