/**
 * 代码级别的配置文件
 */
export default {    
    title: '智慧环卫管理系统',
    dataTitle: '智慧环卫管理系统大数据云图',
    videoApi: process.env.VUE_APP_TONGTIANXING,
    uploadUrl() {
        return process.env.VUE_APP_API + '/iot/files/upload'
    },
    uploadFilesUrl(){
        return process.env.VUE_APP_API + '/iot/files/commonUpload'
    },
    labelPosition: 'left',
    labelWidth: '108px',
    color_n: '#0575e6',
    color_z: '#15d123',
    color_w: '#e6a23c',
    color_hw: '#1ABC9C',
    // ailivingToken: sessionStorage.getItem('loginData') && JSON.parse(sessionStorage.getItem('loginData')).Token,
}
