import Axios from 'axios'
import router from '../router'
import configs from '../config'
import {Message} from 'element-ui'
// import qs from 'qs'

(function (win) {
    let ERR_NUMBER = 0;//错误提示的弹窗数量

    Axios.defaults.baseURL = process.env.VUE_APP_API
    // Axios.defaults.baseURL = "https://easydoc.xyz/mock/EBxK2oRz/"

    Axios.defaults.timeout = 60000
    // Axios 请求拦截器
    Axios.interceptors.request.use(config => {
        config.headers['Content-Type'] = 'application/json;charset=UTF-8'
        // const Token = JSON.parse(sessionStorage.getItem('loginData')).Token

        //【1】 上传文件的API都要特殊处理一下
        let uploadApis = ['/iot/files/upload', '/iot/files/commonUpload', '/iot/files/redeem']
        let tmp = uploadApis.indexOf(config.url);
        if (tmp != -1) {
            return config;
        }

        //【2】 这段代码有缺陷，需要重构
        if (config.method === 'get') {
            config.baseURL = configs.videoApi
            config.url = configs.videoApi + config.url
            return config
        }

        //【3】 处理中正常的post请求
        config.data = {
            //id这个字段最早的CTO定的，不知道有什么用，可能之前是想做成递增的id，对请求进行记录，同时便于第三方开
            //发者提交工单；最早的CTO是从阿里物联网团队出来的，他们做的是物联网的开放平台，最早我们这套系统也是向
            //着开放平台出发的。现在后台完全不处理这个字段。 by 李允
            id: 1500000000000,
            params: config.data,
            request: {
                // apiName: config.url,
                apiVer: process.env.VUE_APP_API_VERSION,
            },
            version: process.env.VUE_APP_API_JSON_VERSION,
            client: `web ${process.env.VUE_APP_VERSION}`
        }
        if (sessionStorage.getItem('loginData')) {
            config.data.request.ailivingToken = JSON.parse(sessionStorage.getItem('loginData')).Token
        }
        // config.url = '/iot/api'
        return config
    })

    // Axios 响应拦截器
    Axios.interceptors.response.use(res => {
        if (res.status === 200) {
            // 第三方请求
            if (res.config.baseURL === process.env.VUE_APP_TONGTIANXING) {
                if (res.data.cmsserver === 1) return res.data
            }
            // 正常请求
            if (res.data.code === 200){ return res.data.data || res.data }else if (res.data.code === 5163) {
            // if (res.data.code === 5163 || res.data.code === 200) {
                router.replace({
                    name: 'Login'
                })
                showError('登录失效啦，请您重新登录喔')
            } else {
                showError(res.data.message);
                return Promise.reject(res.data.message)
            }
        } else {
            return Promise.reject(res.response)
        }
    }, err => {
        showError('网络异常，请检查您的网络设置')
        return Promise.reject(err.response)
    })

    function showError(msg,isHideErr){
        if(!isHideErr && ERR_NUMBER<1){
            ERR_NUMBER++;
            Message({
                type:"error",
                message:msg,
                onClose(){
                    ERR_NUMBER--;
                }
            });            
        }
    }
})(window);