import Layout from '@views/Layout.vue'
export default {
    path: '/customer',
    component: Layout,
    children: [
        { //客户报表
            path: '/business-report/customer-statements',
            name: 'Customer-statements',
            component: () =>
                import ('@views/business-report/customer-statements/customer-statements'),
            meta: {
                keepAlive: true,
                isBack: false
            }
        },
        { //驾驶员报表
            path: '/business-report/driver-report',
            name: 'Driver-report',
            component: () =>
                import ('@views/business-report/driver-report/driver-report'),
            meta: {
                keepAlive: true,
                isBack: false
            }
        },
        { //车辆报表
            path: '/business-report/vehicl-report',
            name: 'Vehicl-report',
            component: () =>
                import ('@views/business-report/vehicl-report/vehicl-report'),
            meta: {
                keepAlive: true,
                isBack: false
            }
        },
        { //业务类型报表
            path: '/business-report/business-type-report',
            name: 'Business-type-report',
            component: () =>
                import ('@views/business-report/business-type-report/business-type-report'),
            meta: {
                keepAlive: true,
                isBack: false
            }
        },
        { //地点报表
            path: '/business-report/site-report',
            name: 'Site-report',
            component: () =>
                import ('@views/business-report/site-report/site-report'),
            meta: {
                keepAlive: true,
                isBack: false
            }
        },
        { //订单明细
            path: '/business-report/order-details',
            name: 'Order-details',
            component: () =>
                import ('@views/business-report/order-details')
        },
        { //客户报表订单明细
            path: '/business-report/curstomer-details',
            name: 'Curstomer-details',
            component: () =>
                import ('@views/business-report/curstomer-details')
        },
        { //订单详情
            path: '/business-report/search-derails',
            name: 'Search-derails',
            component: () =>
                import ('@views/business-report/search-derails')
        },
        { //状态报表
            path: '/business-report/status-report',
            name: 'Status-report',
            component: () =>
                import ('@views/business-report/status-report/status-report'),
                meta: {
                    keepAlive: true,
                    isBack: false
                }
        },
    ]
}