<template>
  <el-upload
    action="http://47.100.196.44:8080/iot/files/commonUpload"
    :on-remove="onRemove"
    :on-exceed="handleExceed"
    multiple
    :limit="limit"
    :accept="accept"
    :file-list="getFileList"
    :data="uploadData"
    :on-preview="downLoad"
    :http-request="uploadRequest"
    v-bind="$attrs"
  >
    <div style="font-size:9px" v-if="isDebug">{{fileList}}</div>
    <el-button size="small" type="primary">点击上传</el-button>
    <div slot="tip" class="el-upload__tip">
      只能最多
      <b>{{limit}}</b> 个文件，
      <span v-if="accept">
        限定
        <b>{{acceptFiles}}</b> 格式，
      </span>且单个文件大小不超过
      <b>{{getMaxSize}}</b>
    </div>
  </el-upload>
</template>
<script>
import FileSaver from "file-saver";
export default {
  name: "ayl-upload-files",

  props: {
    value: {
      //文件fileKey地址串，形如：adfasdf,asdfasdfas,asdfadsf,dddaaddas
      type: Array,
      default() {
        return [];
      } //形如[{name: 'food.jpeg', url: 'fileKey'}, {name: 'food2.jpeg', url: 'fileKey'}]
    },
    limit: {
      //上传文件的个数
      type: Number,
      default: 3
    },
    accept: {
      //上传文件的格式
      type: String,
      default: "image/png,image/jpeg,image/jpg"
    },
    maxSize: {
      //上传文件的最大尺寸，单位是Kb
      type: Number,
      default: 500
    },
    isDebug: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      debug: this.isDebug,
      fileList: this.value,
      uploadData: {
        ailivingToken: JSON.parse(sessionStorage.getItem("loginData")).Token
      }
    };
  },

  computed: {
    acceptFiles() {
      let self = this;
      let arr = [];
      let acceptMap = {
        ".3gpp ": "   audio/3gpp, video/3gpp",
        ".ac3  ": "  audio/ac3",
        ".asf  ": "     allpication/vnd.ms-asf",
        ".au   ": "        audio/basic",
        ".css  ": "         text/css",
        ".csv  ": "         text/csv",
        ".doc  ": "  application/msword    ",
        ".dot  ": "  application/msword    ",
        ".dtd  ": "  application/xml-dtd    ",
        ".dwg  ": "  image/vnd.dwg    ",
        ".dxf  ": "    image/vnd.dxf",
        ".gif  ": "          image/gif    ",
        ".htm  ": "  text/html    ",
        ".html ": "   text/html    ",
        ".jp2  ": "          image/jp2    ",
        ".jpe  ": "     image/jpeg",
        ".jpeg ": "   image/jpeg",
        ".jpg  ": "        image/jpeg    ",
        ".js   ": "    text/javascript, application/javascript    ",
        ".json ": "   application/json    ",
        ".mp2  ": "  audio/mpeg, video/mpeg    ",
        ".mp3  ": "  audio/mpeg    ",
        ".mp4  ": "  audio/mp4, video/mp4    ",
        ".mpeg ": "   video/mpeg    ",
        ".mpg  ": "  video/mpeg    ",
        ".mpp  ": "  application/vnd.ms-project    ",
        ".ogg  ": "  application/ogg, audio/ogg    ",
        ".pdf  ": "  application/pdf    ",
        ".png  ": "  image/png    ",
        ".pot  ": "  application/vnd.ms-powerpoint    ",
        ".pps  ": "  application/vnd.ms-powerpoint    ",
        ".ppt  ": "  application/vnd.ms-powerpoint    ",
        ".rtf  ": "          application/rtf, text/rtf    ",
        ".svf  ": "         image/vnd.svf    ",
        ".tif  ": "       image/tiff    ",
        ".tiff ": "      image/tiff    ",
        ".txt  ": "         text/plain    ",
        ".wdb  ": "  application/vnd.ms-works    ",
        ".wps  ": "  application/vnd.ms-works    ",
        ".xhtml": "    application/xhtml+xml    ",
        ".xlc  ": "    application/vnd.ms-excel    ",
        ".xlm  ": "  application/vnd.ms-excel    ",
        ".xls  ": "         application/vnd.ms-excel    ",
        ".xlt  ": "    application/vnd.ms-excel    ",
        ".xlw  ": "    application/vnd.ms-excel    ",
        ".xml  ": "  text/xml, application/xml    ",
        ".zip  ": "          aplication/zip    ",
        ".xlsx ":
          "    application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      };
      let newMap = {};
      for (let k in acceptMap) {
        if (!newMap[acceptMap[k].trim()])
          newMap[acceptMap[k].trim()] = k.trim();
        else {
          newMap[acceptMap[k].trim()] += k.trim();
        }
      }
      if (self.accept)
        arr = self.accept.split(",").map(item => {
          return newMap[item];
        });
      return arr.join("");
    },
    getFileList() {
      let self = this;
      return self.fileList;
    },
    getMaxSize() {
      let self = this;
      if (self.maxSize < 1024) return self.maxSize + " kb";
      else {
        return self.maxSize / 1024 + " mb";
      }
    }
  },

  methods: {
    onRemove(file, fileList) {
      this.log("onRemove", file, fileList);
      this.fileList = fileList;
      this.$emit("input", this.fileList);
    },
    handleExceed(files, fileList) {
      let self = this;
      self.$message.warning(
        `当前限制选择 ${self.limit} 个文件，本次选择了 ${
          files.length
        } 个文件，多选择了 ${files.length +
          fileList.length -
          self.limit} 个文件`
      );
    },
    beforeUpload(file) {
      let self = this;
      const isPG = !self.accept || self.accept.includes(file.type); //限制图片格式
      const isLt2M = file.size < self.maxSize * 1024; //限制图片大小
      /*
      const isSize = new Promise(function(resolve,reject) {
          let width = 600
          let height = 400
          let _URL = window.URL || window.webkitURL
          let img = new Image()
          img.onload = function() {
              let valid = img.width >= width && img.height >= height
              valid ? resolve() : reject();
          }
          img.src = _URL.createObjectURL(file)
      }).then(() => {
          return file;
      },()=>{
          this.$message.error('上传图片像素要大于600*400!');
          return promise.reject();
      });*/
      if (!isPG) {
        self.$message.error(`上传 ${file.name} 出错，
        只能上传 ${self.acceptFiles} 格式`);
      }
      if (!isLt2M) {
        self.$message.error(`上传 ${file.name} 出错，
        大小不能超过 ${self.getMaxSize} kb`);
      }
      return isPG && isLt2M; //&& isSize
    },
    async uploadRequest(param) {
      let self = this;
      self.log(param);

      let file = param.file;
      if (!self.beforeUpload(file)) {
        file.abort();
        return;
      }

      let formData = new FormData();
      formData.append("file", file);
      formData.append(
        "ailivingToken",
        JSON.parse(sessionStorage.getItem("loginData")).Token
      );
      try {
        let res = await self.$api_hw.uploadFile(formData); //放一个由阿里云OSS生成的filekey就行
        this.log(res);
        //成功，更新数据
        self.fileList.push({
          name: res.fileName,
          url: res.fileKey
        });
        self.$emit("input", self.fileList);
      } catch (e) {
        self.$message.error(file.name + " 上传失败：" + e);
        file.abort();
      }
    },

    // 下载内容有问题需要继续完善
    downLoadFile(res, name) {
      let blob = new Blob([res], {
        type: "image/jpeg"
      });
      FileSaver.saveAs(blob, name);
    },

    // 下载
    async downLoad(file) {
      console.log(file);
      await this.$api_hw
        .common_getFileUrlFromOSS({
          ailivingToken: JSON.parse(sessionStorage.getItem("loginData")).Token,
          fileKey: file.url
        })
        .then(res => {
          console.log(res);
          window.location.href = res;
          // this.downLoadFile(res,file.name)
        })
        .catch(e => {
          this.$message.error("文件下载失败");
        });
    }
  },

  watch: {
    value: function(v, ov) {
      let self = this;
      self.fileList = v; //很奇怪，还必须这样？？
      self.log(
        "---------ayl-upload-files.watch.fileList.change---------",
        v,
        ov
      );
    }
  },

  created() {},

  mounted() {
    // this.log("--------- ayl-upload-files mounted ---------");
  },

  updated() {
    // this.log("--------- ayl-upload-files updated ---------");
  }
};
</script>
<style scoped>
</style>