import Layout from '@views/Layout.vue'

export default {
  path: '/consign-order',
  component: Layout,
  children: [{
    name: 'consignOrder',
    path: '/consign-order/consign-order',
    component: () => import('@views/consign-order/consign-order'),
    meta: {
      keepAlive: true,
    }
  },
  {
    name: 'recordOrder',
    path: '/consign-order/record-order',
    component: () => import('@views/consign-order/record-order')
  },
  {
    name: 'independentRecordOrder',
    path: '/consign-order/independent-record-order',
    component: () => import('@views/consign-order/independent-record-order')
  }]
}
