export default {
    /**
     * 输入0-9 包含小数点的数字
     */
    isNumber(rule, value, callback) {
        const reg = /^[.0-9]*$/;
        if ((!reg.test(value)) && (value != '' && value !=null && value != undefined)) {
            callback(new Error('请输入规范数字'));
        } else {
            callback();
        }
    },
    isTwoDecimal(rule, value, callback) {
        if (String(value).toString().split('.')[1] && String(value).toString().split('.')[1].length > 2) {
            callback(new Error('最多两位小数'));
        } else {
            callback()
        }
    },
    // 三位小数
    isThreeDecimal(rule, value, callback) {
        if (String(value).toString().split('.')[1] && String(value).toString().split('.')[1].length > 3) {
            callback(new Error('最多三位小数'));
        } else {
            callback()
        }
    },
    //  四位小数
    isForsDecimal(rule, value, callback) {
        if (String(value).toString().split('.')[1] && String(value).toString().split('.')[1].length < 4) {
            callback(new Error('请精确到小数点后四位'));
        } else {
            callback()
        }
    },
    // 一位小数
    isOneDecimal(rule, value, callback) {
        if (String(value).toString().split('.')[1] && String(value).toString().split('.')[1].length > 1) {
            callback(new Error('最多一位小数'));
        } else {
            callback()
        }
    },
    // 整数
    isInteger(rule, value, callback) {
        if (value && !(/^[0-9]*[1-9][0-9]*$/.test(value))) {
            callback(new Error('请填入整数'));
        } else {
            callback()
        }
    },
    /** 是否包含空格 */
    isContainBlank(rule, value, callback) {
        if (value == '' || value == undefined || value == null) {
            return callback()
        } else if (value.indexOf(" ") >= 0) {
            return callback(new Error('输入内容不能包含空格'))
        } else {
            return callback()
        }
    },
    /** 是否为正数 */
    isPositiveNum(rule, value, callback) {
        if (value == '' || value == undefined || value == null) {
            return callback()
        } else if (value < 0) {
            return callback(new Error('请输入正数'))
        } else {
            return callback()
        }
    },

    /** 是否为手机号码 长度为11位的数字 */
    validatePhone(rule, value, callback) {
        const reg = /^[0-9]{11}/;
        if (value == '' || value == undefined || value == null) {
            callback(new Error('请正确输入11位手机号码'));
        } else if ((!reg.test(value)) && value != '') {
            callback(new Error('请正确输入11位手机号码'));
        } else if (value.length != 11) {
            callback(new Error('请正确输入11位手机号码'));
        } else {
            callback();
        }
    },
    /** 是否为手机号码 长度为11位的数字。可以为空 */
    validatePhoneBlank(rule, value, callback) {
        const reg = /^[0-9]{11}/;
        if (value == '' || value == undefined || value == null) {
            callback();
        } else if ((!reg.test(value)) && value != '') {
            callback(new Error('请正确输入11位手机号码'));
        } else if (value.length != 11) {
            callback(new Error('请正确输入11位手机号码'));
        } else {
            callback();
        }
    },
    /** 是否身份证号码 */
    validateIdNo(rule, value, callback) {
        const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
        if (value == '' || value == undefined || value == null) {
            callback();
        } else {
            if ((!reg.test(value)) && value != '') {
                callback(new Error('请输入正确的身份证号码'));
                return false;
            } else {
                callback();
            }
        }
    },
    //若有值，必须是正整数
    positiveInteger(rule, value, callback) {
        if (value == '' || value == undefined || value == null) {
            return callback()
        } else {
            const reg = /^[0-9]+$/;
            if (!reg.test(value)) {
                return callback(new Error('请输入≥0的整数数字'))
            } else {
                return callback()
            }
        }
    },
}