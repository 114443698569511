<template>
  <div class="search-wrap">
    <div v-for="(item, index) in searchData" :key="index">
      <!-- 输入框 -->
      <div v-if="item.type == 'input'" class="search-item">
        <span v-if="item.title" style="margin-right: 8px">{{ item.title }}</span>
        <el-input
          style="width: 160px"
          v-model="searchQuery[item.model]"
          :placeholder="item.placeholder"
          :clearable="!item.clearable"
        ></el-input>
      </div>
      <!-- 下拉选择器 -->
      <div v-if="item.type == 'select'" class="search-item">
        <span v-if="item.title" style="margin-right: 8px">{{ item.title }}</span>
        <el-select
          style="width: 160px"
          v-model="searchQuery[item.model]"
          :placeholder="item.placeholder"
          :clearable="!item.clearable"
          @change="cascaderChange(item.model)"
        >
          <el-option
            v-for="item in item.option"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <!-- 级联选择器 -->
      <div v-if="item.type == 'cascader'" class="search-item">
        <span v-if="item.title" style="margin-right: 8px">{{ item.title }}</span>
        <el-cascader
          style="width: 160px"
          v-model="searchQuery[item.model]"
          :options="item.option"
          :placeholder="item.placeholder"
          :clearable="!item.clearable"
        ></el-cascader>
      </div>
      <!-- 普通日期范围 获取时间戳 -->
      <div v-if="item.type == 'dateRange'" class="search-item">
        <span style="margin-right: 8px">{{ item.title }}</span>
        <el-date-picker
          style="width: 220px"
          v-model="dateRangeValue"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp"
          @change="dateRangeChange(item.model)"
        >
        </el-date-picker>
      </div>

      <div v-if="item.type === 'dateSection_zl'" class="search-item">
        <span style="margin-right: 8px">{{item.title}}</span>
        <el-date-picker
          style="width: 220px"
          format="yyyy-MM-dd"
          value-format="timestamp"
          v-model="zlDefaultVal"
          type="daterange"
          align="right"
          unlink-panels
          @change="(value)=>dateChange_zl(value,item)"
          start-placeholder="开始日期"
          range-separator="——"
          end-placeholder="结束日期"
          :picker-options=" item.isOptions && pickerOptions"
        />
      </div>
    </div>
    <div class="search-item">
      <slot name="after"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "AylNewSearch",
  components: {},
  props: {
    searchData: {
      type: Array,
      default: () => [],
    },
    searchQuery: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dateRangeValue: [],
      zlDefaultVal: [new Date().setTime(new Date(new Date().setMonth(new Date().getMonth() - 3))), new Date().getTime()],
    };
  },
  watch: {
    searchQuery: {
      handler() {
        this.$emit("getSearchPramas", this.searchQuery);
      },
      deep: true,
    },
  },
  mounted() {
    for (let item of this.searchData) {
      if(item.type ==="dateSection_zl"){
        this.$nextTick(()=>{
          this.dateChange_zl();
        })
      }
    }
  },
  methods: {
    emitSearchChange() {
      this.$emit("on-search-change", 1);      
    },
    // 处理日期
    dateRangeChange(val) {
      if (this.dateRangeValue) {
        this.searchQuery[val[0]] = this.dateRangeValue[0];
        this.searchQuery[val[1]] = this.dateRangeValue[1];
      } else {
        this.searchQuery[val[0]] = null;
        this.searchQuery[val[1]] = null;
      }
    },
    cascaderChange(val) {
      if (this.searchQuery[val] === "") {
        this.searchQuery[val] = null
      }
    },

    dateChange_zl(val, item) {
      console.log(this.searchQuery)
      if (!this.zlDefaultVal) {
        this.searchQuery.startTime = this.searchQuery.endTime= null;
      } else {
        this.searchQuery.startTime= this.zlDefaultVal[0];
        this.searchQuery.endTime= this.$dayjs(
          this.$dayjs(this.zlDefaultVal[1]).format("YYYY-MM-DD 23:59:59")
        ).valueOf();
      }
      this.emitSearchChange();
    },
  },
};
</script>

<style lang="css" scoped>
.search-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.search-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-right: 20px;
}
</style>
