import Layout from '@views/Layout.vue'

export default {
    path: '/oil-management',
    component: Layout,
    children: [{
        name: 'OilInfo',
        path: '/oil-info/oil-info',
        component: () =>
            import('@views/oil-management/oil-info/oil-info'),
        meta: {
            keepAlive: true,
            isBack: false
        }
    }, {
        name: 'OilInfoDetails',
        path: '/oil-info/oil-info/details',
        component: () =>
            import('@views/oil-management/oil-info/oil-info-details'),
    }, {
        name: 'OilReal',
        path: '/oil-real/oil-real',
        component: () =>
            import('@views/oil-management/oil-real/oil-real'),
        meta: {
            keepAlive: true,
            isBack: false
        }
    },
        , {
        name: 'OilQuery',
        path: '/oil-query/oil-query',
        component: () =>
            import('@views/oil-management/oil-query/oil-query'),
        meta: {
            // keepAlive: true,
            isBack: false,
            title: '油量查询',
        }
    },
    {
        name: 'OilAdd',
        path: '/oil-add/oil-add',
        component: () =>
            import('@views/oil-management/oil-add/oil-add'),
        meta: {
            keepAlive: true,
            isBack: false
        }
    }, {
        name: 'OilDrop',
        path: '/oil-drop/oil-drop',
        component: () =>
            import('@views/oil-management/oil-drop/oil-drop'),
        meta: {
            keepAlive: true,
            isBack: false
        }
    }, {
        name: 'OilDropDetails',
        path: '/oil-drop/oil-drop/details',
        component: () =>
            import('@views/oil-management/oil-drop/oil-drop-details'),
    },
    {
        name: 'OilAddDetails',
        path: '/oil-add/oil-add-details',
        component: () =>
            import('@views/oil-management/oil-add/oil-add-details'),
    },
    {
        name: 'OilRealDetails',
        path: '/oil-real/oil-real-details',
        component: () =>
            import('@views/oil-management/oil-real/oil-real-details'),
    },

    ]
}
