import Axios from 'axios'

//环卫系统的API
export default {
    //////////公共的、通用的API，和具体功能无关////////////////////////////////////////////////////////////////////
    /**
     * 获取租户所属企业信息
     */
    async common_getTenantCompanyInfo(params) {
        return await Axios.post(`/sanitation/commonService/getTenantCompanyInfo`, params)
    },

    async common_bidManageGet(params) {
        return await Axios.post(`/sanitation/bidManage/get`, params)
    },

    /**
     * SIM卡查询
     */
    async common_simInfoByQueryCondition(params) {
        return await Axios.post(`/IOV/simInfo/listSimInfoByQueryCondition`, params)
    },
    /**
     * excel导出
     */
    async common_exportExcel(params) {
        return await Axios.post(`/excel/export`, params)
    },
    /**
     * 上传单文件，返回2个字段{data}
     */
    async uploadFile(params) {
        return await Axios.post(`/iot/files/commonUpload`, params)
    },
    /**
     * 从阿里云OSS获取单文件的地址
     */
    async common_getFileUrlFromOSS(params) {
        return await Axios.post(`/iot/files/redeem`, params)
    },
    /**
     * 查询标段筛选条件列表 for 下拉列表
     */
    async common_getBidNameAndIdList(params) {
        return await Axios.post(`/sanitation/bidManage/getBidNameAndIdList`, params)
    },
    /**
     * 根据租户获取网格信息，用于下拉框
     */
    async common_getDistinctGrid(params) {
        return await Axios.post(`/sanitation/commonService/getDistinctGrid`, params)
    },
    /**
     * 根据租户获取线路信息，用于下拉框
     */
    async common_getDistinctRoute(params) {
        return await Axios.post(`/sanitation/commonService/getDistinctRoute`, params)
    },
    /**
     * 根据租户获取标段信息，用于下拉框
     */
    async common_getDistinctBid(params) {
        return await Axios.post(`/sanitation/commonService/getDistinctBid`, params)
    },
    /**
     * 根据租户获取养护信息，用于下拉框
     */
    async common_getDistinctSelfCheck(params) {
        return await Axios.post(`/sanitation/commonService/getDistinctSelfCheck`, params)
    },
    /**
     * 养护信息新接口（上面的不用），用于下拉框
     */
    async common_getDistinctAccident(params) {
        return await Axios.post(`/sanitation/commonService/getDistinctAccident`, params)
    },

    /**
     * 根据租户获取主管单位信息，用于下拉框
     */
    async common_getDistinctManageUnit(params) {
        return await Axios.post(`/sanitation/commonService/getDistinctManageUnit`, params)
    },
    /**
     * 获取所有甲方单位，用于下拉框
     */
    async common_listFirstParty(params) {
        return await Axios.post(`/sanitation/contract/listFirstParty`, params)
    },
    /**
     * 通用枚举定义的代码描述查询
     * 参数typeName可取：PersonType, CarType, ComplainExplainType, CheckStatusType, ComplainObjectType, RegulationType
     */
    async common_getCodeDesList(params) {
        return await Axios.post(`/sanitation/common/getCodeDesList`, params)
    },
    /**
     * 标段详情-get
     */
    async common_getBidInfo(params) {
        return await Axios.post(`/sanitation/bidManage/get`, params)
    },



    //////////标段管理////////////////////////////////////////////////////////////////////

    /**
     * 车辆管理-车辆列表
     */
    async vehicleManagement_listSanitationCarInfoByCondition(params) {
        return await Axios.post(`/sanitation/car/listSanitationCarInfoByCondition`, params)
    },
    /**
     * 车辆管理-新增-insert
     */
    async vehicleManagement_insert(params) {
        return await Axios.post(`/sanitation/car/insert`, params)
    },

    /**
     * 车辆管理-详情-getCarInfoByPlateNumber
     */
    async vehicleManagement_getCarInfoByPlateNumber(params) {
        return await Axios.post(`/sanitation/car/getCarInfoByPlateNumber`, params)
    },
    /**
     * 车辆管理-更新-updateByPlateNumber
     */
    async vehicleManagement_updateByPlateNumber(params) {
        return await Axios.post(`/sanitation/car/updateByPlateNumber`, params)
    },

    /**
     * 车辆管理-所属企业单独接口-updateByPlateNumber
     */
    async vehicleManagement_getByTenantId(params) {
        return await Axios.post(`/sanitation/company/getByTenantId`, params)
    },

    /**
     * 车辆管理-车辆作废-obsoleteCar
     */
    async vehicleManagement_obsoleteCar(params) {
        return await Axios.post(`/sanitation/car/obsoleteCar`, params)
    },
    /**
     * 车辆管理-车辆匹配设备列表
     */
    async deviceInfo_listMatchCarDevice(params) {
        return await Axios.post(`/sanitation/deviceInfo/listMatchCarDevice`, params)
    },
    /**
     * 车辆管理-匹配设备
     */
    async car_bindDevice(params) {
        return await Axios.post(`/sanitation/car/bindDevice`, params)
    },
    /**
     * 车辆管理-重新匹配设备
     */
    async car_reBindDevice(params) {
        return await Axios.post(`/sanitation/car/reBindDevice`, params)
    },
    /**
     * 车辆管理-解除绑定
     */
    async car_unBindDevice(params) {
        return await Axios.post(`/sanitation/car/unBindDevice`, params)
    },


    /**
     * 车队管理-insert
     */
    async fleetManagement_insert(params) {
        return await Axios.post(`/sanitation/carTeam/insert`, params)
    },
    /**
     * 车队管理-update
     */
    async fleetManagement_update(params) {
        return await Axios.post(`/sanitation/carTeam/update`, params)
    },
    /**
     * 车队管理-delete
     */
    async fleetManagement_delete(params) {
        return await Axios.post(`/sanitation/carTeam/delete`, params)
    },
    /**
     * 车队管理-list
     */
    async fleetManagement_list(params) {
        return await Axios.post(`/sanitation/carTeam/list`, params)
    },
    /**
     * 车队管理-getById
     */
    async fleetManagement_getById(params) {
        return await Axios.post(`/sanitation/carTeam/getById`, params)
    },
    /**
     * 车队管理-获取公司列表及公司下车队信息
     */
    async carTeam_listCompanyAndCarTeam(params) {
        return await Axios.post(`/sanitation/carTeam/listCompanyAndCarTeam`, params)
    },
    /**
     * 车队管理-分配/移除车队
     */
    async carTeam_allocateCar(params) {
        return await Axios.post(`/sanitation/carTeam/allocateCar`, params)
    },
    /**
     * 车队管理-查询已分配/未分配车辆列表（ps:查询已分配需要传入车队和公司id，查询未分配groupId为null）
     */
    async carTeam_listCarCaseAllocationr(params) {
        return await Axios.post(`/sanitation/carTeam/listCarCaseAllocation`, params)
    },
    /**
     * 人员管理-人员列表
     */
    async personnelInfoList(params) {
        return await Axios.post(`/sanitation/person/list`, params)
    },
    /**
     * 人员管理-新增人员
     */
    async personnelInfoInsert(params) {
        return await Axios.post(`/sanitation/person/insert`, params)
    },
    /**
     * 人员管理-匹配人员
     */
    async personnelInfoListUninstallDeviceIdByObjectType(params) {
        return await Axios.post(`/sanitation/device/listUninstallDeviceIdByObjectType`, params)
    },
    /**
     * 人员管理-离职某人
     */
    async personnelInfo_updatePersonStatus(params) {
        return await Axios.post(`/sanitation/person/updatePersonStatus`, params)
    },
    /**
     * 人员管理-编辑修改
     */
    async personnelInfo_personUpdate(params) {
        return await Axios.post(`/sanitation/person/update`, params)
    },

    /**
     * 人员管理-获取人员详情
     */
    async personnelInfo_personGetDetail(params) {
        return await Axios.post(`/sanitation/person/getDetail`, params)
    },
    /**
     * 人员管理-解除绑定
     */
    async personnelInfo_personGetUnbindDevice(params) {
        return await Axios.post(`/sanitation/person/unbindDevice`, params)
    },
    /**
     * 人员管理-人员匹配设备列表
     */
    async personnelInfo_personListMatchPersonDevice(params) {
        return await Axios.post(`/sanitation/deviceInfo/listMatchPersonDevice`, params)
    },
    /**
     * 人员管理-匹配设备
     */
    async personnelInfo_personBindDevice(params) {
        return await Axios.post(`/sanitation/person/bindDevice`, params)
    },

    /**
     * 国土地块-listPlot
     */
    async plotManagement_listPlot(params) {
        return await Axios.post(`/sanitation/villagePlot/listPlot`, params)
    },
    /**
     * 国土地块-insertPlot
     */
    async plotManagement_insertPlot(params) {
        return await Axios.post(`/sanitation/villagePlot/insertPlot`, params)
    },
    /**
     * 国土地块-delete
     */
    async plotManagement_delete(params) {
        return await Axios.post(`/sanitation/villagePlot/delete`, params)
    },
    /**
     * 国土地块-update
     */
    async plotManagement_update(params) {
        return await Axios.post(`/sanitation/villagePlot/update`, params)
    },
    /**
     * 国土地块-getById
     */
    async plotManagement_getById(params) {
        return await Axios.post(`/sanitation/villagePlot/getById`, params)
    },
    /**
     * 市政道路-list
     */
    async municipalRoad_list(params) {
        return await Axios.post(`/sanitation/cityRoad/list`, params)
    },
    /**
     * 市政道路-insert
     */
    async municipalRoad_insert(params) {
        return await Axios.post(`/sanitation/cityRoad/insert`, params)
    },
    /**
     * 市政道路-delete
     */
    async municipalRoad_delete(params) {
        return await Axios.post(`/sanitation/cityRoad/delete`, params)
    },
    /**
     * 市政道路-update
     */
    async municipalRoad_update(params) {
        return await Axios.post(`/sanitation/cityRoad/update`, params)
    },
    /**
     * 市政道路-getById
     */
    async municipalRoad_getById(params) {
        return await Axios.post(`/sanitation/cityRoad/getById`, params)
    },
    /**
     * 环保垃圾屋-insert
     */
    async greenGarbageHouse_insert(params) {
        return await Axios.post(`/sanitation/ecoHouse/insert`, params)
    },
    /**
     * 环保垃圾屋-delete
     */
    async greenGarbageHouse_delete(params) {
        return await Axios.post(`/sanitation/ecoHouse/delete`, params)
    },
    /**
     * 环保垃圾屋-update
     */
    async greenGarbageHouse_update(params) {
        return await Axios.post(`/sanitation/ecoHouse/update`, params)
    },
    /**
     * 环保垃圾屋-getById
     */
    async greenGarbageHouse_getById(params) {
        return await Axios.post(`/sanitation/ecoHouse/getById`, params)
    },
    /**
     * 环保垃圾屋-list
     */
    async greenGarbageHouse_list(params) {
        return await Axios.post(`/sanitation/ecoHouse/list`, params)
    },
    /**
     * 垃圾中转站-新增垃圾中转站信息
     */
    async transferStationManagement_insert(params) {
        return await Axios.post(`/sanitation/rubbishTransfer/insert`, params)
    },
    /**
     * 垃圾中转站-删除垃圾中转站信息
     */
    async transferStationManagement_delete(params) {
        return await Axios.post(`/sanitation/rubbishTransfer/delete`, params)
    },
    /**
     * 垃圾中转站-更新垃圾中转站信息
     */
    async transferStationManagement_update(params) {
        return await Axios.post(`/sanitation/rubbishTransfer/update`, params)
    },
    /**
     * 垃圾中转站-查询垃圾中转站详细信息
     */
    async transferStationManagement_getById(params) {
        return await Axios.post(`/sanitation/rubbishTransfer/getById`, params)
    },
    /**
     * 垃圾中转站-分页查询垃圾中转站信息
     */
    async transferStationManagement_list(params) {
        return await Axios.post(`/sanitation/rubbishTransfer/list`, params)
    },
    /**
     * 垃圾收集点-insert
     */
    async refuseCollectionPoint_insert(params) {
        return await Axios.post(`/sanitation/rubbishCollect/insert`, params)
    },
    /**
     * 垃圾收集点-delete
     */
    async refuseCollectionPoint_delete(params) {
        return await Axios.post(`/sanitation/rubbishCollect/delete`, params)
    },
    /**
     * 垃圾收集点-update
     */
    async refuseCollectionPoint_update(params) {
        return await Axios.post(`/sanitation/rubbishCollect/update`, params)
    },
    /**
     * 垃圾收集点-getById
     */
    async refuseCollectionPoint_getById(params) {
        return await Axios.post(`/sanitation/rubbishCollect/getById`, params)
    },
    /**
     * 垃圾收集点-list
     */
    async refuseCollectionPoint_list(params) {
        return await Axios.post(`/sanitation/rubbishCollect/list`, params)
    },
    /**
     * 城中村-insertVillage
     */
    async urbanVillagesManagement_insertVillage(params) {
        return await Axios.post(`/sanitation/villagePlot/insertVillage`, params)
    },
    /**
     * 城中村-delete
     */
    async urbanVillagesManagement_delete(params) {
        return await Axios.post(`/sanitation/villagePlot/delete`, params)
    },
    /**
     * 城中村-update
     */
    async urbanVillagesManagement_update(params) {
        return await Axios.post(`/sanitation/villagePlot/update`, params)
    },
    /**
     * 城中村-getById
     */
    async urbanVillagesManagement_getById(params) {
        return await Axios.post(`/sanitation/villagePlot/getById`, params)
    },
    /**
     * 城中村-listVillage
     */
    async urbanVillagesManagement_listVillage(params) {
        return await Axios.post(`/sanitation/villagePlot/listVillage`, params)
    },
    /**
     * 公共厕所-insert
     */
    async publicToilets_insert(params) {
        return await Axios.post(`/sanitation/publicToilet/insert`, params)
    },
    /**
     * 公共厕所-delete
     */
    async publicToilets_delete(params) {
        return await Axios.post(`/sanitation/publicToilet/delete`, params)
    },
    /**
     * 公共厕所-update
     */
    async publicToilets_update(params) {
        return await Axios.post(`/sanitation/publicToilet/update`, params)
    },
    /**
     * 公共厕所-getById
     */
    async publicToilets_getById(params) {
        return await Axios.post(`/sanitation/publicToilet/getById`, params)
    },
    /**
     * 公共厕所-list
     */
    async publicToilets_list(params) {
        return await Axios.post(`/sanitation/publicToilet/list`, params)
    },
    /**
     * SIM卡管理-insertSimInfo
     */
    async simManagement_insertSimInfo(params) {
        return await Axios.post(`/IOV/simInfo/insertSimInfo`, params)
    },
    /**
     * SIM卡管理-作废
     */
    async simManagement_obsoleteSimCard(params) {
        return await Axios.post(`/sanitation/simInfo/obsoleteSimCard`, params)
    },
    /**
     * SIM卡管理-updateSimInfo
     */
    async simManagement_updateSimInfo(params) {
        return await Axios.post(`/IOV/simInfo/updateSimInfo`, params)
    },
    /**
     * SIM卡管理-getSimInfoBySimNumber
     */
    async simManagement_getSimInfoBySimNumber(params) {
        return await Axios.post(`/IOV/simInfo/getSimInfoBySimNumber`, params)
    },
    /**
     * SIM卡管理-listSimInfoByQueryCondition
     */
    async simManagement_listSimInfoByQueryCondition(params) {
        return await Axios.post(`/IOV/simInfo/listSimInfoByQueryCondition`, params)
    },
    /**
     * 设备管理-insertDeviceInfo
     */
    async equipmentManagement_insertDeviceInfo(params) {
        return await Axios.post(`/IOV/deviceIOVService/insertDeviceInfo`, params)
    },
    /**
     * 设备管理-updateDeviceInfo
     */
    async equipmentManagement_updateDeviceInfo(params) {
        return await Axios.post(`/IOV/deviceIOVService/updateDeviceInfo`, params)
    },
    /**
     * 设备管理-作废
     */
    async equipmentManagement_obsoleteDevice(params) {
        return await Axios.post(`/sanitation/device/obsoleteDevice`, params)
    },
    /**
     * 设备管理-解除绑定
     */
    async equipmentManagement_unBindSimCardNumber(params) {
        return await Axios.post(`/sanitation/device/unBindSimCardNumber`, params)
    },
    /**
     * 设备管理-getDeviceInfo
     */
    async equipmentManagement_getDeviceInfo(params) {
        return await Axios.post(`/IOV/deviceIOVService/getDeviceInfo`, params)
    },
    /**
     * 设备管理-list
     */
    async equipmentManagement_list(params) {
        return await Axios.post(`/sanitation/deviceInfo/list`, params)
    },
    /**
     * 设备管理-listNotEnableSimInfo
     */
    async equipmentManagement_listNotEnableSimInfo(params) {
        return await Axios.post(`/sanitation/simInfo/listNotEnableSimInfo`, params)
    },
    /**
     * 设备管理-bindSimCardNumber
     */
    async equipmentManagement_bindSimCardNumber(params) {
        return await Axios.post(`/sanitation/device/bindSimCardNumber`, params)
    },
    /**
     * 车俩作业路线-getRouteList
     */
    async vehicleOperation_getRouteList(params) {
        return await Axios.post(`/sanitation/carRoute/getRouteList`, params)
    },
    /**
     * 车俩作业路线-addNewRouteShift
     */
    async vehicleOperation_addNewRouteShift(params) {
        return await Axios.post(`/sanitation/carRoute/addNewRouteShift`, params)
    },
    /**
     * 车俩作业路线-getRouteShiftInfo
     */
    async vehicleOperation_getRouteShiftInfo(params) {
        return await Axios.post(`/sanitation/carRoute/getRouteShiftInfo`, params)
    },
    /**
     * 车俩作业路线-updateRouteShift
     */
    async vehicleOperation_updateRouteShift(params) {
        return await Axios.post(`/sanitation/carRoute/updateRouteShift`, params)
    },
    /**
     * 人员作业网格-getGridList
     */
    async personnelWork_getGridList(params) {
        return await Axios.post(`/sanitation/personWorkGrid/getGridList`, params)
    },
    /**
     * 人员作业网格-addNewGridShift
     */
    async personnelWork_addNewGridShift(params) {
        return await Axios.post(`/sanitation/personWorkGrid/addNewGridShift`, params)
    },
    /**
     * 人员作业网格-getGridShiftInfo
     */
    async personnelWork_getGridShiftInfo(params) {
        return await Axios.post(`/sanitation/personWorkGrid/getGridShiftInfo`, params)
    },

    /**
     * 人员作业网格-updateGridShift
     */
    async personnelWork_updateGridShift(params) {
        return await Axios.post(`/sanitation/personWorkGrid/updateGridShift`, params)
    },




    /*
     * 排班管理-获取司机排班列表
     */
    async scheduling_driverScheduleDetail(params) {
        return await Axios.post(`/sanitation/arrangeSchedule/driverScheduleDetail`, params)
    },
    /*
     * 排班管理-获取车辆排班列表
     */
    async scheduling_getUnderBidDifferTypeCarList(params) {
        return await Axios.post(`/sanitation/arrangeSchedule/getUnderBidDifferTypeCarList`, params)
    },
    /**
     * 排班管理-保洁人员排班列表分页查询
     */
    async scheduling_getPersonShiftScheduleList(params) {
        return await Axios.post(`/sanitation/arrangeSchedule/getPersonShiftScheduleList`, params)
    },
    /**
     * 排班管理-垃圾清扫车辆排班列表分页查询
     */
    async scheduling_getRouteJobTypeScheduleList(params) {
        return await Axios.post(`/sanitation/arrangeSchedule/getRouteJobTypeScheduleList`, params)
    },
    /**
     * 排班管理-复制排班
     */
    async scheduling_copySchedule(params) {
        return await Axios.post(`/sanitation/arrangeSchedule/copySchedule`, params)
    },
    /**
     * 排班管理-复制排班筛选-获取租户下有班次有排班的标段等信息
     */
    async scheduling_getBidShiftScheduleInfo(params) {
        return await Axios.post(`/sanitation/arrangeSchedule/getBidShiftScheduleInfo`, params)
    },
    /**
     * 排班管理-特定班次日期下：已排班、未排班人员列表（适用于人员排班）
     */
    async scheduling_personScheduleDetail(params) {
        return await Axios.post(`/sanitation/arrangeSchedule/personScheduleDetail`, params)
    },
    /**
     * 排班管理-保存人员网格排班信息
     */
    async scheduling_saveSchedule(params) {
        return await Axios.post(`/sanitation/arrangeSchedule/saveSchedule`, params)
    },
    /**
     * 排班管理-更新线路排班司机或车辆
     */
    async scheduling_updateDutyCarDriver(params) {
        return await Axios.post(`/sanitation/carRoute/updateDutyCarDriver`, params)
    },


    /**
     * 工资管理-新增工资发放记录
     */
    async salary_add(params) {
        return await Axios.post(`/sanitation/salaryManage/add`, params)
    },
    /**
     * 工资管理-提交工资审批
     */
    async salary_commit(params) {
        return await Axios.post(`/sanitation/salaryManage/commit`, params)
    },
    /**
     * 工资管理-查询工资发放明细列表
     */
    async salary_querySalaryDetailBySalaryId(params) {
        return await Axios.post(`/sanitation/salaryManage/querySalaryDetailBySalaryId`, params)
    },
    /**
     * 工资管理-查询工资发放明细列表
     */
    async salary_query(params) {
        return await Axios.post(`/sanitation/salaryManage/query`, params)
    },
    /**
     * 工资管理-获取工资发放详细信息(含对应的明细列表)
     */
    async salary_getBySalaryId(params) {
        return await Axios.post(`/sanitation/salaryManage/getBySalaryId`, params)
    },
    /**
     * 工资管理-修改工资发放信息
     */
    async salary_update(params) {
        return await Axios.post(`/sanitation/salaryManage/updateBySalaryId`, params)
    },

    /**
     * 申诉管理-查询申诉信息列表
     */
    async complainManage_query(params) {
        return await Axios.post(`/sanitation/complainManage/query`, params)
    },
    /**
     * 申诉管理-申诉审批
     */
    async complainManage_audit(params) {
        return await Axios.post(`/sanitation/complainManage/audit`, params)
    },

    /**
     * 标段生活管理记录-分页查询标段审核记录
     */
    async bidCommit_page(params) {
        return await Axios.post(`/sanitation/bidCommit/page`, params)
    },

    /**
     * 公共枚举接口
     */
    async public_getEnumProperties(params) {
        return await Axios.post(`/sanitation/common/getEnumProperties`, params)
    },


    //////////安全作业管理////////////////////////////////////////////////////////////////////

    // 企业安全自查-列表-getSelfCheckList
    async operationSafetyManagement_getSelfCheckList(params) {
        return await Axios.post(`/sanitation/safeSelfCheck/getSelfCheckList`, params)
    },
    // 企业安全自查-新增-saveNewSelfCheckInfo
    async operationSafetyManagement_saveNewSelfCheckInfo(params) {
        return await Axios.post(`/sanitation/safeSelfCheck/saveNewSelfCheckInfo`, params)
    },

    // 企业安全自查-详情信息-getSelfCheckInfo
    async operationSafetyManagement_getSelfCheckInfo(params) {
        return await Axios.post(`/sanitation/safeSelfCheck/getSelfCheckInfo`, params)
    },

    // 企业安全自查-编辑更新-getSelfCheckInfo
    async operationSafetyManagement_updateSelfCheckInfo(params) {
        return await Axios.post(`/sanitation/safeSelfCheck/updateSelfCheckInfo`, params)
    },

    // 企业安全自查-删除（批量）-deleteSelfCheckInfo
    async operationSafetyManagement_deleteSelfCheckInfo(params) {
        return await Axios.post(`/sanitation/safeSelfCheck/deleteSelfCheckInfo`, params)
    },



    // 安全事故记录-列表-getAccidentList
    async operationSafetyManagement_getAccidentList(params) {
        return await Axios.post(`/sanitation/safeAccidentRecord/getAccidentList`, params)
    },
    // 安全事故记录-新增-saveNewAccidentInfo
    async operationSafetyManagement_saveNewAccidentInfo(params) {
        return await Axios.post(`/sanitation/safeAccidentRecord/saveNewAccidentInfo`, params)
    },
    // 安全事故记录-表单详情-getAccidentInfo
    async operationSafetyManagement_getAccidentInfo(params) {
        return await Axios.post(`/sanitation/safeAccidentRecord/getAccidentInfo`, params)
    },
    // 安全事故记录-编辑-updateAccidentInfo
    async operationSafetyManagement_updateAccidentInfo(params) {
        return await Axios.post(`/sanitation/safeAccidentRecord/updateAccidentInfo`, params)
    },
    // 安全事故记录-删除（批量）-deleteAccidentInfo
    async operationSafetyManagement_deleteAccidentInfo(params) {
        return await Axios.post(`/sanitation/safeAccidentRecord/deleteAccidentInfo`, params)
    },
    // 安全事故记录-编辑下拉相关车辆
    async operationSafetyManagement_getAllCarsUnderBid(params) {
        return await Axios.post(`/sanitation/commonService/getAllCarsUnderBid`, params)
    },
    // 安全事故记录-编辑下拉相关人员
    async operationSafetyManagement_getAllPersonUnderBid(params) {
        return await Axios.post(`/sanitation/commonService/getAllPersonUnderBid`, params)
    },



    // 安全物资管理-列表-getMaterialList
    async operationSafetyManagement_getMaterialList(params) {
        return await Axios.post(`/sanitation/safeMaterialInfo/getMaterialList`, params)
    },

    // 安全物资管理-新增-saveNewMaterialInfo
    async operationSafetyManagement_saveNewMaterialInfo(params) {
        return await Axios.post(`/sanitation/safeMaterialInfo/saveNewMaterialInfo`, params)
    },
    // 安全物资管理-详情-getMaterialInfo
    async operationSafetyManagement_getMaterialInfo(params) {
        return await Axios.post(`/sanitation/safeMaterialInfo/getMaterialInfo`, params)
    },
    // 安全物资管理-编辑-updateMaterialInfo
    async operationSafetyManagement_updateMaterialInfo(params) {
        return await Axios.post(`/sanitation/safeMaterialInfo/updateMaterialInfo`, params)
    },
    // 安全物资管理-删除（批量）-deleteMaterialInfo
    async operationSafetyManagement_deleteMaterialInfo(params) {
        return await Axios.post(`/sanitation/safeMaterialInfo/deleteMaterialInfo`, params)
    },



    // 标段信息-列表-page
    async districtInfo_bidManagePage(params) {
        return await Axios.post(`/sanitation/bidManage/page`, params)
    },

    // 标段信息-新增-add
    async districtInfo_bidManageAdd(params) {
        return await Axios.post(`/sanitation/bidManage/add`, params)
    },

    // 标段信息-详情-get
    async districtInfo_bidManageGet(params) {
        return await Axios.post(`/sanitation/bidManage/get`, params)
    },
    // 标段信息-编辑更新-update
    async districtInfo_bidManageUpdate(params) {
        return await Axios.post(`/sanitation/bidManage/update`, params)
    },

    // 标段信息-获取表单汇总信息-get
    async districtInfo_bidSummaryGet(params) {
        return await Axios.post(`/sanitation/bidSummary/get`, params)
    },

    // 标段信息-更新表单汇总信息-merge
    async districtInfo_bidSummaryMerge(params) {
        return await Axios.post(`/sanitation/bidSummary/merge`, params)
    },

    // 标段信息-表单汇总信息-右侧弹窗-基本信息
    async districtInfo_bidSummaryDetailBase(params) {
        return await Axios.post(`/sanitation/bidManage/detail/base`, params)
    },
    // 标段信息-表单汇总信息-右侧弹窗-合同信息
    async districtInfo_bidSummaryDetailContract(params) {
        return await Axios.post(`/sanitation/bidManage/detail/contract`, params)
    },
    // 标段信息-表单汇总信息-右侧弹窗-车辆信息
    async districtInfo_bidSummaryDetailCarPage(params) {
        return await Axios.post(`/sanitation/bidManage/detail/car/page`, params)
    },
    // 标段信息-表单汇总信息-右侧弹窗-市镇道路信息
    async districtInfo_bidSummaryDetailCityRoadPage(params) {
        return await Axios.post(`/sanitation/bidManage/detail/cityRoad/page`, params)
    },
    // 标段信息-表单汇总信息-右侧弹窗-环保垃圾屋信息
    async districtInfo_bidSummaryDetailEcoHousePage(params) {
        return await Axios.post(`/sanitation/bidManage/detail/ecoHouse/page`, params)
    },
    // 标段信息-表单汇总信息-右侧弹窗-人员信息
    async districtInfo_bidSummaryDetailPersonPage(params) {
        return await Axios.post(`/sanitation/bidManage/detail/person/page`, params)
    },
    // 标段信息-表单汇总信息-右侧弹窗-国土地块信息
    async districtInfo_bidSummaryDetailPlotPage(params) {
        return await Axios.post(`/sanitation/bidManage/detail/plot/page`, params)
    },
    // 标段信息-表单汇总信息-右侧弹窗-公厕信息
    async districtInfo_bidSummaryDetailPublicToiletPage(params) {
        return await Axios.post(`/sanitation/bidManage/detail/publicToilet/page`, params)
    },
    // 标段信息-表单汇总信息-右侧弹窗-垃圾收集点信息
    async districtInfo_bidSummaryDetailRubbishCollectorPage(params) {
        return await Axios.post(`/sanitation/bidManage/detail/rubbishCollector/page`, params)
    },
    // 标段信息-表单汇总信息-右侧弹窗-垃圾中转站信息
    async districtInfo_bidSummaryDetailRubbishTransferPage(params) {
        return await Axios.post(`/sanitation/bidManage/detail/rubbishTransfer/page`, params)
    },
    // 标段信息-表单汇总信息-右侧弹窗-城中村信息
    async districtInfo_bidSummaryDetailVillagePage(params) {
        return await Axios.post(`/sanitation/bidManage/detail/village/page`, params)
    },
    // 标段信息-表单汇总信息-右侧弹窗-调入
    async districtInfo_bidSummaryDetailBringIn(params) {
        return await Axios.post(`/sanitation/bidManage/bringIn`, params)
    },
    // 标段信息-表单汇总信息-右侧弹窗-调出
    async districtInfo_bidSummaryDetailBringOut(params) {
        return await Axios.post(`/sanitation/bidManage/bringOut`, params)
    },



    // 获取租户所属企业信息（标段新增养护企业）
    async districtInfo__getTenantCompanyInfo(params) {
        return await Axios.post(`/sanitation/commonService/getTenantCompanyInfo`, params)
    },
    // 获取租户所属企业信息（养护企业下拉）统用一这个
    async districtInfo_getDistinctCareUnit(params) {
        return await Axios.post(`/sanitation/commonService/getDistinctCareUnit`, params)
    },



    // 标段合同-列表-page
    async districtInfo_contractPage(params) {
        return await Axios.post(`/sanitation/contract/page`, params)
    },

    // 标段合同-新增-add
    async districtInfo_contractAdd(params) {
        return await Axios.post(`/sanitation/contract/add`, params)
    },

    // 标段合同-详情-get
    async districtInfo_contractGet(params) {
        return await Axios.post(`/sanitation/contract/get`, params)
    },

    // 标段合同-更新-update
    async districtInfo_contractUpdate(params) {
        return await Axios.post(`/sanitation/contract/update`, params)
    },

    // 标段合同-续约合同-renew
    async districtInfo_contractRenew(params) {
        return await Axios.post(`/sanitation/contract/renew`, params)
    },

    // 标段合同-终止合同-terminate
    async districtInfo_contractTerminate(params) {
        return await Axios.post(`/sanitation/contract/terminate`, params)
    },

    // 历史标段-列表-page
    async districtInfo_bidManageHistoryPage(params) {
        return await Axios.post(`/sanitation/bidManage/history/page`, params)
    },


    //////////////////////作业监管////////////////////////////////////////////////
    // 实时监管（标段坐标）-根据标段ID获取标段坐标范围，用于前端绘制展示
    async commonService_getBidCoordinates(params) {
        return await Axios.post(`/sanitation/commonService/getBidCoordinates`, params)
    },
    // 实时监管（人员/车辆）-查询车辆在线信息列表
    async realSupervise_getCarList(params) {
        return await Axios.post(`/sanitation/realSupervise/getCarList`, params)
    },
    // 实时监管（人员/车辆）-查询车辆附加详细信息
    async realSupervise_getCarAdditional(params) {
        return await Axios.post(`/sanitation/realSupervise/getCarAdditional`, params)
    },
    // 实时监管（人员/车辆）-查询人员在线信息列表
    async realSupervise_getPersonList(params) {
        return await Axios.post(`/sanitation/realSupervise/getPersonList`, params)
    },
    // 实时监管（人员/车辆）-统计租户下人员，车辆实时统计信息（数量）
    async realSupervise_getStatisticsInfo(params) {
        return await Axios.post(`/sanitation/realSupervise/getStatisticsInfo`, params)
    },
    // 实时监管（人员）-查询人员附加详细信息
    async realSupervise_getPersonnelAdditional(params) {
        return await Axios.post(`/sanitation/realSupervise/getPersonnelAdditional`, params)
    },
    // 实时监管（人员/车辆）-查询指定标段下车辆排班信息
    async realSupervise_getUnderBidCarScheduling(params) {
        return await Axios.post(`/sanitation/realSupervise/getUnderBidCarScheduling`, params)
    },
    // 实时监管（人员/车辆）-查询指定标段下人员排班信息
    async realSupervise_getUnderBidPersonnelScheduling(params) {
        return await Axios.post(`/sanitation/realSupervise/getUnderBidPersonnelScheduling`, params)
    },
    // 实时监管（人员/车辆）-通过网格ID查询排班时间列表
    async realSupervise_listShiftsById(params) {
        return await Axios.post(`/sanitation/realSupervise/listShiftsById`, params)
    },
    // 实时监管（网格）-标段下的网格列表 每个网格下具体的各状态人员数量
    async personWorkGrid_underBidGridPersonNum(params) {
        return await Axios.post(`/sanitation/personWorkGrid/underBidGridPersonNum`, params)
    },
    // 实时监管（网格）-查看网格详情
    async personWorkGrid_getGridShiftInfo(params) {
        return await Axios.post(`/sanitation/personWorkGrid/getGridShiftInfo`, params)
    },
    // 实时监管（网格）-获取租户标段下，所有的网格坐标，用于实时监管绘制
    async commonService_getGridCoordinateUnderBid(params) {
        return await Axios.post(`/sanitation/commonService/getGridCoordinateUnderBid`, params)
    },
    // 实时监管（人员）-点击人员数量获取人员列表
    async realTimeSupervision_getPersonByIdList(params) {
        return await Axios.post(`/sanitation/realTimeSupervision/getPersonByIdList`, params)
    },
    // 实时监管（车辆路线）-获取标段下的作业线路列表 机扫、冲洗统计
    async carRoute_getRouteStatisticalInfo(params) {
        return await Axios.post(`/sanitation/carRoute/getRouteStatisticalInfo`, params)
    },
    // 实时监管（车辆路线）-查看线路统计详情信息
    async carRoute_underBidRouteStatistical(params) {
        return await Axios.post(`/sanitation/realTimeSupervision/underBidRouteStatistical`, params)
    },
    // 实时监管（作业路线）-获取租户标段下，所有的作业路线坐标，用于实时监管绘制
    async commonService_getRouteCoordinateUnderBid(params) {
        return await Axios.post(`/sanitation/commonService/getRouteCoordinateUnderBid`, params)
    },
    // 实时监管（其它信息）-根据分页条件和所需要查询的业务实体类型进行查询
    async realSupervise_otherInfo_page(params) {
        return await Axios.post(`/sanitation/realSupervise/otherInfo/page`, params)
    },
    // 实时监管（其它信息）-查询标段对应的其他信息列表（标段区域信息，标段设施信息等）
    async realSupervise_otherInfo_list(params) {
        return await Axios.post(`/sanitation/realSupervise/otherInfo/list`, params)
    },
    // 实时监管（其它信息）环卫系统-今日报警总数
    async alarmManage_getTodayAiAlarmCount(params) {
        return await Axios.post(`/sanitation/alarmManage/getTodayAiAlarmCount`, params)
    },

    // 作业监管（历史视频）-查询获取视频回放-车辆列表
    async historyVideo_getRealTimeCarInfoList(params) {
        return await Axios.post(`/sanitation/historyVideo/getRealTimeCarInfoList`, params)
    },
    // 作业监管（历史视频）-查询获取轨迹回放-车辆人员列表
    async historyVideo_getRealTimeDeviceInfoList(params) {
        return await Axios.post(`/sanitation/historyVideo/getRealTimeDeviceInfoList`, params)
    },
    // 作业监管（历史视频）-查询获取视频回放-停车点列表
    async historyVideo_getStopPointList(params) {
        return await Axios.post(`/sanitation/historyVideo/getStopPointList`, params)
    },
    // 作业监管（历史视频）-查询获取轨迹回放-作业信息
    async historyVideo_listWorkInfo(params) {
        return await Axios.post(`/sanitation/historyVideo/listWorkInfo`, params)
    },
    // 作业监管（历史视频）- 查询获取视频回放-获取历史轨迹信息
    async historyVideo_getDeviceHistoryUsage(params) {
        return await Axios.post(`/sanitation/historyVideo/getDeviceHistoryUsage`, params)
    },
    // 作业监管（历史视频）- 条件查询 历史视频列表
    async historyVideo_list(params) {
        return await Axios.post(`/sanitation/historyVideo/list`, params)
    },
    ////////////////////////////////////作业安全管理//////////////////////////////////

    /** ------------suyonggang----------- */
    // 作业规则管理-作业规则管理-列表
    async operationSafetyManagement_listRegulationInfoByCondition(params) {
        return await Axios.post(`/sanitation/regulationInfo/listRegulationInfoByCondition`, params)
    },
    // 作业规则管理-作业规则管理-新增
    async operationSafetyManagement_regulationInfoInsert(params) {
        return await Axios.post(`/sanitation/regulationInfo/insert`, params)
    },
    // 作业规则管理-作业规则-删除
    async operationSafetyManagement_regulationInfoDelete(params) {
        return await Axios.post(`/sanitation/regulationInfo/delete`, params)
    },
    // 作业规则管理-作业规则-修改
    async operationSafetyManagement_regulationInfoUpdate(params) {
        return await Axios.post(`/sanitation/regulationInfo/update`, params)
    },
    // 作业规则管理-作业规则-详情
    async operationSafetyManagement_regulationInfoGetRegulationInfoByRegulationId(params) {
        return await Axios.post(`/sanitation/regulationInfo/getRegulationInfoByRegulationId`, params)
    },
    // 作业规则管理-分配/未分配-详情
    async operationSafetyManagement_regulationInfoGetRegulationInfoListForAllocation(params) {
        return await Axios.post(`/sanitation/regulationInfo/getRegulationInfoListForAllocation`, params)
    },
    // 作业规则管理-标段规则启用/停用
    async operationSafetyManagement_regulationInfoUpdateForDisabled(params) {
        return await Axios.post(`/sanitation/regulationAllocation/updateForDisabled`, params)
    },
    // 作业规则管理-标段规则管理-列表
    async operationSafetyManagement_listRegulationAllocationByCondition(params) {
        return await Axios.post(`/sanitation/regulationAllocation/listRegulationAllocationByCondition`, params)
    },
    // 作业规则管理-分配标段规则
    async operationSafetyManagement_listRegulationAllocationUpdateForRegulation(params) {
        return await Axios.post(`/sanitation/regulationAllocation/updateForRegulation`, params)
    },
    // 作业规则管理-新增区域
    async operationSafetyManagement_insertRegionInfo(params) {
        return await Axios.post(`/sanitation/regionInfoIOVService/insertRegionInfo`, params)
    },
    // 作业规则管理-新增区域列表
    async operationSafetyManagement_listDefaultRegionInfoByTenantId(params) {
        return await Axios.post(`/sanitation/regionInfoIOVService/listDefaultRegionInfoByTenantId`, params)
    },
    // 作业规则管理-新增区域坐标详情
    async operationSafetyManagement_getRegionInfoByRegionId(params) {
        return await Axios.post(`/sanitation/regionInfoIOVService/getRegionInfoByRegionId`, params)
    },



    /** ------------suyonggang----------- */
    // 作业预警管理-作业设置预警-列表
    async operationSafetyManagement_jobPreWarningPage(params) {
        return await Axios.post(`/sanitation/jobPreWarning/page`, params)
    },
    // 作业预警管理-作业设置预警-新增
    async operationSafetyManagement_jobPreWarningAdd(params) {
        return await Axios.post(`/sanitation/jobPreWarning/add`, params)
    },
    // 作业预警管理-作业设置预警-删除
    async operationSafetyManagement_jobPreWarningBatchDelete(params) {
        return await Axios.post(`/sanitation/jobPreWarning/batchDelete`, params)
    },
    // 作业预警管理-作业设置预警-修改
    async operationSafetyManagement_jobPreWarningBatchUpdate(params) {
        return await Axios.post(`/sanitation/jobPreWarning/update`, params)
    },
    // 作业预警管理-作业设置预警-详情
    async operationSafetyManagement_jobPreWarningGet(params) {
        return await Axios.post(`/sanitation/jobPreWarning/get`, params)
    },
    // 作业预警管理-人员/车辆当班预警-列表
    async operationSafetyManagement_jobPreWarningEventPage(params) {
        return await Axios.post(`/sanitation/jobPreWarningEvent/page`, params)
    },
    // 作业预警管理-人员/车辆当班预警-列表
    async operationSafetyManagement_jobPreWarningEventDeal(params) {
        return await Axios.post(`/sanitation/jobPreWarningEvent/deal`, params)
    },
    // 作业预警管理-人员/车辆根据标段动态获取下拉
    async operationSafetyManagement_jobPreWarningEventListWorkingAreas(params) {
        return await Axios.post(`/sanitation/jobPreWarningEvent/listWorkingAreas`, params)
    },



    /** -----------suyonggang------------ */
    // 人员健康管理-列表
    async operationSafetyManagement_HealthManageGetPersonHealthList(params) {
        return await Axios.post(`/sanitation/HealthManage/getPersonHealthList`, params)
    },
    // 人员健康明细-列表
    async operationSafetyManagement_getHealthDetailList(params) {
        return await Axios.post(`/sanitation/HealthManage/getHealthDetailList`, params)
    },
    // 人员健康明细-列表-顶部
    async operationSafetyManagement_getAbnormalPersonCount(params) {
        return await Axios.post(`/sanitation/HealthManage/getAbnormalPersonCount`, params)
    },
    


    /** -----------suyonggang------------ */
    // 安全培训记录-列表
    async operationSafetyManagement_safetyTrainingListCompanyObject(params) {
        return await Axios.post(`/sanitation/safetyTraining/listCompanyObject`, params)
    },
    // 安全培训记录-新增
    async operationSafetyManagement_safetyTrainingInsert(params) {
        return await Axios.post(`/sanitation/safetyTraining/insert`, params)
    },
    // 安全培训记录-删除
    async operationSafetyManagement_safetyTrainingDelete(params) {
        return await Axios.post(`/sanitation/safetyTraining/delete`, params)
    },
    // 安全培训记录-修改
    async operationSafetyManagement_safetyTrainingUpdate(params) {
        return await Axios.post(`/sanitation/safetyTraining/update`, params)
    },
    // 安全培训记录-详情
    async operationSafetyManagement_safetyTrainingGetDetail(params) {
        return await Axios.post(`/sanitation/safetyTraining/getDetail`, params)
    },
    // 安全培训记录-提交
    async operationSafetyManagement_safetyTrainingCommit(params) {
        return await Axios.post(`/sanitation/safetyTraining/commit`, params)
    },
    // 安全培训记录-审核列表
    async operationSafetyManagement_safetyTrainingListBidObject(params) {
        return await Axios.post(`/sanitation/safetyTraining/listBidObject`, params)
    },
    // 安全培训记录-审核
    async operationSafetyManagement_safetyTrainingCheck(params) {
        return await Axios.post(`/sanitation/safetyTraining/check`, params)
    },


    /** ------------suyonggang----------- */
    // 应急管理-应急预案-列表
    async operationSafetyManagement_emergencyPlanListCompanyObject(params) {
        return await Axios.post(`/sanitation/emergencyPlan/listCompanyObject`, params)
    },
    // 应急管理-应急预案-新增
    async operationSafetyManagement_emergencyPlanInsert(params) {
        return await Axios.post(`/sanitation/emergencyPlan/insert`, params)
    },
    // 应急管理-应急预案-删除
    async operationSafetyManagement_emergencyPlandDelete(params) {
        return await Axios.post(`/sanitation/emergencyPlan/delete`, params)
    },
    // 应急管理-应急预案-修改
    async operationSafetyManagement_emergencyPlanUpdate(params) {
        return await Axios.post(`/sanitation/emergencyPlan/update`, params)
    },
    // 应急管理-应急预案-详情
    async operationSafetyManagement_emergencyPlanGetDetail(params) {
        return await Axios.post(`/sanitation/emergencyPlan/getDetail`, params)
    },
    // 应急管理-应急预案-提交
    async operationSafetyManagement_emergencyPlanCommit(params) {
        return await Axios.post(`/sanitation/emergencyPlan/commit`, params)
    },
    // 应急管理-应急预案-审核列表
    async operationSafetyManagement_emergencyPlanListBidObject(params) {
        return await Axios.post(`/sanitation/emergencyPlan/listBidObject`, params)
    },
    // 应急管理-应急预案-审核
    async operationSafetyManagement_emergencyPlanCheck(params) {
        return await Axios.post(`/sanitation/emergencyPlan/check`, params)
    },


    /** ------------suyonggang----------- */
    // 应急管理-应急物资-列表
    async operationSafetyManagement_emergencySupplyListCompanyObject(params) {
        return await Axios.post(`/sanitation/emergencySupply/listCompanyObject`, params)
    },
    // 应急管理-应急物资-新增
    async operationSafetyManagement_emergencySupplyInsert(params) {
        return await Axios.post(`/sanitation/emergencySupply/insert`, params)
    },
    // 应急管理-应急物资-删除
    async operationSafetyManagement_emergencySupplyDelete(params) {
        return await Axios.post(`/sanitation/emergencySupply/delete`, params)
    },
    // 应急管理-应急物资-修改
    async operationSafetyManagement_emergencySupplyUpdate(params) {
        return await Axios.post(`/sanitation/emergencySupply/update`, params)
    },
    // 应急管理-应急物资-详情
    async operationSafetyManagement_emergencySupplyGetDetail(params) {
        return await Axios.post(`/sanitation/emergencySupply/getDetail`, params)
    },
    // 应急管理-应急物资-提交
    async operationSafetyManagement_emergencySupplyCommit(params) {
        return await Axios.post(`/sanitation/emergencySupply/commit`, params)
    },
    // 应急管理-应急物资-审核列表
    async operationSafetyManagement_emergencySupplyBidObject(params) {
        return await Axios.post(`/sanitation/emergencySupply/listBidObject`, params)
    },
    // 应急管理-应急物资-审核
    async operationSafetyManagement_emergencySupplyCheck(params) {
        return await Axios.post(`/sanitation/emergencySupply/check`, params)
    },


    /** ------------suyonggang----------- */
    // 应急管理-应急演练-列表
    async operationSafetyManagement_emergencyDrillListCompanyObject(params) {
        return await Axios.post(`/sanitation/emergencyDrill/listCompanyObject`, params)
    },
    // 应急管理-应急演练-新增
    async operationSafetyManagement_emergencyDrillInsert(params) {
        return await Axios.post(`/sanitation/emergencyDrill/insert`, params)
    },
    // 应急管理-应急演练-删除
    async operationSafetyManagement_emergencyDrillDelete(params) {
        return await Axios.post(`/sanitation/emergencyDrill/delete`, params)
    },
    // 应急管理-应急演练-修改
    async operationSafetyManagement_emergencyDrillUpdate(params) {
        return await Axios.post(`/sanitation/emergencyDrill/update`, params)
    },
    // 应急管理-应急演练-详情
    async operationSafetyManagement_emergencyDrillGetDetail(params) {
        return await Axios.post(`/sanitation/emergencyDrill/getDetail`, params)
    },
    // 应急管理-应急演练-提交
    async operationSafetyManagement_emergencyDrillCommit(params) {
        return await Axios.post(`/sanitation/emergencyDrill/commit`, params)
    },
    // 应急管理-应急演练-审核列表
    async operationSafetyManagement_emergencyDrillBidObject(params) {
        return await Axios.post(`/sanitation/emergencyDrill/listBidObject`, params)
    },
    // 应急管理-应急演练-审核
    async operationSafetyManagement_emergencyDrillCheck(params) {
        return await Axios.post(`/sanitation/emergencyDrill/check`, params)
    },


    /** ------------suyonggang----------- */
    // 应急管理-应急处置记录-列表
    async operationSafetyManagement_emergencyResponseRecordCompanyObject(params) {
        return await Axios.post(`/sanitation/emergencyResponseRecord/listCompanyObject`, params)
    },
    // 应急管理-应急处置记录-新增
    async operationSafetyManagement_emergencyResponseRecordInsert(params) {
        return await Axios.post(`/sanitation/emergencyResponseRecord/insert`, params)
    },
    // 应急管理-应急处置记录-删除
    async operationSafetyManagement_emergencyResponseRecordDelete(params) {
        return await Axios.post(`/sanitation/emergencyResponseRecord/delete`, params)
    },
    // 应急管理-应急处置记录-修改
    async operationSafetyManagement_emergencyResponseRecordUpdate(params) {
        return await Axios.post(`/sanitation/emergencyResponseRecord/update`, params)
    },
    // 应急管理-应急处置记录-详情
    async operationSafetyManagement_emergencyResponseRecordGetDetail(params) {
        return await Axios.post(`/sanitation/emergencyResponseRecord/getDetail`, params)
    },
    // 应急管理-应急处置记录-提交
    async operationSafetyManagement_emergencyResponseRecordCommit(params) {
        return await Axios.post(`/sanitation/emergencyResponseRecord/commit`, params)
    },
    // 应急管理-应急处置记录-审核列表
    async operationSafetyManagement_emergencyResponseRecordBidObject(params) {
        return await Axios.post(`/sanitation/emergencyResponseRecord/listBidObject`, params)
    },
    // 应急管理-应急处置记录-审核
    async operationSafetyManagement_emergencyResponseRecordCheck(params) {
        return await Axios.post(`/sanitation/emergencyResponseRecord/check`, params)
    },

    // 报警管理-人员报警中心列表


    /** ----------------------------报警管理--------------------------------------- */
    // 报警管理-车辆证据中心详情
    async alarmManage_deviceAlarmEvidence(params) {
        return await Axios.post(`/sanitation/alarmManage/deviceAlarmEvidence`, params)
    },
    // 报警管理-车辆报警中心详情
    async alarmManage_deviceAlarmDetail(params) {
        return await Axios.post(`/sanitation/alarmManage/deviceAlarmDetail`, params)
    },
    // 报警管理-人员报警详情
    async alarmManage_personAlarmDetail(params) {
        return await Axios.post(`/sanitation/alarmManage/personAlarmDetail`, params)
    },
    // 报警管理-车辆报警中心列表
    async alarmManage_listCarAlarmDeal(params) {
        return await Axios.post(`/sanitation/alarmManage/listCarAlarmDeal`, params)
    },
    // 报警管理-车辆证据中心列表
    async alarmManage_listAlarmEvidence(params) {
        return await Axios.post(`/sanitation/alarmManage/listAlarmEvidence`, params)
    },
    // 报警管理-车辆报警中心列表
    async alarmManage_listPersonAlarmDeal(params) {
        return await Axios.post(`/sanitation/alarmManage/listPersonAlarmDeal`, params)
    },
    // 报警管理-通过附件key换取证据附件的url集合
    async alarmManage_findFileByPrefix(params) {
        return await Axios.post(`/IOV/alarmManage/findFileByPrefix`, params)
    },
    // 报警管理-车辆报警中心证据导出
    async alarmManage_evidenceExport(params) {
        return await Axios.post(`/IOV/alarmManage/evidenceExport`, params)
    },
    // --------------suyonggang--------------
    // 提交安全事故、安全物资、安全自查记录
    async operationsSafetyManagement_commonServiceCommitRecord(params) {
        return await Axios.post(`/sanitation/commonService/commitRecord`, params)
    },
    // 审核安全事故、安全物资、安全自查记录
    async operationsSafetyManagement_commonServiceCheckRecord(params) {
        return await Axios.post(`/sanitation/commonService/checkRecord`, params)
    },

}